import React, { useState } from "react";
import {
  getDashboardSearchPath,
  getTheDate,
  sortObjectsBy,
} from "../../helpers/helpers";

import TooltipTD from "../Tooltip/TooltipTD";
import { getShipmentBadge } from "../../helpers/shipments";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./sections/helpers";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "../../hooks";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { WindowTableStyled } from "./styles";

function WindowTable({ shipmentsToRender, headCells, onClose }) {
  const [filters, setFilters] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  function renderArrow(headerLabel, arrowStyles = {}) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIcon
            svgClass={"headerArrowButton"}
            style={arrowStyles}
            leftColor="#25486D"
            rightColor="#ccc"
          />
        );
      }
      return (
        <ArrowsToSortIcon
          svgClass={"headerArrowButton"}
          style={arrowStyles}
          leftColor="#ccc"
          rightColor="#25486D"
        />
      );
    }
    return (
      <ArrowsToSortIcon
        svgClass={"headerArrowButton"}
        style={arrowStyles}
        leftColor="#ccc"
        rightColor="#ccc"
      />
    );
  }

  function sortActualColumn(actualColumn) {
    if (filters.sortedColumn === actualColumn) {
      if (filters.orderBy === "ASC") {
        setFilters({ ...filters, orderBy: "DESC" });
      } else {
        setFilters({ ...filters, orderBy: "ASC" });
      }
    } else {
      setFilters({
        ...filters,
        sortedColumn: actualColumn,
        orderBy: "ASC",
      });
    }
  }

  const getCell = ({ id, row, reference }) => {
    switch (id) {
      case "shipment":
        return (
          <TableCell className="number">
            {getShipmentBadge({
              shipment: row,
              classes: shipmentBadgeClass.medium,
              showToolTip: false,
              onChangeShipment: (shipment) => {
                const { purchaseOrderId, shipmentId } = queryParams;
                let newShipmentId = shipmentId;
                if (shipmentId !== shipment.id) {
                  newShipmentId = shipment.id;
                }
                let newPurchaseOrderId = purchaseOrderId;
                if (!shipment.purchaseOrderIds?.includes(purchaseOrderId)) {
                  newPurchaseOrderId = shipment.purchaseOrderIds[0];
                }
                navigate(
                  getDashboardSearchPath({
                    ...queryParams,
                    purchaseOrderId: newPurchaseOrderId,
                    shipmentId: newShipmentId,
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                  }),
                  { replace: true }
                );

                onClose();
              },
            })}
          </TableCell>
        );
      case "date":
      case "deliveryDate":
        return (
          <TableCell className="date">{getTheDate(row[reference])}</TableCell>
        );

      default:
        return (
          <TableCell className="finalDestination">
            <TooltipTD
              label={row.finalDestination}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.finalDestination}
            </TooltipTD>
          </TableCell>
        );
    }
  };

  return (
    <WindowTableStyled className="table" aria-label="simple table">
      <TableHead className="tableHeaderContainer">
        <TableRow>
          {headCells.map((header) => {
            return (
              <TableCell
                style={header.style}
                key={header.label + "windowsTable"}
                className={
                  filters.sortedColumn === header.reference
                    ? "sortedHeaderCell"
                    : "headerCell"
                }
                onClick={() => sortActualColumn(header.reference)}
              >
                <span>{header.label}</span>
                {header.canBeSort &&
                  renderArrow(header.reference, header.arrowStyles)}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {shipmentsToRender
          .sort(
            sortObjectsBy(
              filters.sortedColumn,
              filters.orderBy === "ASC" ? true : false
            )
          )
          .map((row) => {
            return (
              <TableRow className="tableRow" key={row.id}>
                {headCells.map((header) =>
                  getCell({ row, id: header.id, reference: header.reference })
                )}
              </TableRow>
            );
          })}
      </TableBody>
    </WindowTableStyled>
  );
}

export default WindowTable;
