import { colors } from "../../assets/jss/variables";
import { FormControl, Popper, styled } from "@mui/material";

export const GeneralFilterContainer = styled("div")(() => ({
  "&.filterContainer": {
    display: "flex",
    height: 40,
    gap: 8,
  },
}));

export const PopoverMultiSelect = styled(Popper)(({ theme }) => ({
  "&.popperLabelSelect": {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 4,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "white",
    maxWidth: 350,
  },
  "&.popperSelect": {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 4,
    width: "-webkit-fill-available !Important",
    zIndex: 1000,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "white",
    marginTop: 2,
    "& .radioClass": {
      padding: 5,
      color: colors.primaryDark,
    },
    "& .checkedClass": {
      color: colors.radioCheck,
    },
    "& .MuiAutocomplete-popper": {
      boxShadow: "0px 6px 9px -3px rgba(27,31,35,.45)",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,

      "& .MuiPaper-root": {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      },
    },
  },
  "& .paper": {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
    maxHeight: 500,
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "calc(100vh - 255px)",
    },
  },
  "& .paperNotification": {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
    maxHeight: 400,
    overflow: "auto",
  },
  "& .inputBase": {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid white",
    "& .rootInput": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        //input boxshadow
        boxShadow: "rgba(93, 146, 244, 0.25) 0 0 0 0.2rem",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  "& .poppoverOption": {
    padding: "4px 10px 4px 4px",
    height: 42,
    '&[aria-selected="true"]': {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  "& .popperDisablePortal": {
    position: "relative",
    width: "auto",
  },
  "& .listbox": {
    padding: 0,
    maxHeight: "inherit",
  },
  "& .selectedText": {
    flexGrow: 1,
    fontSize: 16,
    color: colors.primaryDark,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
  },
  "& .buttonX": {
    background: "#FFF",
    color: "darkGray",
    width: 15,
    height: 15,
    borderRadius: "50%",
    margin: "0px 8px",
    position: "absolute",
    right: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
  "& .buttonXHidden": {
    display: "none",
  },

  "& .menuItem": {
    padding: "4px 10px 4px 4px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    color: "#25486D",
    "& label": {
      margin: 0,
      cursor: "pointer",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: "#25486D",
    },
  },
}));

export const MultiSelectButton = styled(FormControl)(({ theme }) => ({
  "& .notificationContainer": {
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #d0dcea",
    background: "white",
    display: "flex",
    alignItems: "center",
    width: 65,
    transform: "unset",
    boxShadow: "unset",
    justifyContent: "flex-start",
    padding: "0px 0px 0px 3px",
    "&:hover": {
      backgroundColor: "#e0e3e7",
    },
  },
  "& .MuiTouchRipple-child": {
    backgroundColor: colors.touchRipple,
  },
  "& .regularContainer": {
    height: "100%",
    borderRadius: "4px",
    border: "1px solid #d0dcea",
    background: "white",
    display: "flex",
    alignItems: "center",
    width: "180px",
    transform: "unset",
    boxShadow: "unset",
    justifyContent: "flex-start",
    padding: "0px 0px 0px 7px",
    "&:hover": {
      backgroundColor: "#e0e3e7",
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: "50px",
      width: "50px",
      maxWidth: "50px",
    },
  },
  "& .arrowDropDownIcon": {
    position: "absolute",
    right: 5,
    background: "unset",
    transition: "transform 0.3s ease",
    color: colors.primaryDark,
  },
  "& .textFilter": {
    margin: "0px 0px 0px 6px",
    position: "relative",
    color: colors.primaryDark,
  },
  "& .arrowActive": {
    transform: "rotate(180deg)",
  },
  "& .selectFilter": {
    width: 180,
    margin: 0,
    marginTop: "0",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 5,
    border: "1px solid #D0DCEA",
    height: 40,
    "&:hover": {
      backgroundColor: "#e0e3e7",
      borderRadius: 5,
    },
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  "& .selectMenu": {
    minWidth: 180,
    maxWidth: 260,
    border: "1px solid #aaa",
    overflowY: "auto",
    maxHeight: "calc(100% - 200px)",
  },
  "& .wrapper": {
    padding: 0,
    overflowX: "hidden",
  },
  "& .menuItem": {
    padding: "4px 10px 4px 4px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    color: colors.primaryDark,
    "& label": {
      margin: 0,
      cursor: "pointer",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: colors.primaryDark,
    },
  },
  "& .selectMenuContent": {
    padding: "12px",
  },
  "& .inputLabelContainer": {
    height: "100%",
    borderRadius: 4,
    border: "1px solid #D0DCEA",
    background: "white",
    display: "flex",
    alignItems: "center",
    width: 180,
  },
}));

export const NotificationGeneralFilterStyled = styled("div")(() => ({
  "&.filterContainer": {
    width: "100%",
    display: "block",

    "& .resetButton": {
      width: 65,
      height: 40,
      background: "rgba(255, 51, 102, 0.07)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
      fontSize: 10,
      color: colors.primaryDark,
      fontWeight: 400,
      border: "1px solid #FF7495 !important",
      cursor: "pointer",
      transform: "unset !important",
      "& span": {
        textAlign: "center",
      },
      "&:hover": {
        backgroundColor: "rgba(255, 51, 102, 0.2)",
      },
      "& .MuiTouchRipple-child": {
        backgroundColor: "rgba(255,51,102,0.4)",
      },
    },
    "& .dateContainer": {
      border: "1px solid #D0DCEA !important",
      padding: 11,
      borderRadius: 4,
      width: 65,
      height: 40,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: 8,
      cursor: "pointer",
      transform: "unset !important",
      "&:hover": {
        backgroundColor: "#e0e3e7 !important",
      },
    },
  },
  "& .verticalDivider": {
    height: 42,
    width: 1,
    backgroundColor: "#e0e0e0",
    marginRight: 25,
    marginLeft: 17,
  },
  "& .tabFilterContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "auto",
    "& .verticalDivider": {
      height: "42px",
      width: "1px",
      backgroundColor: "#e0e0e0",
      marginRight: "25px",
      marginLeft: "22px",
    },
  },
  "& .filterDisplayContainer": {
    padding: 8,
    alignItems: "center",
    flexWrap: "wrap",
    gap: 8,
    display: "grid",
    gridTemplateRows: "40px 40px",
    gridTemplateColumns: "169px 169px",
    "& .filterContainer": {
      minWidth: "65px !Important",
    },
  },
  "& .arrowIcon": {
    marginLeft: 5,
  },
}));
