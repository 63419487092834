import React from "react";
import moment from "moment";
import { SALES_ORDER_DIAMOND_STATUS, TASK_TYPE, taskStatus } from "./constants";
import lateIcon from "../assets/flag-icons/red_diamond.svg";
import nearDueIcon from "../assets/flag-icons/orange_diamond.svg";
import nearDueOutlined from "../assets/flag-icons/orange-diamond-white-outline.png";
import lateIconOutlined from "../assets/flag-icons/red-diamond-white-outline.png";
import { typeOfTask } from "../api/types/dbTables";
import { hexToRgbA } from "./helpers";
import AppConfig from "../constants/AppConfig";

export function isThereGhostTask({ task, ghostTask, flagViewOptions }) {
  if (
    flagViewOptions[viewOptionLabels.GHOSTED_CHANGES] &&
    JSON.stringify({
      startDate: task.startDate,
      finishDate: task.finishDate,
      assignedTo: task.assignedTo,
      status: task.status,
    }) !==
      JSON.stringify({
        startDate: ghostTask.startDate,
        finishDate: ghostTask.finishDate,
        assignedTo: ghostTask.assignedTo,
        status: ghostTask.status,
      })
  ) {
    return true;
  }
  return false;
}

function convertHex(hex, opacity) {
  if (!hex) return "#000";
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  let result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  return result;
}

export function getTaskDiamond({ status, styles, isOutlined = false }) {
  switch (status) {
    case taskStatus.LATE:
      return (
        <img
          draggable={false}
          src={isOutlined ? lateIconOutlined : lateIcon}
          alt="late"
          style={styles}
        />
      );
    case taskStatus.NEAR_DUE:
      return (
        <img
          draggable={false}
          src={isOutlined ? nearDueOutlined : nearDueIcon}
          alt="late"
          style={styles}
        />
      );
    case taskStatus.NOT_STARTED:
    case taskStatus.IN_PROGRESS:
      return <div></div>;
    case taskStatus.COMPLETE:
      return null;
    default:
      return null;
  }
}

export function getOrderDiamond({ order, styles, isOutlined = false }) {
  if (order.diamondStatus === SALES_ORDER_DIAMOND_STATUS.LATE) {
    return (
      <img
        src={isOutlined ? lateIconOutlined : lateIcon}
        alt="late"
        style={styles}
      />
    );
  } else if (order.diamondStatus === SALES_ORDER_DIAMOND_STATUS.NEAR_DUE) {
    return (
      <img
        src={isOutlined ? nearDueOutlined : nearDueIcon}
        alt="late"
        style={styles}
      />
    );
  }
  return null;
}

export const fullDependencyType = {
  SS: "Start-Start",
  FS: "Finish-Start",
  FF: "Finish-Finish",
};

export function getReminderDays({ date, isEndDate = true }) {
  const diffdays = moment()
    .startOf("day")
    .diff(moment(date).startOf("day"), "days");
  if (diffdays === -1) {
    return (
      <b
        style={{
          color: "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Tomorrow
      </b>
    );
  } else if (diffdays === 1) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Yesterday
      </b>
    );
  } else if (diffdays > 0) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        ({Math.abs(diffdays)} {diffdays === 1 ? "day" : "days"} ago)
      </b>
    );
  } else if (diffdays < 0) {
    return (
      <b style={{ marginLeft: 4 }}>
        ({"in " + Math.abs(diffdays)}{" "}
        {Math.abs(diffdays) === 1 ? "day" : "days"})
      </b>
    );
  } else if (diffdays === 0) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Today
      </b>
    );
  }
}

export const viewOptionsArrayv2 = [
  {
    id: "weekends",
    label: "Weekends",
    enabled: true,
    position: "left",
  },
  {
    id: "milestones",
    label: "Milestones",
    enabled: true,
    position: "left",
  },
  {
    id: "ad_hoc_tasks",
    label: "Ad-hoc tasks",
    enabled: true,
    position: "left",
  },
  {
    id: "diamond_in_task_bars",
    label: "Diamonds in task bars",
    enabled: true,
    position: "left",
  },
  {
    id: "full_table_details",
    label: "Full table details",
    enabled: false,
    position: "right",
  },
  {
    id: "arrows",
    label: "Arrows",
    enabled: true,
    position: "right",
  },
  {
    id: "section_dividers",
    label: "Section dividers",
    enabled: true,
    position: "right",
  },
  {
    id: "ghosted_changes",
    label: "Ghosted changes",
    enabled: true,
    position: "right",
  },
];

export const milestoneHeader = [
  {
    id: 1,
    label: "Color",
    width: "15%",
  },
  {
    id: 2,
    label: "Title",
    width: "60%",
  },
  {
    id: 3,
    label: "Date",
    width: "20%",
  },
  {
    id: 4,
    label: "",
    width: "5%",
  },
];

export function getFlagViewOptions(viewOptions) {
  const flagViewOptions = {};
  viewOptions.forEach((option) => {
    flagViewOptions[option.label] = option.enabled;
  });
  return flagViewOptions;
}

const viewOptionLabels = {
  WEEKENDS: "Weekends",
  MILESTONES: "Milestones",
  AD_HOC_TASKS: "Ad-hoc tasks",
  DIAMOND_IN_TASK_BARS: "Diamonds in task bars",
  ARROWS: "Arrows",
  SECTION_DIVIDERS: "Section dividers",
  GHOSTED_CHANGES: "Ghosted changes",
  JUMP_TO_THE_END_BUTTONS: "Jump to the end buttons",
  FULL_TABLE_DETAILS: "Full table details",
};

export const colorPickerArray = [
  {
    color: "#F9D451",
    position: "top",
  },
  {
    color: "#FA9917",
    position: "top",
  },
  {
    color: "#768EE1",
    position: "top",
  },
  {
    color: "#FF7C7C",
    position: "top",
  },
  {
    color: "#A462F8",
    position: "top",
  },
  {
    color: "#67D2EA",
    position: "top",
  },
  {
    color: "#C8EA67",
    position: "top",
  },
  {
    color: "#A0B895",
    position: "top",
  },
  {
    color: "#59DCB5",
    position: "bottom",
  },
  {
    color: "#6FD36D",
    position: "bottom",
  },

  {
    color: "#FF4F4F",
    position: "bottom",
  },
  {
    color: "#C09A6D",
    position: "bottom",
  },
  {
    color: "#989898",
    position: "bottom",
  },
  {
    color: "#D7B1A5",
    position: "bottom",
  },
  {
    color: "#D28C8C",
    position: "bottom",
  },
  {
    color: "#D46BEE",
    position: "bottom",
  },
];

export function getTaskReference({ task, vendorIndex }) {
  if (task.isAdHocTask) {
    return "";
  } else if (task.type === TASK_TYPE.SALES_ORDER) {
    return `S.${vendorIndex || task.number}`;
  } else {
    return `P.${vendorIndex || task.number}`;
  }
}

export function getPredecesorTask({ predecesorTask }) {
  if (!predecesorTask || !predecesorTask.id) {
    return "";
  } else if (predecesorTask.type === TASK_TYPE.SALES_ORDER) {
    return "S." + predecesorTask.number;
  } else {
    return "P." + predecesorTask.number;
  }
}

export { convertHex, viewOptionLabels };

export function getTaskBackgroundColor({ task, isWhite }) {
  switch (task.type) {
    case typeOfTask.SALES_ORDER:
      return hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      );
    case typeOfTask.SHIPMENT:
      return hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      );
    case typeOfTask.PURCHASE_ORDER: {
      if (isWhite) {
        return "#FFF";
      } else {
        return hexToRgbA(AppConfig.themeColors.purchaseOrderBorderColor, 0.2);
      }
    }
    default:
      return "#fff";
  }
}
