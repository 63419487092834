import moment from "moment";
import { getRandomId, sortObjectsBy } from "../../helpers/helpers";
import { taskStatus, triggerTaskTypes } from "../../helpers/tasks";
import {
  TASK_FILTER,
  TASK_MOVED_STATUS,
} from "../../helpers/constants";
import { getShipmentValue } from "../../helpers/orderDashboard";
import {
  getCorrectTimezone,
  taskCompletionVerifier,
} from "../../helpers/ganttChart";
import { typeOfTask } from "../../api/types/dbTables";

export const getTaskUpdated = ({
  task,
  user,
  purchaseOrder = {},
  salesOrder,
  shipments = [],
  companyId,
}) => {
  const notificationId = getRandomId();
  const dayOffset = moment()
    .startOf("day")
    .diff(moment(task.finishDate).startOf("day"), "days");
  const { duration, startDate, finishDate } = taskCompletionVerifier({
    task: {
      ...task,
      startDate: getCorrectTimezone({
        date: task.startDate,
        isServerTime: true,
      }).valueOf(),
      finishDate: getCorrectTimezone({
        date: task.finishDate,
        isServerTime: true,
      }).valueOf(),
    },
  });
  const fieldsToUpdate = {
    complete: !task.complete,
    completedBy: user.id,
    dayOffset: dayOffset,
    status: taskStatus.COMPLETE,
    moved: dayOffset !== 0 ? TASK_MOVED_STATUS.WAITING : true,
    notificationId,
    PONumber: purchaseOrder.number || "",
    SONumber: salesOrder.number,
    customerId: salesOrder.customerId,
    factoryId:
      task.type === typeOfTask.SALES_ORDER ? "" : purchaseOrder.factoryId,
    duration,
    finishDate,
    startDate,
    triggerType: triggerTaskTypes.COMPLETE,
    templateDuration: task.duration,
  };
  const notificationTask = {
    ...fieldsToUpdate,
    ...task,
    salesOrderId: salesOrder.id,
    purchaseOrderId: purchaseOrder.id,
    customerId: salesOrder.customerId,
    factoryId: purchaseOrder.factoryId,
    PONumber: purchaseOrder.number || "",
    SONumber: salesOrder.number,
    shipmentNumber: getShipmentValue({
      shipments,
      shipmentId: task.shipmentId,
      field: "number",
    }),
    finalDestination: getShipmentValue({
      shipments,
      shipmentId: task.shipmentId,
      field: "finalDestination",
    }),
    isAdHocTask: false,
    companyId: companyId,
    createdBy: user.id,
  };
  return { fieldsToUpdate, dayOffset, notificationId, notificationTask };
};

export const getListIndexAdHoc = ({
  salesOrderTasks,
  purchaseOrderTasks,
  adHocTask,
}) => {
  let POTasksLenght = purchaseOrderTasks.filter(
    (item) => item.stage === adHocTask.stage
  ).length;
  let SOTasksLenght = salesOrderTasks.filter(
    (item) => item.stage === adHocTask.stage
  ).length;

  const adHocIndex = POTasksLenght + SOTasksLenght + 1;
  return adHocIndex;
};

const filterTask = ({ task, showCompleted, listTask = [] }) => {
  if (showCompleted) {
    return true;
  }
  const currentTask = listTask.find((item) => item.id === task.id) || {};
  const hasPendingTask = !currentTask.complete || !currentTask.moved;
  return (
    !task.complete ||
    task.moved === TASK_MOVED_STATUS.WAITING ||
    (!task.moved &&
      (task.type === typeOfTask.SALES_ORDER ? hasPendingTask : true))
  );
};

export const filterTaskList = ({
  salesOrderTasks,
  taskFilter,
  user,
  showCompleted,
  purchaseOrderTasks,
  shipmentTasks,
  pendingToCompleted = {},
}) => {
  const SOTasks = [...salesOrderTasks.map((task) => ({ ...task }))]
    .filter((task) =>
      taskFilter === TASK_FILTER.MINE ? task.assignedTo === user.id : true
    )
    .filter((task) =>
      filterTask({
        task,
        showCompleted: showCompleted || pendingToCompleted[task.id],
        listTask: salesOrderTasks,
      })
    );
  const POTasks = [
    ...purchaseOrderTasks.filter(
      (task) => task.type === typeOfTask.PURCHASE_ORDER
    ),
    ...shipmentTasks,
  ]
    .filter((task) =>
      taskFilter === TASK_FILTER.MINE ? task.assignedTo === user.id : true
    )
    .filter((task) =>
      filterTask({
        task,
        showCompleted: showCompleted || pendingToCompleted[task.id],
        listTask: purchaseOrderTasks,
      })
    );
  return { SOTasks, POTasks };
};


export const getListOptions = ({
  filterList = [],
  allList = [],
  showAll,
  key = {
    value: "id",
    label: "name",
    user: "",
    type: "type",
  },
  sorted = "label",
  prefix = "",
}) => {
  let selectedData = [];
  if (showAll) {
    return allList
      .map((item) => ({
        value: item[key.value],
        label: `${prefix}${item[key.label]}`,
        type: item[key.type] || "",
        user: key.user
          ? {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              displayName: item.displayName,
              avatar: item.avatar,
            }
          : false,
      }))
      .sort(sortObjectsBy(sorted, false));
  } else {
    selectedData = allList
      .filter((item) => filterList.includes(item.id))
      .map((item) => ({
        value: item[key.value],
        label: `${prefix}${item[key.label]}`,
        type: item[key.type] || "",
        user: key.user
          ? {
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              displayName: item.displayName,
              avatar: item.avatar,
            }
          : false,
      }))
      .sort(sortObjectsBy(sorted, false));
  }
  return selectedData;
};
