import React, { useState, useEffect } from "react";
import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import ProgressStage from "./ProgressStage";
import moment from "moment";
import { groupBySOHeadCellsPOs } from "../../components/Managers/TableHeaderProvider";
import numeral from "numeral";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import { useUser } from "../../hooks/user";
import { NavLink } from "react-router-dom";
import FooterTable from "../../components/Tables/FooterTable";
import { footerOrdersByPhasePOsWithinSO } from "../../components/Managers/FooterTableManager";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import { TableCell } from "@mui/material";
import TagsModal from "../../components/Modal/TagsModal";
import {
  getTagsLabel,
  verifyVendorIcon,
  isTradeDashEmployee,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import { orderByPhasePOGroupBySOSetup } from "../../helpers/screenHeaderSetup";
import {
  generalPermissions,
  headerColumns,
  notAllowedToSee,
} from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import { getTagStatus } from "../../helpers/salesOrder";
import { useFactories } from "../../hooks/factories";
import { useFavorites, useFavoritesList } from "../../hooks";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import POBadge from "../../components/Badge/POBadge";
import { colors } from "../../assets/jss/variables";

function FilterTablePO({
  purchaseOrderList,
  headerStages,
  filtersTable,
  onUpdateFilters,
  tags,
  onUpdateItem,
  isAllowed,
  isThereProposalQuoteColumn,
  filters = { factory: [] },
  topHeader = [],
  expanded = false,
}) {
  const user = useUser();
  const [headerCells, setHeaderCells] = useState([]);
  const filterTag = filters.tag || [];
  const factories = useFactories();
  const favorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });

  function zeroIsBlank(item, isCBM) {
    let dformat = "$0,0.00";
    if (isCBM) {
      dformat = "0.00";
    }
    return numeral(item).format("$0,0.00") === "$0.00"
      ? " "
      : numeral(item).format(dformat);
  }

  function getPOsTotalAmount(purchaseOrders) {
    let sum = 0;
    purchaseOrders.forEach((po) => (sum += numeral(po.total).value()));
    return numeral(sum).format("$ 0,0.00");
  }

  useEffect(() => {
    let cpyHeaderCell = orderByPhasePOGroupBySOSetup.map((header) => {
      const itemTable = groupBySOHeadCellsPOs[header.name];
      const style = itemTable.styles;
      let widthSize = style.width;
      if (itemTable.reference === "number") {
        const po =
          topHeader.find((header) => header.reference === "number") || {};
        widthSize = po.headerWidth || style.width;
      }
      if (itemTable.reference === "factoryName") {
        const po =
          topHeader.find((header) => header.reference === "customerName") || {};
        widthSize = po.headerWidth || style.width;
      }

      if (
        itemTable[generalPermissions.PURCHASE_ORDER_AMOUNT] &&
        !isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
      ) {
        return {
          ...header,
          ...itemTable,
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? isThereProposalQuoteColumn
              : header.enabled,
          styles: {
            ...itemTable.styles,
            width: widthSize,
          },
        };
      } else {
        return {
          ...header,
          ...itemTable,
          styles: {
            ...itemTable.styles,
            width: widthSize,
          },
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? isThereProposalQuoteColumn
              : header.enabled,
        };
      }
    });
    setHeaderCells(cpyHeaderCell);
  }, [isAllowed]);

  function filterTablePOSelector({ column, enabled, index, itemPO }) {
    switch (column) {
      case headerColumns.EXPAND:
        return enabled && <TableCell className="emptyTableCell" />;
      case headerColumns.FAVORITE:
        return (
          enabled && (
            <TableCell key={index} center classes={{ root: "favorite" }}>
              {getStarFavorite({
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: itemPO.id }),
                addFavorite: () =>
                  addFavorite({
                    favoritesDB,
                    id: itemPO.id,
                    user,
                    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
                  }),
                itemId: itemPO.id,
                favorites: favorites,
              })}
            </TableCell>
          )
        );

      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell key={index} className="diamond">
              {getDiamondStatus({
                diamondStatus: itemPO.diamondStatus,
                itemStatus: itemPO.status,
              })}
            </TableCell>
          )
        );

      case headerColumns.PO:
        const currentVendor = factories.find(
          (factory) => factory.id === itemPO.factoryId
        );
        const showIconVendor = verifyVendorIcon({
          factoryFilter: filters.factory,
          item: itemPO,
          currentVendor,
        });
        const purchaseOrderTags = itemPO.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={"poNumber"}>
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={itemPO.id}
                    isReadOnly={isTradeDashEmployee(user)}
                    label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                    tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                    allTags={tags}
                    item={itemPO}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    imageStyle={{
                      height: 15,
                      width: 13,
                      position: "relative",
                      top: 1,
                    }}
                    isThereItemRef={false}
                    status={getTagStatus({
                      tags: purchaseOrderTags,
                      currentTags: filterTag,
                    })}
                  />
                )}
                {showIconVendor && (
                  <TooltipTD label={itemPO.factoryName}>
                    <img
                      src={FactoryIcon}
                      alt={"FactoryIcon"}
                      style={{
                        width: 11,
                        marginRight: 5,
                        marginBottom: 5,
                      }}
                    />
                  </TooltipTD>
                )}

                <NavLink
                  style={{
                    color: colors.primaryDark,
                    cursor: "pointer",
                    marginLeft: !showIconVendor ? 16 : 0,
                    textDecoration: "none",
                  }}
                  to={getDashboardSearchPath({
                    salesOrderId: itemPO.salesOrderId,
                    purchaseOrderId: itemPO.id,
                    shipmentId: itemPO.shipmentIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                  })}
                  id="list-view-content-purchaseorder"
                >
                  <POBadge
                    number={itemPO.number}
                    className="purchase-order-badge-white"
                  />
                </NavLink>
                <ThreePanelButton
                  soId={itemPO.salesOrderId}
                  poId={itemPO.id}
                  shipmentId={itemPO.shipmentIds[0]}
                  tab={1}
                />
              </div>
            </TableCell>
          )
        );

      case headerColumns.VENDOR:
        return (
          enabled && (
            <TableCell key={index} className="factoryName">
              <TooltipTD
                label={itemPO.factoryName}
                style={{ width: "fit-content", display: "flex" }}
              >
                <NavLink
                  to={`/app/factories/${itemPO.factoryId}`}
                  style={{
                    verticalAlign: "-webkit-baseline-middle",
                    color: "#25486D",
                    textDecoration: "underline",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "-webkit-fill-available",
                  }}
                  id="list-view-content-factory"
                >
                  {itemPO.factoryName}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.PO_VALUE:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="deposit">
              {isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
                ? zeroIsBlank(itemPO.total)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.ORDER_READY_DATE:
        return (
          enabled && (
            <TableCell key={index} className="shipDate" id="shipDate">
              {itemPO.orderReadyDate
                ? moment(itemPO.orderReadyDate).format("M/D/YY")
                : ""}
            </TableCell>
          )
        );

      case headerColumns.POS:
        return enabled && <TableCell key={index} className="emptyTableCell" />;

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled &&
          isThereProposalQuoteColumn && (
            <TableCell key={index} className="proposalTasks">
              <ProgressStage
                tasks={itemPO.proposalTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="preProductionTasks">
              <ProgressStage
                tasks={itemPO.preProductionTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="productionTasks">
              <ProgressStage
                tasks={itemPO.productionTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell key={index} className="bookingTransitTasks">
              <ProgressStage
                tasks={itemPO.bookingTransitTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell key={index} className="paymentBillingTasks">
              <ProgressStage
                tasks={itemPO.paymentBillingTasks}
                purchaseOrderId={itemPO.id}
                salesOrderId={itemPO.salesOrderId}
              />
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={index} />;

      default:
        return <TableCell key={index} />;
    }
  }

  return (
    <MainTableRefactor
      className={cx("poGroupBySO", { showTable: expanded })}
      headCells={headerCells.map((cell) => {
        if (cell.label === headerColumns.PROPOSAL_QUOTE) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.proposalQuoteMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRE_PRODUCTION) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.preProductionMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRODUCTION) {
          return {
            ...cell,
            styles: { ...cell.styles, width: headerStages.productionMinWidh },
          };
        } else if (cell.label === headerColumns.BOOKING_TRANSIT) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.bookingTransitMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PAYMENT_BILLING) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.paymentBillingMinWidh,
            },
          };
        } else {
          return cell;
        }
      })}
      stylesTable={{
        boxShadow: "2px 2px 14px 0px rgba(0, 0, 0, 0.25)",
      }}
      bodyRows={purchaseOrderList}
      filters={filtersTable}
      onUpdateFilters={onUpdateFilters}
      footer={
        <FooterTable
          footer={footerOrdersByPhasePOsWithinSO(
            purchaseOrderList.length,
            getPOsTotalAmount(purchaseOrderList),
            isAllowed
          )}
          headerCells={headerCells}
        />
      }
      renderRow={(itemPO) => (
        <MainRowStyled
          className={cx(mainRowClasses.SubTableRow, mainRowClasses.hoverEffect)}
          key={
            itemPO.purchaseOrderId
              ? itemPO.id + itemPO.purchaseOrderId
              : itemPO.id + itemPO.salesOrderId
          }
        >
          {headerCells.map((header) =>
            filterTablePOSelector({
              column: header.name,
              enabled: header.enabled,
              index: itemPO.id + header.name,
              itemPO,
            })
          )}
        </MainRowStyled>
      )}
      key="po-table"
      sortArrowsColor="#FFF"
    />
  );
}

export default FilterTablePO;
