import { arrayMove } from "@dnd-kit/sortable";

function restoredSalesOrderTasksWithIndexes(newTasks) {
  const updatedListIndexInTasks = Object.keys(newTasks).flatMap((key) =>
    newTasks[key].map((task, index) => ({
      ...task,
      listIndex: index + 1,
    }))
  );
  const rootTasksUpdatedInTasks = updatedListIndexInTasks.map((task, index) => {
    if (index === 0) {
      return {
        ...task,
        number: index + 1,
        dependency: "",
        dependencyType: "",
        offset: 0,
      };
    } else {
      return { ...task, number: index + 1 };
    }
  });
  return rootTasksUpdatedInTasks;
}

function restoredPOTasksWithIndexes(newTasks) {
  const updatedListIndexInTasks = Object.keys(newTasks).flatMap((key) =>
    newTasks[key].map((task, index) => ({
      ...task,
      listIndex: index + 1,
    }))
  );
  return updatedListIndexInTasks.map((task, index) => {
    return { ...task, number: index + 1 };
  });
}

export function getReorderedTasks({
  active = {},
  over = {},
  items = {},
  isPOTemplate = false,
}) {
  if (!active || !over) return false;
  const currentTasks = Object.values(items).flat();
  const activeTask = currentTasks.find((task) => task.id === active.id) || {};
  const overTask = currentTasks.find((task) => task.id === over?.id) || {};
  const activeContainer = activeTask.stage || active.id;
  const overContainer = overTask.stage || over.id;
  if (!activeContainer || !overContainer) return false;
  let newTasks = {};
  const activeIndex = items[activeContainer].findIndex(
    (task) => task.id === active.id
  );
  const overIndex = items[overContainer].findIndex(
    (task) => task.id === over.id
  );
  const updatedTaskList = arrayMove(
    items[activeContainer],
    activeIndex,
    overIndex
  );
  newTasks = {
    ...items,
    [activeContainer]: updatedTaskList,
  };

  let tasksToUpdateValidated = [];
  if (isPOTemplate) {
    tasksToUpdateValidated = restoredPOTasksWithIndexes(newTasks);
  } else {
    tasksToUpdateValidated = restoredSalesOrderTasksWithIndexes(newTasks);
  }
  return tasksToUpdateValidated;
}

export function validateDragOver({ active, over, items }) {
  if (!active || !over) return false;
  const currentTasks = Object.values(items).flat();
  const activeTask = currentTasks.find((task) => task.id === active.id) || {};
  const overTask = currentTasks.find((task) => task.id === over?.id) || {};
  const activeContainer = activeTask.stage;
  const overContainer = overTask.stage || over.id;
  if (!activeContainer || !overContainer || activeContainer === overContainer) {
    return false;
  }
  return true;
}

export function handleDragOverState({
  tasksByStages = {},
  activeContainer,
  overContainer,
  active,
  over,
  draggingRect,
}) {
  const activeTasks = tasksByStages[activeContainer];

  const overTasks = tasksByStages[overContainer];
  const activeIndex = activeTasks.findIndex((task) => task.id === active.id);
  const overIndex = overTasks.findIndex((task) => task.id === over.id);
  if (!activeContainer || !overContainer || activeContainer === overContainer) {
    return tasksByStages;
  }
  let newIndex;
  if (overContainer in tasksByStages) {
    newIndex = overTasks.length;
  } else {
    const isBelowLastItem =
      over &&
      overIndex === overTasks.length - 1 &&
      draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

    const modifier = isBelowLastItem ? 1 : 0;

    newIndex = overIndex >= 0 ? overIndex + modifier : overTasks.length;
  }
  return {
    ...tasksByStages,
    [activeContainer]: [
      ...tasksByStages[activeContainer].filter((item) => item.id !== active.id),
    ],
    [overContainer]: [
      ...tasksByStages[overContainer].slice(0, newIndex),
      { ...activeTasks[activeIndex], stage: overContainer }, // Actualizar el stage
      ...tasksByStages[overContainer].slice(newIndex),
    ],
  };
}
