import React from "react";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import TagsScreen from "../../container/TagsScreen/TagsScreen";
import Users from "../../components/CompanyTabs/Users";
import Reports from "../../components/CompanyTabs/Reports";
import Projects from "../../components/CompanyTabs/Dashboard";
import GLAccountsTab from "../../components/CompanyTabs/GLAccounts";
import UserManager from "../../components/UserManagement/UserManager";
import Documents from "../../components/CompanyTabs/Documents";
import PermissionGroupsAdmin from "../../components/CompanyTabs/PermissionGroupsAdmin";
import SalesOrderTemplate from "../../components/CompanyTabs/SalesOrderTemplateContainer";
import ImportedData from "../../components/CompanyTabs/ImportedData";
import ErrorLog from "../../components/CompanyTabs/ErrorLog";
import Settings from "../../components/CompanyTabs/Settings";
import BackEndErrorLog from "../../components/CompanyTabs/BackEndErrorLog";
import CompanyPermissionsTab from "../../components/CompanyTabs/CompanyPermissionsTab";
import Factories from "../../components/CompanyTabs/Factories";
import Customers from "../../components/CompanyTabs/Customers";
import FeatureFlags from "../../components/CompanyTabs/FeatureFlags";
import Categories from "../../components/CompanyTabs/Categories";
import SuppliersTab from "../../components/CompanyTabs/SuppliersTab";
import DeleteNotes from "../../components/CompanyTabs/DeleteNotes";
import PurchaseOrderTemplate from "../../components/CompanyTabs/PurchaseOrderTemplateContainer";

export const ADMINROUTES = [
  {
    path: "labels",
    element: <TagsScreen />,
    label: "Labels",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_LABELS,
  },
  {
    path: "categories",
    element: <Categories />,
    label: "Categories",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_CATEGORIES,
  },
  {
    path: "reports",
    element: <Reports />,
    label: "Reports",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_REPORTS,
  },
  {
    path: "users",
    element: <Users />,
    label: "Users",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_USERS,
  },
  {
    path: "roles",
    element: <UserManager />,
    label: "Roles",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_ROLES,
  },
  {
    path: "documents",
    element: <Documents />,
    label: "Document Placeholders",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PLACEHOLDER,
  },
  {
    path: "permission_groups",
    element: <PermissionGroupsAdmin />,
    label: "Access Groups",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_ACCESS_GROUPS,
  },
  {
    path: "sales_orders_template",
    element: <SalesOrderTemplate />,
    label: "Sales Order Templates",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_TEMPLATE,
  },
  {
    path: "purchase_orders_template",
    element: <PurchaseOrderTemplate />,
    label: "PO Templates",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PO_TEMPLATE,
  },
  {
    path: "gl_accounts",
    element: <GLAccountsTab />,
    label: "G/L Accounts",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_GL_ACCOUNTS,
  },
  {
    path: "suppliers",
    element: <SuppliersTab />,
    label: "Suppliers",
    permission: GENERAL_PERMISSION_VALUE.SUPPLIER,
  },
  {
    path: "projects",
    element: <Projects />,
    label: "Projects",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS,
  },
  {
    path: "imported_api__data",
    element: <ImportedData />,
    label: "API Imported Data",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_DATA,
  },
  {
    path: "api_error_log",
    element: <ErrorLog />,
    label: "API Error Log",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_ERROR_LOG,
  },
  {
    path: "api_settings",
    element: <Settings />,
    label: "API Settings",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_API_CONFIGURATION,
  },
  {
    path: "back-end_error_log",
    element: <BackEndErrorLog />,
    label: "Back-End Error Log",
    permission: GENERAL_PERMISSION_VALUE.SETTINGS_BACKEND_ERRORLOG,
  },
  {
    path: "delete_note",
    element: <DeleteNotes />,
    label: "Delete Note",
    onlySuperAdmin: true,
  },
  {
    path: "companyPermission",
    element: <CompanyPermissionsTab />,
    label: "Company Permissions",
    onlyEmployee: true,
  },
  {
    path: "featureFlags",
    element: <FeatureFlags />,
    label: "Feature Flags",
    onlyEmployee: true,
  },
  {
    path: "factories",
    element: <Factories />,
    label: "Vendors",
    onlyEmployee: true,
  },
  {
    path: "customers",
    element: <Customers />,
    label: "Customers",
    onlyEmployee: true,
  },
  {
    menu_title: "Expenses",
    path: "notFound",
    element: <h4>You are not allowed to view any tabs</h4>,
  },
];
