import { cx } from "@emotion/css";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  formatWithComma,
  getCustomerName,
  getDashboardSearchPath,
} from "../../../helpers/helpers";
import { itemTableReference, screenPath } from "../../../helpers/constants";
import SKUEditableLabelV2 from "./SKUEditableLabelV2";

import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../sections/helpers";
import TooltipTD from "../../Tooltip/TooltipTD";
import { HiddenDataIcon } from "../../../helpers/iconSvgPath";
import { getDiffSOQuantity } from "./SKUHelper";
import SalesOrderBadge from "../../Badge/SalesOrderBadge";

function SalesOrderItemCells({
  itemSKU,
  salesOrderItem,
  customers,
  shipments = [],
  queryParams,
  onChangeData = () => {},
  isAllowed,
  isReadOnly,
  firstSO,
  onOpenRow,
  showIcon,
  soList,
}) {
  const { dangerStyle, diffQuantity } = getDiffSOQuantity({
    salesOrderItem,
    shipments,
  });
  const hiddenClass = showIcon ? "hiddenClass" : "";
  const customerName = getCustomerName(
    customers,
    salesOrderItem.customerId,
    true
  );

  const toolTipLabel = () => {
    if (diffQuantity === 0) return "";

    const sizeComparison = diffQuantity > 0 ? "LARGER" : "SMALLER";
    return (
      <span>
        the total shipped quantity is <b>{sizeComparison}</b> than the Sales
        Order quantity
      </span>
    );
  };

  return (
    <div className={"soRowContainer"}>
      <div
        className={cx("salesOrderCell", "cellBorderLeft", "alignRight", {
          dangerStyle,
        })}
        style={{ paddingRight: 0 }}
      >
        <SKUEditableLabelV2
          value={salesOrderItem.allocation}
          showToolTip={dangerStyle || !showIcon}
          tooltipLabel={toolTipLabel()}
          className={hiddenClass}
          disabled={!isAllowed || isReadOnly || showIcon}
          labelFormat={(value) => formatWithComma(value)}
          onChangeLabel={(temporalLabel) => {
            onChangeData({
              item: itemSKU,
              row: {
                ...salesOrderItem,
                allocation: temporalLabel,
              },
              reference: itemTableReference.SALES_ORDERS,
            });
          }}
          preventDelete
        />
      </div>
      <div
        className={cx("salesOrderCell", "navLinkClass")}
        style={{
          textDecoration: "none",
        }}
      >
        {salesOrderItem.number && (
          <NavLink
            to={getDashboardSearchPath({
              ...queryParams,
              salesOrderId: salesOrderItem.id,
              section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
            })}
            className={hiddenClass}
          >
            <SalesOrderBadge
              number={salesOrderItem.number}
              className="sales-order-badge-white"
            />
          </NavLink>
        )}
        {firstSO && (
          <div
            className="expandIconContainer"
            style={{ opacity: showIcon ? 1 : 0, zIndex: showIcon ? 1 : -1 }}
            onClick={() => onOpenRow(itemSKU.id, true)}
          >
            <HiddenDataIcon svgClass={cx("expandRow")} />
            {soList.length}
          </div>
        )}
      </div>
      <div
        className={cx(
          "salesOrderCell",
          "cellBorderRight",
          "customized-tooltip"
        )}
      >
        {customerName !== "N/A" && (
          <TooltipTD
            label={customerName}
            className={("tooltTipSpan", hiddenClass)}
            showToolTip={!hiddenClass}
            style={{ display: "flex" }}
          >
            <NavLink
              to={`/app/${screenPath.CUSTOMER}/${salesOrderItem.customerId}`}
              className={cx("navLinkClass", "tooltTipSpan")}
              style={{ maxWidth: 120 }}
              disabled
            >
              {customerName}
            </NavLink>
          </TooltipTD>
        )}
      </div>
    </div>
  );
}

export default SalesOrderItemCells;
