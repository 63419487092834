import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class POTemplateObj {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    creationDate = now(),
    createdBy = "",
    currentVersionId = "",
    salesOrderTemplateId = "",
    salesOrderTemplateVersion = "",
    emoji = "",
    isOutdated = false,
    templateIndexes = {},
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.creationDate = creationDate;
    this.createdBy = createdBy;
    this.currentVersionId = currentVersionId;
    this.salesOrderTemplateId = salesOrderTemplateId;
    this.salesOrderTemplateVersion = salesOrderTemplateVersion;
    this.emoji = emoji;
    this.isOutdated = isOutdated;
    this.templateIndexes = templateIndexes;
  }
}
