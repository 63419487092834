import React, { useContext, useEffect, useRef } from "react";
import {
  GANTT_CHART_ACTION,
  GanttChartTemplateContext,
  soTaskTableSize,
} from "../../CompanyTabs/SalesOrderTemplateContainer";
import { TaskTableStyled } from "../../Timeline/styles";
import { GANTTCHART_TABLE_SIZE } from "../../Timeline/GanttChartContext";

import DndTaskTemplate from "./DndTaskTemplate";

function TaskTemplateContent({
  tasks,
  onAddTask = () => {},
  vendorTemplate = false,
}) {
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { taskPhases, tableSize, graphTableRef } = ganttChartTemplateState;

  const taskTableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (taskTableRef.current) {
        const currentTop = graphTableRef.current.scrollTop;
        taskTableRef.current.scrollTop = currentTop;
      }
    };
    if (graphTableRef && graphTableRef.current) {
      graphTableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (graphTableRef && graphTableRef.current) {
        graphTableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [graphTableRef]);

  useEffect(() => {
    if (tableSize === GANTTCHART_TABLE_SIZE.MEDIUM) {
      const currentTop = taskTableRef?.current?.scrollTop;
      if (currentTop && graphTableRef && graphTableRef.current) {
        graphTableRef.current.scrollTop = currentTop;
      }
    }
  }, [tableSize]);

  const handleTaskStage = (stage) => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          ...taskPhases,
          [stage]: !taskPhases[stage],
        },
      },
    });
  };

  return (
    <TaskTableStyled
      id="soTask-table"
      className="soTaskTable"
      ref={taskTableRef}
      style={{
        width: soTaskTableSize[tableSize],
        overflowY: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? "scroll" : "",
        paddingRight: tableSize === GANTTCHART_TABLE_SIZE.LARGE ? 10 : "",
      }}
    >
      <DndTaskTemplate
        tasks={tasks}
        handleTaskStage={handleTaskStage}
        onAddTask={onAddTask}
        vendorTemplate={vendorTemplate}
      />
    </TaskTableStyled>
  );
}

export default TaskTemplateContent;
