import React, { useState } from "react";
import PermissionsToNofity from "./PermissionNotification";

function SetupNotifications({
  onSubmit,
  item = {},
  style,
  hiddenButton = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {!hiddenButton && (
        <button
          className="button-success"
          style={{
            height: 25,
            margin: 0,
            ...style,
          }}
          onClick={(event) => {
            setIsOpen(event.currentTarget);
          }}
        >
          Change users
        </button>
      )}

      {isOpen && (
        <PermissionsToNofity
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          item={item}
          onSubmit={({ item }) => {
            onSubmit({ item });
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default SetupNotifications;
