import React, { useState } from "react";
import { sortObjectsBy } from "../../helpers/helpers";
import { SettingScreenBox, SettingsPopover } from "./styles";
import {
  POHeaderSetup,
  columnHeaderSetup,
  taskHeaderSetup,
  SOHeaderSetup,
  orderByPhasePOSetup,
  shipmentHeaderSetup,
  expensesHeaderSetup,
} from "../../helpers/screenHeaderSetup";
import TooltipTD from "../Tooltip/TooltipTD";
import { SettingsActiveIcon } from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import { cx } from "@emotion/css";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { SortableItem } from "./SortableItem";

export default function MultipleSelect({
  items,
  onChange,
  type,
  onClose = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  function onChangeAsDefault(items, newItems) {
    let itemsCpy = [...items];
    newItems.forEach((item) => {
      itemsCpy = itemsCpy.map((itemCpy) => {
        if (itemCpy.name === item.name) {
          return {
            ...itemCpy,
            index: item.index,
            enabled: item.enabled,
            unmovable: item.unmovable || false,
            styles: {
              ...itemCpy.styles,
              width: item.width ? item.width : itemCpy.styles.width,
            },
          };
        }
        return itemCpy;
      });
    });
    onChange(itemsCpy);
  }
  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
    const sourceIndex = items.findIndex((item) => item.id === active.id);
    const destinationIndex = items.findIndex((item) => item.id === over.id);
    let itemsCpy = [...items];
    const [sourceElement] = itemsCpy.splice(sourceIndex, 1);
    itemsCpy.splice(destinationIndex, 0, sourceElement);
    const updatedItems = itemsCpy.map((item, index) => ({ ...item, index }));
    onChange(updatedItems);
  }
  function handleRestoreAsDefault(ev) {
    switch (type) {
      case columnHeaderSetup.PO_HEADER_SETUP:
        return onChangeAsDefault(items, [...POHeaderSetup]);
      case columnHeaderSetup.SO_HEADER_SETUP:
        return onChangeAsDefault(items, [...SOHeaderSetup]);
      case columnHeaderSetup.TASK_HEADER_SETUP:
        return onChangeAsDefault(items, [...taskHeaderSetup]);
      case columnHeaderSetup.ORDER_BY_PHASE_PO_SETUP:
        return onChangeAsDefault(items, [...orderByPhasePOSetup]);
      case columnHeaderSetup.SHIPMENT_HEADER_SETUP:
        return onChangeAsDefault(items, [...shipmentHeaderSetup]);
      case columnHeaderSetup.EXPENSES_HEADER_SETUP:
        return onChangeAsDefault(items, [...expensesHeaderSetup]);
      default:
        break;
    }
    ev.preventDefault();
    ev.stopPropagation();
  }

  const menuItems = items
    .sort(sortObjectsBy("index", false))
    .filter((item) => item.filter);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <SettingScreenBox
      id="list-view-table-content-selectcolumns"
      className={"multiSelectContainer"}
      onClick={handleClick}
    >
      <TooltipTD label="Column settings" style={{ display: "flex" }}>
        <Button color="inherit" style={{ minWidth: 36 }}>
          <SettingsActiveIcon svgClass={cx("gearClose", { gearOpen: open })} />
        </Button>
      </TooltipTD>
      {open && (
        <SettingsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          classes={{ paper: "paperPopover" }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DndContext
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {menuItems.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  item={item}
                  items={items}
                  onChange={onChange}
                />
              ))}
            </SortableContext>
            <div
              className="defaultColumnsContainer"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
              }}
            >
              <span onClick={handleRestoreAsDefault}>RESTORE DEFAULTS</span>
            </div>
          </DndContext>
        </SettingsPopover>
      )}
    </SettingScreenBox>
  );
}
