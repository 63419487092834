import { now } from "moment";
import { getRandomId } from "../../helpers/helpers";

export default class ChangeLog {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    description = "",
    user = "",
    version = "",
    templateVersionId = "",
    templateVersion = 0,
    cloneFrom = "",
    addedTasks = [],
    deletedTasks = [],
    updatedTasks = [],
    beforeMetadata = {},
    afterMetadata = {},
    templateSubVersion = 0
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.description = description;
    this.user = user;
    this.version = version;
    this.templateVersionId = templateVersionId;
    this.templateVersion = templateVersion;
    this.addedTasks = addedTasks;
    this.deletedTasks = deletedTasks;
    this.updatedTasks = updatedTasks;
    this.cloneFrom = cloneFrom;
    this.beforeMetadata = beforeMetadata;
    this.afterMetadata = afterMetadata;
    this.templateSubVersion = templateSubVersion;
  }
}
