import React from "react";
import { Pagination, styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { cx } from "@emotion/css";

const BasicPaginationStyled = styled("div")(() => ({
  "&.root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: AppConfig.themeColors.tableHeaderColor,
    width: "100%",
    bottom: 0,
    position: "absolute",
    height: 45,
  },
  "&.taskPagination": {
    outline: "solid 1px #C5DBF6",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    position: "inherit",
  },
  "& .rootPagination": {
    margin: 8,
  },
  "& .ulPagination": {
    "& .MuiButtonBase-root": {
      fontSize: 12,
      fontWeight: 400,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      background: "rgba(120, 166, 215, 0.48)",
      border: "1px solid #578EC7",
    },
    "& .MuiPaginationItem-sizeSmall": {
      width: 22,
      minWidth: 22,
    },
  },
  "& .basicPaginationSelect": {
    border: "none",
    padding: "4px 10px",
    background: "transparent",
    fontSize: 12,
    fontWeight: 400,
    color: "#000",
  },
}));

function BasicPagination({
  count = 7,
  page = 3,
  rowsPerPage,
  onChangePagination,
  onChangeRowsPerPage,
  style = { bottom: -10 },
  paginationClass = "",
}) {
  return (
    <BasicPaginationStyled className={cx("root", paginationClass)} style={style}>
      <Pagination
        classes={{
          root: "rootPagination",
          ul: "ulPagination",
        }}
        count={count}
        color="standard"
        size="small"
        shape="rounded"
        page={page}
        onChange={onChangePagination}
      />
      <select
        className={"basicPaginationSelect"}
        onChange={onChangeRowsPerPage}
        value={rowsPerPage}
      >
        <option value={10}>10 per page</option>
        <option value={25}>25 per page</option>
        <option value={50}>50 per page</option>
      </select>
    </BasicPaginationStyled>
  );
}

export default BasicPagination;
