import React, { useState, useEffect } from "react";
import { dbTables } from "../../../api/types/dbTables";
import { BACKDROP_TYPE, SHIPMENT_STATUS } from "../../../helpers/constants";
import { getProgressValue } from "../../../helpers/helpers";
import { getPOShipments, getShipmentBadge } from "../../../helpers/shipments";
import {
  useCompanyId,
  useFavorites,
  useFavoritesList,
  useQueryParams,
} from "../../../hooks";
import {
  useOrderDashboardSetup,
  useCurrentSalesOrder,
  useSortPuchaseOrders,
} from "../../../hooks/salesOrders";
import { useShipments } from "../../../hooks/shipments";
import { useUser, useUserMentions } from "../../../hooks/user";
import { ShipmentTabsContainerStyled } from "../styles";
import TabLabel from "../TablLabel";
import { TabScrollButton } from "../TabScrollButton";
import {
  onChangeQueryParamsv2,
  QUERY_PARAMS_SCOPE,
} from "../../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "./helpers";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../../actions/DataActions";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";
import { cx } from "@emotion/css";

function ShipmentNavigation() {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const user = useUser();
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const favorites = useFavoritesList({
    dbTable: dbTables.SHIPMENT_FAVORITES,
  });
  const favoritesDB = useFavorites({
    dbTable: dbTables.SHIPMENT_FAVORITES,
  });
  const { salesOrderId, purchaseOrderId, shipmentId } =
    useOrderDashboardSetup();

  const [currentTab, setCurrentTab] = useState(0);
  const shipments = useShipments();
  const purchaseOrders = useSortPuchaseOrders();
  const currentPurchaseOrder = purchaseOrders.find(
    (item) => item.id === purchaseOrderId
  );
  const currentSalesOrder = useCurrentSalesOrder({
    salesOrderId: salesOrderId,
  });

  const currentShipments = getPOShipments({
    shipments,
    purchaseOrder: currentPurchaseOrder,
  });

  const userShipmentMentions = useUserMentions(dbTables.SHIPMENTS);

  useEffect(() => {
    const newIndex = currentShipments.findIndex(
      (item) => item.id === shipmentId
    );
    if (newIndex !== -1) {
      setCurrentTab(newIndex);
    }
  }, [shipmentId, currentShipments.length]);

  const redirect = ({ item, badgeBackdrop }) => {
    onChangeQueryParamsv2({
      scope: QUERY_PARAMS_SCOPE.SHIPMENT,
      shipmentId: item.id,
      queryParams: {
        ...queryParams,
        section: badgeBackdrop
          ? ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION
          : ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
      },
      companyId: companyId,
      navigate,
    });
    if (badgeBackdrop) {
      setBackdrop({
        type: BACKDROP_TYPE.BADGE_NUMBER,
        id: BACKDROP_TYPE.BADGE_NUMBER,
      });
    }
  };

  return (
    <ShipmentTabsContainerStyled
      className={cx("TabsContainer", {
        ShowTab: currentShipments.length > 0,
      })}
    >
      <div
        className={cx("tabShipmentContainer", {
          growShipmentContainer: currentShipments.length > 0,
        })}
      >
        {currentShipments.length > 0 &&
          queryParams.section !==
            ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION && (
            <Tabs
              id="tabs-container-id"
              value={currentTab}
              textColor="primary"
              className={"customTabs"}
              classes={{
                indicator: "customTabIndicator",
                flexContainer: "customTabsContainer",
                scrollButtons: "scrollButtons",
                scroller: "custom-shipment-scroller",
              }}
              variant="scrollable"
              scrollButtons="auto"
              ScrollButtonComponent={(props) => (
                <TabScrollButton
                  props={{ ...props, scroller: "custom-shipment-scroller" }}
                />
              )}
            >
              {currentShipments.map((item, index) => {
                let classNamePOTab = "customTab";
                const alternativeStatus =
                  item.status === SHIPMENT_STATUS.VOIDED;
                const background = currentTab === index ? "" : "#cbddf0";
                const id =
                  currentTab === index
                    ? "shipment-tab-active"
                    : "shipment-tab-" + item.id;
                return (
                  <Tab
                    id={id}
                    key={item.id}
                    onClick={(ev) => {
                      redirect({ item });
                      ev.stopPropagation();
                    }}
                    classes={{
                      selected: "activeTab",
                      wrapper: "wrapped-shipment",
                    }}
                    style={{
                      margin: "0 4px",
                      fontSize: 15,
                      backgroundColor: alternativeStatus ? "#ddd" : background,
                    }}
                    className={classNamePOTab}
                    label={
                      <TabLabel
                        currentIndex={index}
                        progress={getProgressValue(item)}
                        itemId={item.id}
                        po={{
                          ...item,
                        }}
                        label={getShipmentBadge({
                          shipment: item,
                          styles: {
                            margin: "0px 4px 0px 0px",
                            zIndex: "unset",
                          },
                          classes: shipmentBadgeClass.mediumInverted,
                        })}
                        salesOrder={{ ...currentSalesOrder }}
                        currentTab={currentTab}
                        companyId={companyId}
                        user={user}
                        // isReadOnly={isReadOnly}
                        onSetBackdrop={(ev) => {
                          redirect({ item, badgeBackdrop: true });
                          ev.stopPropagation();
                        }}
                        favoriteTable={dbTables.SHIPMENT_FAVORITES}
                        favorites={favorites}
                        favoritesDB={favoritesDB}
                        hasUserMention={!!userShipmentMentions[item.id]}
                      />
                    }
                  />
                );
              })}
            </Tabs>
          )}
      </div>
    </ShipmentTabsContainerStyled>
  );
}

export default ShipmentNavigation;
