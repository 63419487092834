/**
 * App Redux Action Types
 */

export const SET_LANGUAGE = "SET_LANGUAGE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";


export const GET_DATA = "GET_DATA";
export const GET_SO_DATA = "GET_SO_DATA";
export const ADD_DATA = "ADD_DATA";
export const UPDATE_DATA = "UPDATE_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const GET_DATA_WITH_CHILD = "GET_DATA_WITH_CHILD";
export const ADD_DATA_WITH_CHILD = "ADD_DATA_WITH_CHILD";
export const UPDATE_DATA_WITH_CHILD = "UPDATE_DATA_WITH_CHILD";
export const DELETE_DATA_WITH_CHILD = "DELETE_DATA_WITH_CHILD";
export const DASHBOARD_BACKDROP = "DASHBOARD_BACKDROP";
export const ACTIVITY_NOTE_DATE = "ACTIVITY_NOTE_DATE";
export const UNSUBSCRIBE_CURRENT_CUSTOMER = "UNSUBSCRIBE_CURRENT_CUSTOMER";
export const UNSUBSCRIBE_CURRENT_FACTORY = "UNSUBSCRIBE_CURRENT_FACTORY";
export const UNSUBSCRIBE_CURRENT_SHIPMENT = "UNSUBSCRIBE_CURRENT_SHIPMENT";
export const UNSUBSCRIBE_ITEMS = "UNSUBSCRIBE_ITEMS";
export const CLEAN_UNSUBSCRIBE_ITEMS = "CLEAN_UNSUBSCRIBE_ITEMS";
export const UNSUBSCRIBE_PURCHASE_ORDERS = "UNSUBSCRIBE_PURCHASE_ORDERS";
export const UNSUBSCRIBE_ROLE_PERMISSIONS = "UNSUBSCRIBE_ROLE_PERMISSIONS";
export const UNSUBSCRIBE_SHIPMENTS = "UNSUBSCRIBE_SHIPMENTS";
export const UNSUBSCRIBE_COLLECTION = "UNSUBSCRIBE_COLLECTION";
export const ACTIVITY_STREAM = "ACTIVITY_STREAM";
export const DELETE_ACTIVITY_STREAM = "DELETE_ACTIVITY_STREAM";
export const REPORTS_VIEW = "REPORTS_VIEW";
export const QUERY_PARAMS = "QUERY_PARAMS";
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const MARK_READ_LOADED_NOTIFICATIONS = "MARK_READ_LOADED_NOTIFICATIONS";
export const MARK_READ_ACTIVITY = "MARK_READ_ACTIVITY";
export const ORDER_DASHBOARD_ACTUAL_VIEW = "ORDER_DASHBOARD_ACTUAL_VIEW";
export const ORDER_DASHBOARD_SETUP = "ORDER_DASHBOARD_SETUP";
export const CLIENT_IS_OFFLINE = "CLIENT_IS_OFFLINE";
export const ACTIVITY_LOAD_FROM = "ACTIVITY_LOAD_FROM";
