import React from "react";

import { useCustomers } from "../../hooks/customers";
import { headerColumns, screenPath } from "../../helpers/constants";
import { TableCell } from "@mui/material";
import TooltipTD from "../Tooltip/TooltipTD";
import { NavLink } from "react-router-dom";
import ThreePanelButton from "../Buttons/ThreePanelButton";
import {
  mainRowClasses,
  MainRowStyled,
} from "../filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import { useFactories } from "../../hooks/factories";
import { getShipmentBadge } from "../../helpers/shipments";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { getVerificationPath } from "./sidebarHelper";
import SalesOrderBadge from "../Badge/SalesOrderBadge";
import POBadge from "../Badge/POBadge";

function SearchTableRow({ item, headers = [], scope, onClose = () => {} }) {
  const customers = useCustomers();
  const factories = useFactories();
  const customer = customers.find((cus) => cus.id === item.customerId) || {};
  const factory = factories.find((fac) => fac.id === item.factoryId) || {};

  function TableBody({ column, index }) {
    switch (column) {
      case headerColumns.SALES_ORDER:
        return (
          <TableCell key={index} className="number" id={column}>
            <div className="centerContainer">
              <TooltipTD
                label={item.number}
                className={cx("customized-tooltip")}
              >
                <NavLink
                  to={getVerificationPath({ id: item.id, scope })}
                  className="navLink"
                  id="list-view-content-salesorder"
                  onClick={onClose}
                  style={{ textDecoration: "none" }}
                >
                  <SalesOrderBadge number={item.number} id={item.id} />
                </NavLink>
              </TooltipTD>
              {item.purchaseOrders.length !== 0 && (
                <ThreePanelButton
                  alternativePath={getVerificationPath({
                    id: item.id,
                    scope,
                    toThreePanel: true,
                  })}
                  clickAction={() => onClose({ addBackdrop: false })}
                />
              )}
            </div>
          </TableCell>
        );
      case headerColumns.PO:
        return (
          <TableCell key={index} className="number" id={column}>
            <div className="centerContainer">
              <TooltipTD
                label={item.number}
                className={cx("customized-tooltip")}
              >
                <NavLink
                  to={getVerificationPath({ id: item.id, scope })}
                  className="navLink"
                  id="list-view-content-salesorder"
                  onClick={onClose}
                  style={{ textDecoration: "none" }}
                >
                  <POBadge number={item.number} id={item.id} />
                </NavLink>
              </TooltipTD>
              <ThreePanelButton
                alternativePath={getVerificationPath({
                  id: item.id,
                  scope,
                  toThreePanel: true,
                })}
                clickAction={() => onClose({ addBackdrop: false })}
              />
            </div>
          </TableCell>
        );

      case headerColumns.CUSTOMER:
        return (
          customer && (
            <TableCell key={index} className="customerName">
              <div className="tooltipContainer">
                <TooltipTD label={customer.name} className="customized-tooltip">
                  <NavLink
                    to={`/app/${screenPath.CUSTOMER}/${item.customerId}`}
                    className="navLink"
                    id="list-view-content-customer"
                    onClick={onClose}
                  >
                    <span className="span-description">{customer.name}</span>
                  </NavLink>
                </TooltipTD>
              </div>
            </TableCell>
          )
        );
      case headerColumns.VENDOR:
        return (
          customer && (
            <TableCell key={index} className="customerName">
              <div className="tooltipContainer">
                <TooltipTD label={factory.name} className="customized-tooltip">
                  <NavLink
                    to={`/app/${screenPath.VENDOR}/${item.factoryId}`}
                    className="navLink"
                    id="list-view-content-customer"
                    onClick={onClose}
                  >
                    <span className="span-description">{factory.name}</span>
                  </NavLink>
                </TooltipTD>
              </div>
            </TableCell>
          )
        );
      case headerColumns.SHIPMENT:
        return (
          <TableCell key={index} className={"number"}>
            <div className="number-table-container">
              <NavLink
                to={getVerificationPath({ id: item.id, scope })}
                style={{
                  overflow: "visible",
                  marginLeft: 0,
                  textDecoration: "none",
                }}
                id="list-view-content-shipment"
                onClick={onClose}
              >
                {getShipmentBadge({
                  shipment: {
                    ...item,
                    id: item.id,
                    number: item.number,
                    deliveryDate: Number(item.deliveryDate),
                    shipDate: Number(item.shipDate),
                  },
                  classes: shipmentBadgeClass.medium,
                  showToolTip: true,
                })}
              </NavLink>
              <ThreePanelButton
                alternativePath={getVerificationPath({
                  id: item.id,
                  scope,
                  toThreePanel: true,
                })}
                clickAction={() => onClose({ addBackdrop: false })}
              />
            </div>
          </TableCell>
        );
      case headerColumns.FINAL_DESTINATION:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD
                label={item.finalDestination}
                className="customized-tooltip"
              >
                <span className="span-description">
                  {item.finalDestination}
                </span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      case headerColumns.REFERENCE_NUMBER:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD
                label={item.referenceNumber}
                className="customized-tooltip"
              >
                <span className="span-description">{item.referenceNumber}</span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      case headerColumns.CUSTOMER_PO:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD label={item.customerPO} className="customized-tooltip">
                <span className="span-description">{item.customerPO}</span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      case headerColumns.CONTAINER:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD label={item.container} className="customized-tooltip">
                <span className="span-description">{item.container}</span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      case headerColumns.BILL_OF_LADING:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD
                label={item.billOfLading}
                className="customized-tooltip"
              >
                <span className="span-description">{item.billOfLading}</span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      case headerColumns.INVOICE:
        return (
          <TableCell key={index} className={"referenceText"}>
            <div className="tooltipContainer">
              <TooltipTD label={item.invoice} className="customized-tooltip">
                <span className="span-description">{item.invoice}</span>
              </TooltipTD>
            </div>
          </TableCell>
        );
      default:
        return <TableCell key={index} />;
    }
  }

  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        id="salesOrderTableRow"
        className={cx(mainRowClasses.SearchRow, mainRowClasses.hoverEffect)}
      >
        {headers.map((header) =>
          TableBody({
            column: header.reference,
            index: item.id + header.reference,
          })
        )}
      </MainRowStyled>
    )
  );
}

export default SearchTableRow;
