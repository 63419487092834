import React, { useState } from "react";
import { cx } from "@emotion/css";
import { PrincipalDrawerStyled } from "./styles";
import { BellIcon, LogoutIcon, SmileIcon } from "../../helpers/iconSvgPath";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import ProfileDrawer from "../ProfileContainer/ProfileDrawer";
import { colors } from "../../assets/jss/variables";
import NotificationsContainer from "../NotificationsContainer/NotificationsContainer";
import { auth } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import { globalScopes } from "../../api/types/dbTables";
import AttentionModal from "../../components/Modal/AttentionModal";
import { Button, ButtonGroup, Paper } from "@mui/material";
import { PERMISSION_TEXT } from "../../helpers/constants";

const modalText = {
  [globalScopes.SALES_ORDER]: PERMISSION_TEXT.CUSTOMER,
  [globalScopes.PURCHASE_ORDER]: PERMISSION_TEXT.VENDOR,
  [globalScopes.SHIPMENT]:
    "The shipment is no longer belong to this sales order",
  PERMISSION_TO_CUSTOMER_BUT_NONE_OF_VENDOR:
    PERMISSION_TEXT.SHIPMENT_IN_VENDOR_THAT_ARE_NOT_ALLOWED,
};

const NotificationDrawer = ({ open, handleIsOpen, user }) => {
  const [isLoading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [openAttentionModal, setAttentionModal] = useState();

  function closeDrawer() {
    handleIsOpen(false);
  }

  return (
    <PrincipalDrawerStyled
      className="userDrawerRoot"
      open={open}
      anchor="right"
      onClose={closeDrawer}
      transitionDuration={800}
    >
      {!!openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={modalText[openAttentionModal]}
          isOpen={openAttentionModal}
          onClick={() =>
            setTimeout(() => {
              setAttentionModal(false);
            }, 100)
          }
          confirmationText="Ok"
          onClose={() =>
            setTimeout(() => {
              setAttentionModal(false);
            }, 100)
          }
        />
      )}

      {isLoading && <LoadingBackdrop backdropStyle={{ left: 0, width: 560 }} />}

      <div className="header">
        <button
          className="buttonLogout"
          onClick={async () => {
            await auth.signOut();
            localStorage.clear();
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }}
        >
          Logout
          <LogoutIcon svgClass={"logoutIcon"} color={colors.borderGray} />
        </button>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTimeout(closeDrawer, 550);
          }}
        >
          {getUserAvatar({
            styles: {
              width: 50,
              height: 50,
              outline: "3px solid #000",
              fontSize: 26,
            },
            user,
          })}
        </div>
      </div>
      {open && (
        <div className="contentNotificationDrawer">
          <ButtonGroup
            aria-label="outlined primary button group"
            className={cx("groupButton", {
              showContent: open,
            })}
          >
            <Button
              onClick={() => setTab(0)}
              className={cx({
                selected: tab === 0,
              })}
            >
              Notifications
              <BellIcon style={{ marginLeft: 7 }} color={colors.borderGray} />
            </Button>
            <Button
              onClick={() => setTab(1)}
              className={cx({
                selected: tab === 1,
              })}
            >
              User Settings
              <SmileIcon style={{ marginLeft: 7 }} color={colors.borderGray} />
            </Button>
          </ButtonGroup>
          <Paper elevation={0} className={"paperContent"}>
            {tab === 0 && (
              <NotificationsContainer
                onClose={() => handleIsOpen(false)}
                open={open}
                handleOpenModal={setAttentionModal}
                handleLoading={setLoading}
              />
            )}
            {tab === 1 && (
              <ProfileDrawer handleLoading={(value) => setLoading(value)} />
            )}
          </Paper>
        </div>
      )}
    </PrincipalDrawerStyled>
  );
};

export default NotificationDrawer;
