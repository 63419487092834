import React from "react";
import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

const MessageContainer = styled("div")({
  "&.containerMessage": {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    width: "100vw",
  },
  "& .text": {
    fontWeight: "bold",
    fontSize: 24,
    width: "100%",
    textAlign: "center",
    color: colors.primaryDark,
  },
});

function EmptyMessage({
  style,
  emptyFilter,
  emptyText = "The current filter selection does not have any results",
}) {
  return (
    <MessageContainer className="containerMessage" style={style}>
      {emptyFilter ? (
        <span className="text">
          At least
          <span
            className="text"
            style={{ color: colors.diamondRed, marginInline: 5 }}
          >
            one choice from In Progress, Completed or Void must be selected
          </span>
          or the results will always be blank
        </span>
      ) : (
        <span className="text">{emptyText}</span>
      )}
    </MessageContainer>
  );
}
export default EmptyMessage;
