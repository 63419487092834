import { FILTER_NOTIFICATION } from "../../helpers/constants";
import { dateFilterOptionLabel } from "../../helpers/notifications";

export const getNotificationsStorage = ({ storageNotifications = {} }) => {
  const storageActiveTab =
    storageNotifications.activeTab || FILTER_NOTIFICATION.ALL;
  const storageFilterUnread = !!storageNotifications.storageFilterUnread;
  const storageAssignedTo = storageNotifications.assignedTo || [];
  const storageCustomer = storageNotifications.customer || [];
  const storageFactory = storageNotifications.factory || [];
  const storageDate = storageNotifications.date || "";
  const storageFilterDateOption =
    storageNotifications.filterDateOption ||
    dateFilterOptionLabel.CHOOSE_A_DATE_RANGE;
  const storageDateRange = storageNotifications.dateRange || {
    start: null,
    end: null,
  };
  const storageSearchQuery = storageNotifications.searchQuery || "";

  return {
    storageActiveTab,
    storageFilterUnread,
    storageAssignedTo,
    storageCustomer,
    storageFactory,
    storageDate,
    storageFilterDateOption,
    storageDateRange,
    storageSearchQuery,
  };
};

export const filterNotifications = ({
  currentNotifications = [],
  filters = {},
}) => {
  if (currentNotifications.length === 0) {
    return [];
  }
  let currentNotificationsCpy = [...currentNotifications];
  const { customer = [], factory = [], assignedTo = [] } = filters;
  currentNotificationsCpy = currentNotificationsCpy.filter((notification) => {
    let result = true;
    if (customer.length > 0) {
      result = result && customer.includes(notification.customerId);
    }
    if (factory.length > 0) {
      result = result && factory.includes(notification.factoryId);
    }
    if (assignedTo.length > 0) {
      result =
        result &&
        (assignedTo.includes(notification.createdBy) ||
          assignedTo.includes(notification.userId));
    }

    return result;
  });
  return currentNotificationsCpy;
};
