import React, { useEffect, useState } from "react";
import { PREFIX_ITEM_TABLE, screenPath } from "../../../helpers/constants";
import { getDashboardSearchPath } from "../../../helpers/helpers";
import { useQueryParams } from "../../../hooks";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { globalScopes } from "../../../api/types/dbTables";
import { usePOItemsList } from "../../../hooks/itemTable";
import { POSKUHeader } from "../../../helpers/itemsTableColumns";
import { mismatchLabel, ORDER_DASHBOARD_ACTUAL_VIEWS } from "./helpers";
import { useCurrentSalesOrder } from "../../../hooks/salesOrders";
import POTableSKU from "../POTableSKU";
import {
  onChangeQueryParamsv2,
  QUERY_PARAMS_SCOPE,
} from "../../../helpers/orderDashboard";
import { useNavigate } from "react-router-dom";
import { getPOMismatchTag } from "../SKUTables/SKUHelper";
import Mark from "mark.js";

function POSKUSection({ purchaseOrder, companyId }) {
  const navigate = useNavigate();
  const salesOrder = useCurrentSalesOrder({});
  const queryParams = useQueryParams();
  const isAllowed = useIsAllowedFunction();
  const poItemsList = usePOItemsList({
    currentPurchaseOrder: purchaseOrder,
  });
  const [cleanSearchBox, setCleanSearchBox] = useState(false);
  const [search, setSearch] = useState("");
  const markInstance = new Mark(document.getElementById("itemTablepurchaseOrder"));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: false,
      accuracy: "partially",
      exclude: [
        ".headerTable, .headerTable *",
        ".alignRight, .alignRight *",
        ".totalNumber, .totalNumber *"
      ],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    setTimeout(() => performMark(search), 200);
  }, [search]);

  const hasPOMismatchTag = getPOMismatchTag({
    currentPurchaseOrder: purchaseOrder,
    currentSalesOrder: salesOrder,
    poItemsList,
  });

  return (
    <POTableSKU
      hasMismatchTag={
        hasPOMismatchTag ? mismatchLabel[globalScopes.PURCHASE_ORDER] : false
      }
      prefix={PREFIX_ITEM_TABLE.PURCHASE_ORDER}
      columnsTable={POSKUHeader.filter((column) => {
        if (column.amountPermission && !isAllowed(column.amountPermission)) {
          return false;
        }
        return true;
      })}
      onWriteSearch={setSearch}
      handleSearchBox={setCleanSearchBox}
      cleanSearchBox={cleanSearchBox}
      filterText={search}
      dataTable={poItemsList}
      currentPurchaseOrder={purchaseOrder}
      currentSalesOrder={salesOrder}
      style={{ maxWidth: 1660 }}
      onClickRedirect={(data) => {
        const { item, row } = data;
        if (data.so) {
          if (row.id !== salesOrder.id) {
            onChangeQueryParamsv2({
              scope: QUERY_PARAMS_SCOPE.SALES_ORDER,
              salesOrderId: row.id,
              companyId: companyId,
              navigate,
              queryParams: {
                ...queryParams,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
              },
            });
          } else {
            navigate(
              getDashboardSearchPath({
                ...queryParams,
                section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
              }),
              { replace: true }
            );
          }
          return;
        }
        if (data.customer) {
          return navigate(`/app/${screenPath.CUSTOMER}/${row.customerId}`);
        }
        if (data.badgeShipment || !data.erased) {
          onChangeQueryParamsv2({
            scope: QUERY_PARAMS_SCOPE.SHIPMENT,
            shipmentId: item.id,
            navigate,
            queryParams: {
              ...queryParams,
              section: !data.editing
                ? ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT
                : queryParams.section,
            },
          });
          return;
        }
        if (data.erased && item.id === queryParams.shipmentId) {
          const shipmentIds = purchaseOrder.shipmentIds || [];
          const newShipmentId = shipmentIds.find((id) => id !== item.id);
          onChangeQueryParamsv2({
            scope: QUERY_PARAMS_SCOPE.SHIPMENT,
            shipmentId: newShipmentId,
            navigate,
            queryParams,
          });
        }
      }}
    />
  );
}

export default POSKUSection;
