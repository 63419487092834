/**
 * Helpers Functions
 */
import React from "react";
import { useCallback } from "react";
import moment from "moment";
import {
  cleanPOsRedux,
  cleanShipmentRedux,
  clearSKURedux,
  getDocumentMiscellaneous,
  getFirestoreDocument,
  getListenerPOs,
  getListenerShipments,
  getListenerSKU,
  getListenerSO,
  getListenRolesPermissions,
  getListenUserMentions,
  getNestedCollectionFromFirestoreTable,
  getNestedCollectionFromFirestoreTableRefactored,
  getNotificationsFromFirestoreTable,
} from "../actions/DataActions";
import { dbTables, } from "../api/types/dbTables";
import {
  typeTaskTemplateError,
  regexEmailValidator,
  NOTIFICATION_SCOPE,
  TYPE_OF_FILE,
  taskStatus,
  AUTOMATIC_SYSTEM_LABEL,
  GENERAL_PERMISSION_VALUE,
  TYPE_NOTIFICATION,
} from "./constants";
import { useUser, useCompanyUsers } from "../hooks/user";
import Notification from "../api/model/Notification";
import userTypes from "../api/types/userTypes";
import { useLocation } from "react-router-dom";
import { useCustomers } from "../hooks/customers";
import { useFactories } from "../hooks/factories";

import { getCorrectTimezone } from "./ganttChart";
import { firestore } from "../firebase";
import {
  getDoc,
  setDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
  limit,
} from "firebase/firestore";
import { verifyPermission } from "../hooks/permissions";
import { CLIENT_IS_OFFLINE } from "../actions/types";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../constants/globalVariables";
// import { trackEvent } from "./analytics";
/**import { useUser } from '../hooks/user';
 *
 */

export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

/**
 * Random Id Generator
 */
export function getRandomId() {
  const collectionRef = collection(firestore, "generic");
  const docRef = doc(collectionRef);
  return docRef && docRef.id;
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp;
  if (!timestamp) {
    return "";
  }
  let formatDate = format ? format : "M/D/YY";
  return moment(time).format(formatDate);
}
/**
 * Sort List of Object
 */
export function sortObjectsBy(property, reverse, isDate = false) {
  let order = 1;
  if (reverse) {
    order = -1;
  }
  return function (a, b) {
    let result = "";
    let aProperty = a[property];
    let bProperty = b[property];
    if (isDate) {
      aProperty = moment(aProperty).valueOf();
      bProperty = moment(bProperty).valueOf();
    }
    if (typeof aProperty === "string" && typeof bProperty === "string") {
      const stringA = aProperty.toLowerCase().trim();
      const stringB = bProperty.toLowerCase().trim();
      result = stringA > stringB ? 1 : stringA < stringB ? -1 : 0;
    } else {
      if (!aProperty && !bProperty) {
        result = 0;
        return result;
      }
      if (!aProperty && bProperty) {
        result = -1;
        return result * order;
      }
      if (aProperty && !bProperty) {
        result = 1;
        return result * order;
      }

      result = aProperty > bProperty ? 1 : aProperty < bProperty ? -1 : 0;
    }
    return result * order;
  };
}

export const getDashboardSearchPath = ({
  salesOrderId = "",
  purchaseOrderId = "",
  shipmentId = "",
  noteId,
  taskId,
  fileId,
  section = "",
}) => {
  let search;
  let aditionalParam = "";
  if (noteId && noteId !== "undefined") {
    aditionalParam += `&&noteId=${noteId}`;
  }
  if (taskId && taskId !== "undefined") {
    aditionalParam += `&&taskId=${taskId}`;
  }
  if (fileId && fileId !== "undefined") {
    aditionalParam += `&&fileId=${fileId}`;
  }
  if (section && section !== "undefined") {
    aditionalParam += `&&section=${section}`;
  }
  if (!shipmentId) {
    search = `salesOrderId=${salesOrderId}&&purchaseOrderId=${purchaseOrderId}${aditionalParam}`;
    return {
      pathname: "/app/dashboard",
      search: `?${search}`,
    };
  } else if (!purchaseOrderId) {
    search = `salesOrderId=${salesOrderId}`;
    return {
      pathname: "/app/dashboard",
      search: `?${search}`,
    };
  }
  search = `salesOrderId=${salesOrderId}&&purchaseOrderId=${purchaseOrderId}&&shipmentId=${shipmentId}${aditionalParam}`;
  return {
    pathname: "/app/dashboard",
    search: `?${search}`,
  };
};

export const getPurchaseOrdersPermission = ({
  purchaseOrders,
  user,
  queryParams,
}) => {
  const filterPurchaseOrders = purchaseOrders.filter((po) =>
    verifyPermission({
      user,
      permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
    })
  );
  const firstPO = filterPurchaseOrders[0];
  const purchaseOrderId = queryParams.purchaseOrderId;
  const currentPurchaseOrder = filterPurchaseOrders.find(
    (po) => po.id === purchaseOrderId
  );
  if (currentPurchaseOrder || filterPurchaseOrders.length === 0 || !firstPO) {
    return purchaseOrderId;
  } else {
    return firstPO.id;
  }
};

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : "YYYY/MM/DD";
  return moment(date, formatDate).unix() * 1000;
}

export function inputTypeByField(field) {
  switch (field) {
    case "shippingTerms":
      return "select";
    case "shipDate":
    case "deliveryDate":
    case "date":
    case "orderReadyDate":
    case "payTermsStart":
      return "date";
    case "CBM":
      return "number";
    case "totalUniqueItems":
      return "number";
    default:
      return "text";
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export function convertToTreeRefactored({
  documents = [],
  parentDocumentId = "",
}) {
  const rootDocuments = documents.filter((document) => {
    return parentDocumentId
      ? document.parentId === parentDocumentId
      : !document.parentId;
  });

  function getChildrenDocuments({ documents = [], document }) {
    const childrenDocuments = documents.filter((childDocument) => {
      return childDocument.parentId === document.id;
    });

    if (childrenDocuments.length > 0) {
      childrenDocuments.forEach((childDocument) => {
        if (childDocument.type === TYPE_OF_FILE.FOLDER) {
          childDocument.child = getChildrenDocuments({
            documents,
            document: childDocument,
          });
        } else {
          const versions = childDocument.versions || [];
          childDocument.child = versions.map((docVersion) => ({
            ...childDocument,
            ...docVersion,
            id: childDocument.id + docVersion.version,
            child: [],
            isVersion: true,
            name: replaceFileExtension(childDocument.name, docVersion.type),
          }));
        }
      });
    }
    return childrenDocuments;
  }

  const documentTree = rootDocuments.map((rootDocument) => {
    if (rootDocument.type === TYPE_OF_FILE.FOLDER) {
      return {
        ...rootDocument,
        child: getChildrenDocuments({ documents, document: rootDocument }),
      };
    } else {
      return {
        ...rootDocument,
        child: rootDocument.versions?.map((docVersion) => ({
          ...rootDocument,
          ...docVersion,
          id: rootDocument.id + docVersion.version,
          versionId: rootDocument.id,
          child: [],
          versions: [],
          isVersion: true,
          name: replaceFileExtension(rootDocument.name, docVersion.type),
        })),
      };
    }
  });

  return documentTree;
}

export function replaceFileExtension(name, extension) {
  const elements = name.split(".");
  if (elements.length === 1) {
    return `${name}.${extension}`;
  }
  elements.pop();
  return `${elements.join(".")}.${extension}`;
}


export function getTypeFile(name) {
  const elements = name.split(".");
  if (elements.length === 1) {
    return TYPE_OF_FILE.FOLDER;
  }
  const extension = `${elements[elements.length - 1] || ""}`;
  return extension.toLowerCase();
}

export function pathToString(arrayPath = []) {
  return arrayPath.join("/");
}

export function listenToData({ query, path }) {
  return (dispatch) =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([...path]),
      queryParam: query,
    })(dispatch);
}

export function listenLimitData({ query, path, limit }) {
  return (dispatch) =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([...path]),
      queryParams: query,
      limitValue: limit,
      reduxData: "inbox_sales_order",
    })(dispatch);
}

//REFACTORING LISTE TO DATA
export function listenToDataRefactored({
  query,
  path,
  limit,
  orderBy,
  stateReference,
}) {
  return (dispatch) =>
    getNestedCollectionFromFirestoreTableRefactored({
      path: pathToString([...path]),
      queryParams: query,
      limitParams: limit,
      orderByParams: orderBy,
      stateReference,
    })(dispatch);
}
//

//REFACTORING LISTE TO DATA
export function listenToDataMiscellaneous({
  path,
  stateReference,
  referenceId,
}) {
  return (dispatch) =>
    getDocumentMiscellaneous({
      path: pathToString([...path]),
      stateReference,
      referenceId,
    })(dispatch);
}
//

export function listenSKUData({ poId, skuVersion, companyId }) {
  return (dispatch) =>
    getListenerSKU({ poId, skuVersion, companyId })(dispatch);
}

export function listenToDataSO({ salesOrderId, companyId }) {
  return (dispatch) => getListenerSO({ salesOrderId, companyId })(dispatch);
}
export function listenToDataMentions({ userId }) {
  return (dispatch) => getListenUserMentions({ userId })(dispatch);
}
export function listenRolePermissions({ roleId, companyId }) {
  return (dispatch) =>
    getListenRolesPermissions({ roleId, companyId })(dispatch);
}

export function listenToDataPOs({ salesOrderId, companyId }) {
  return (dispatch) => getListenerPOs({ salesOrderId, companyId })(dispatch);
}
export function listenToDataShipments({ purchaseOrderId, companyId }) {
  return (dispatch) =>
    getListenerShipments({ purchaseOrderId, companyId })(dispatch);
}

export function clearPurchaseOrders({ companyId }) {
  return (dispatch) => cleanPOsRedux({ companyId })(dispatch);
}

export function clearSKUItems({ companyId }) {
  return (dispatch) => clearSKURedux({ companyId })(dispatch);
}

export function clearShipmentOrders({ companyId }) {
  return (dispatch) => cleanShipmentRedux({ companyId })(dispatch);
}

export function listenToDocument({ table, path, keyName, limit, orderBy }) {
  return (dispatch) =>
    getFirestoreDocument({
      table,
      path: pathToString([...path]),
      keyName,
      limit,
      orderBy,
    })(dispatch);
}

export function listenToNotifications(...path) {
  return (dispatch) =>
    getNotificationsFromFirestoreTable(pathToString([...path]))(dispatch);
}

export function getFolderSize(total, document = []) {
  const sum = 0;
  document.forEach((element) => {
    const size = parseInt(element.size || 0);
    if (element.child && element.child.length > 0) {
      total += getFolderSize(sum, element.child);
    } else {
      total +=
        parseInt(size) === 0 && element.type !== TYPE_OF_FILE.FOLDER ? 1 : size;
    }
  });
  return total;
}

export function getSizeTransformedAccordingKBSize(size, type) {
  if (+size >= 0 && +size <= 1023) {
    if (type !== TYPE_OF_FILE.FOLDER) {
      return Math.floor(size) === 0 ? 1 + " KB" : Math.floor(size) + " KB";
    }
    return Math.floor(size) + " KB";
  } else if (+size >= 1024 && +size <= 1048576) {
    return Math.floor(+size / 1024) + " MB";
  } else if (+size >= 1048577) {
    return Math.floor(+size / 1048577) + " GB";
  }
}

export const replacePathReference = ({ oldPath, newId }) => {
  const originalNote = oldPath.split("/");
  originalNote[originalNote.length - 1] = newId;
  return originalNote.join("/");
};

export function getDayOffset(notification) {
  return Math.abs(notification.dayOffset);
}

export function getUserDisplayName(users, userId) {
  const user = users.find((user) => user.id === userId);
  if (user) {
    return user.displayName;
  }
  return "company_user";
}

const taskNotificationType = {
  ADHOC_TASK: "ADHOC_TASK",
  COMPLETED: "COMPLETED",
};

const notificationLateScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_LATE,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_LATE,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_LATE,
};
const notificationEarlyScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_EARLY,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_EARLY,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_EARLY,
};

const notificationCreateScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_CREATED,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_CREATED,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_CREATED,
};

export function isAbleToNotifyUser({ user = {}, task = {}, late = false }) {
  function ObjectInObject({ object1 = {}, object2 = {} }) {
    let found = false;
    Object.keys(object1).forEach((key) => {
      if (object2[key]) {
        found = true;
      }
    });
    return found;
  }

  if (
    !late &&
    ((task.userNotificationEarly && task.userNotificationEarly[user.id]) ||
      ObjectInObject({
        object1: task.groupNotificationEarly,
        object2: user.permissionGroups,
      }))
  ) {
    return true;
  } else if (
    late &&
    ((task.userNotificationLate && task.userNotificationLate[user.id]) ||
      ObjectInObject({
        object1: task.groupNotificationLate,
        object2: user.permissionGroups,
      }))
  ) {
    return true;
  }
  return false;
}

export function useTaskNotificationCreator() {
  const companyUsers = useCompanyUsers({});
  const customers = useCustomers();
  const factories = useFactories();
  const user = useUser();

  const cb = useCallback(
    ({ task, randomId, type = taskNotificationType.ADHOC_TASK }) => {
      const fieldsToUpdate = {
        complete: !task.complete,
        completedBy: user.id,
      };
      const finishDate = task.finishDate;
      if (!task.customerName) {
        fieldsToUpdate.customerName = getCustomerName(
          customers,
          task.customerId
        );
      }
      if (!task.factoryName && task.factoryId) {
        fieldsToUpdate.factoryName = getFactoryName(factories, task.factoryId);
      }
      if (!task.complete) {
        if (type === taskNotificationType.ADHOC_TASK) {
          const notification = new Notification({
            type: "adHocTask",
            scope: notificationCreateScopes[task.type],
            id: randomId,
            read: false,
            newNotification: true,
            creationDate: moment().valueOf(),
            salesOrderId: task.salesOrderId,
            shipmentId: task.shipmentId,
            customerId: task.customerId,
            customerName: fieldsToUpdate.customerName,
            SONumber: task.SONumber,
            PONumber: task.PONumber,
            shipmentNumber: task.shipmentNumber,
            purchaseOrderId: task.purchaseOrderId,
            factoryId: task.factoryId,
            factoryName: fieldsToUpdate.factoryName,
            complete: fieldsToUpdate.complete,
            completedBy: fieldsToUpdate.completedBy,
            companyId: task.companyId,
            createdBy: task.createdBy,
            finishDate: finishDate.valueOf(),
            description: task.description,
            mainDocumentId: task.id,
          });
          return setDoc(
            doc(
              firestore,
              `${dbTables.USERS}/${task.assignedTo}/${dbTables.NOTIFICATIONS}/${notification.id}`
            ),
            { ...notification }
          );
        }
        const dayOffset = moment()
          .startOf("day")
          .diff(moment(finishDate.valueOf()).startOf("day"), "days");
        // trackEvent("PO Dashboard - Tasks - Done", {
        //   days: dayOffset,
        //   task_status: task.status
        // });
        if (
          finishDate.valueOf() - moment().valueOf() < 0 ||
          task.status === taskStatus.LATE
        ) {
          companyUsers.forEach(async (companyUser) => {
            fieldsToUpdate.dayOffset = dayOffset;
            const isAbleToNofity = isAbleToNotifyUser({
              user: companyUser,
              task,
              late: true,
            });
            if (isAbleToNofity) {
              const notification = new Notification({
                id: randomId,
                scope: notificationLateScopes[task.type],
                type: "completeLate",
                read: false,
                newNotification: true,
                creationDate: moment().valueOf(),
                dayOffset: fieldsToUpdate.dayOffset,
                companyId: task.companyId,
                salesOrderId: task.salesOrderId,
                purchaseOrderId: task.purchaseOrderId,
                shipmentId: task.shipmentId,
                SONumber: task.SONumber,
                PONumber: task.PONumber,
                shipmentNumber: task.shipmentNumber,
                customerName: task.customerName || fieldsToUpdate.customerName,
                factoryName: task.factoryName || fieldsToUpdate.factoryName,
                customerId: task.customerId,
                factoryId: task.factoryId,
                completedBy: task.completedBy,
                assignedTo: task.assignedTo,
                confirmedTask: task.confirmedTask,
                description: task.description,
                finishDate: finishDate.valueOf(),
                mainDocumentId: task.id,
                createdBy: task.createdBy,
                shipment: { finalDestination: task.finalDestination },
              });
              sendCompletedTaskEmail({
                notification,
                currentUser: companyUser,
                users: companyUsers,
                type: TYPE_NOTIFICATION.COMPLETE_LATE,
              });
              setDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${companyUser.id}/${dbTables.NOTIFICATIONS}/${randomId}`
                ),
                {
                  ...notification,
                }
              );
            }
          });
        }
        if (moment().isBefore(moment(finishDate.valueOf()), "day")) {
          companyUsers.forEach(async (companyUser) => {
            const isAbleToNofity = isAbleToNotifyUser({
              user: companyUser,
              task,
            });
            if (isAbleToNofity) {
              fieldsToUpdate.dayOffset = dayOffset;
              const notification = new Notification({
                id: randomId,
                scope: notificationEarlyScopes[task.type],
                type: "completeEarly",
                read: false,
                newNotification: true,
                creationDate: moment().valueOf(),
                dayOffset: fieldsToUpdate.dayOffset,
                companyId: task.companyId,
                salesOrderId: task.salesOrderId,
                purchaseOrderId: task.purchaseOrderId,
                shipmentId: task.shipmentId,
                SONumber: task.SONumber,
                PONumber: task.PONumber,
                shipmentNumber: task.shipmentNumber,
                customerName: task.customerName
                  ? task.customerName
                  : fieldsToUpdate.customerName,
                factoryName: task.factoryName
                  ? task.factoryName
                  : fieldsToUpdate.factoryName,
                customerId: task.customerId,
                factoryId: task.factoryId,
                completedBy: task.completedBy,
                assignedTo: task.assignedTo,
                confirmedTask: task.confirmedTask,
                description: task.description,
                finishDate: finishDate.valueOf(),
                mainDocumentId: task.id,
                createdBy: task.createdBy,
                shipment: { finalDestination: task.finalDestination },
              });
              sendCompletedTaskEmail({
                notification,
                currentUser: companyUser,
                users: companyUsers,
                type: TYPE_NOTIFICATION.COMPLETE_EARLY,
              });
              setDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${companyUser.id}/${dbTables.NOTIFICATIONS}/${randomId}`
                ),
                { ...notification }
              );
            }
          });
        }
      }
    }
  );
  return cb;
}

export function debounce(func, wait, immediate) {
  let timeout;

  let debouncedFunction = function () {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };

  debouncedFunction.cancel = function () {
    clearTimeout(timeout);
  };

  return debouncedFunction;
}

export function getFileName(name = "") {
  const splitName = name.split(".");
  if (splitName.length === 1) {
    return name;
  }
  splitName.pop();
  return splitName.join(".");
}

export async function getSpreadSheetByName(SHEET_ID, ACCESS_TOKEN, SHEET_NAME) {
  const request = await window.fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}!A1:Z300`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    }
  );
  const sheet = await request.json();
  console.log("SHEET", sheet);
  return sheet;
}

export function getCustomerName(
  customers = [],
  customerId = "",
  shortResponse
) {
  const customer = customers.find((customer) => customer.id === customerId);
  if (customer) {
    return customer.name;
  }
  return shortResponse ? "N/A" : "No customer found";
}

export function getShipmentDestination(
  shipments = [],
  shipmentId = "",
  shortResponse
) {
  const currentShipment = shipments.find(
    (shipment) => shipment.id === shipmentId
  );
  if (currentShipment) {
    return currentShipment.finalDestination;
  }
  return shortResponse ? "" : "No Final Destination found";
}

export function getFactoryName(factories, factoryId = "", withNumber = false) {
  const factory = factories.find((factory) => factory.id === factoryId);
  if (factory) {
    let name = factory.name;
    if (withNumber) {
      name = `${factory.number || ""}    ${factory.name}`;
    }
    return name;
  }
  return "No factory found";
}

export function getFactoryByField({ factories, factoryId, field }) {
  const factory = factories.find((factory) => factory.id === factoryId);
  if (factory) {
    return factory[field];
  }
  return `Vendor ${field} not found`;
}

export function getUserByField({ users = [], userId = "", field = "" }) {
  const user = users.find((user) => user.id === userId);
  if (user) {
    return user[field];
  }
  return `User ${field} not found`;
}

export function getTagsLabel(tags = [], itemTags = []) {
  let tagsString = [];
  let tagsArray = [];
  const typeOfLabelArr = [];
  Object.values(AUTOMATIC_SYSTEM_LABEL).forEach((value) => {
    typeOfLabelArr.push(value);
  });
  itemTags.forEach((tag) => {
    const tagDB = tags.find((el) => el.id === tag);
    if (tagDB) {
      tagsString.push(tagDB.name);
      tagsArray.push(tagDB);
    }
  });

  const stringValue = tagsString.join(",");
  const list = (
    <ul
      style={{
        color: "white",
        marginLeft: 15,
        marginBottom: 0,
        listStyle: "disc",
      }}
    >
      {tagsString.sort().map((name) => {
        const fontStyle = typeOfLabelArr.includes(name) ? "italic" : "normal";
        return (
          <li
            key={stringValue + name + "getTagsLabel"}
            style={{
              fontStyle: fontStyle,
            }}
          >
            {name}
          </li>
        );
      })}
    </ul>
  );
  return { tagsString: list, tagsArray };
}

export function getCompanyUserDisplayName(companyUsers, userId) {
  if (!userId) {
    return "";
  }
  const user = companyUsers.find((user) => user.id === userId);
  if (user) {
    return "@" + user.displayName;
  } else {
    return "@companyUser";
  }
}

function verifyCircularMultiDependency(task, taskTemplateCpy = [], counter) {
  if (!task) {
    return false;
  }
  const dependencyTask = taskTemplateCpy.find(
    (el) => el.id === task.dependency
  );
  if (dependencyTask) {
    if (counter > taskTemplateCpy.length) {
      return true;
    }
    const newDependencyTask = taskTemplateCpy.find(
      (el) => el.id === dependencyTask.dependency
    );
    let counterCpy = counter + 1;
    return verifyCircularMultiDependency(
      newDependencyTask,
      taskTemplateCpy,
      counterCpy
    );
  }
  return false;
}

export function verifyTaskTemplateErrors({
  SOTaskTemplate = [],
  POTaskTemplate = [],
  isPOVerifier = false,
}) {
  let taskTemplateCpy = isPOVerifier
    ? [...POTaskTemplate]
    : [...SOTaskTemplate];

  let circularDependency = false;
  const circularDependencyTasks = [];
  let dependsOnItself = false;
  const dependsOnItselfTasks = [];
  let noDependencyTaskFound = false;
  const noDependencyTaskFoundArr = [];

  const leafTasks = [];
  taskTemplateCpy.forEach((task) => {
    if (!taskTemplateCpy.some((item) => item.dependency === task.id)) {
      leafTasks.push(task);
    }
  });
  if (leafTasks.length === 0) {
    return {
      status: 400,
      errorMessage: typeTaskTemplateError.THERE_IS_NO_LEAF_TASK,
      type: typeTaskTemplateError.THERE_IS_NO_LEAF_TASK,
    };
  }

  taskTemplateCpy.forEach((task) => {
    const counter = 0;
    const isMultiCircularDependency = verifyCircularMultiDependency(
      task,
      taskTemplateCpy,
      counter
    );
    if (task.dependency && task.dependency === task.id) {
      dependsOnItself = true;
      dependsOnItselfTasks.push({ id: task.id, description: task.description });
    } else if (task.dependency) {
      let dependencyTask = null;
      if (task.dependsOnSOT) {
        dependencyTask = SOTaskTemplate.find(
          (ttask) => ttask.id === task.dependency
        );
      } else {
        dependencyTask = taskTemplateCpy.find(
          (ttask) => ttask.id === task.dependency
        );
      }
      if (!dependencyTask) {
        noDependencyTaskFound = true;
        noDependencyTaskFoundArr.push({
          id: task.id,
          description: task.description,
        });
      } else if (
        (task.dependency === dependencyTask.id &&
          task.id === dependencyTask.dependency) ||
        isMultiCircularDependency
      ) {
        circularDependency = true;
        circularDependencyTasks.push({
          id: task.id,
          description: task.description,
        });
      }
    }
  });

  if (circularDependency) {
    return {
      status: 400,
      type: typeTaskTemplateError.CIRCULAR_DEPENDENCY,
      errorTasks: circularDependencyTasks,
    };
  } else if (dependsOnItself) {
    return {
      status: 400,
      type: typeTaskTemplateError.TASK_DEPENDS_ON_ITSELF,
      errorTasks: dependsOnItselfTasks,
    };
  } else if (noDependencyTaskFound) {
    return {
      status: 400,
      type: typeTaskTemplateError.THERE_IS_NO_DEPENDENCY_TASK,
      errorTasks: noDependencyTaskFoundArr,
    };
  } else
    return {
      status: 200,
      type: typeTaskTemplateError.SUCCESS,
      errorTasks: [],
    };
}

export function verifyVendorIcon({ factoryFilter = [], item, currentVendor }) {
  if (!item || !currentVendor || factoryFilter.length === 0) {
    return false;
  }
  return factoryFilter.includes(item.factoryId);
}

export function isTradeDashEmployee(user = {}) {
  return user.role === userTypes.TRADEDASH_EMPLOYEE;
}

export function isSuperAdmin(user) {
  return user.role === userTypes.SUPER_ADMIN;
}

export function getWeekTabScreenFormatted({ date }) {
  return moment(date).format("MM/DD");
}

export function useLocationPath() {
  const location = useLocation();
  return location.pathname;
}

export function getPathDocumentLocalStorage(path) {
  if (path.includes("/factories")) {
    return "Vendor";
  }
  if (path.includes("/customers")) {
    return "Customers";
  }
  return "OD";
}

export function getIdFromStructureFolders(documents) {
  let ids = [];
  documents.forEach((doc) => {
    if (doc.type === TYPE_OF_FILE.FOLDER) {
      ids.push(doc.id);
    }
  });
  return ids;
}

export const formatWithComma = (number = 0) => {
  const parseNumber = parseInt(number);
  return parseNumber.toLocaleString("en-US");
};

export const formatCash = (value) => {
  if (!value) return "$0";
  const newFormat = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  }).format(value);
  return newFormat;
};

export function getProgressValue(value = {}) {
  let { completedTasks = 0, totalTasks = 0 } = value;
  if (!completedTasks || !totalTasks) {
    return 0;
  }
  const progress = (completedTasks * 100) / totalTasks;
  return progress.toFixed(0);
}

export function getDateByTimezone({
  date,
  format = "M/D/YY",
  timestamp = false,
  serverTime = false,
}) {
  const finishDate = getCorrectTimezone({
    date,
    isShowingDate: true,
    isServerTime: serverTime,
  });
  if (timestamp) {
    return finishDate.valueOf();
  } else {
    return finishDate.format(format);
  }
}

export { taskNotificationType };

export function hasFeatureFlagPermission({
  featureFlags = [],
  user = {},
  featureFlagName = "",
}) {
  if (featureFlags.length === 0) {
    return false;
  }
  const splitShipmentFeatureV1 =
    featureFlags.find((feature) => feature.name === featureFlagName) || {};
  if (splitShipmentFeatureV1.users && splitShipmentFeatureV1.users[user.id]) {
    return true;
  }
  return false;
}

export const validateEmail = (email) => {
  return regexEmailValidator.test(String(email).toLowerCase());
};

export function activitiesFilteredByPermission({
  activityList = [],
  permissionEnabled = true,
  orderFieldsToVerify = [],
}) {
  const filterList = activityList.map((activity) => {
    if (permissionEnabled) {
      return activity;
    } else if (
      activity.hiddenValueDetail &&
      orderFieldsToVerify.some((field) =>
        activity.involvedFields.includes(field)
      )
    ) {
      return { ...activity, detail: activity.hiddenValueDetail };
    } else {
      return activity;
    }
  });
  return filterList;
}

export const getActivitiesNotes = async ({
  companyId,
  orderId,
  userId,
  orderTable,
  dispatch = () => {},
}) => {
  try {
    const activityMentionSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${orderTable}/${orderId}/${dbTables.ACTIVITIES}`
        ),
        where("userMentions." + userId, "==", false),
        limit(1)
      )
    );
    const activityMention = activityMentionSnap.docs.map((doc) => doc.data());
    if (activityMention <= 0) {
      return false;
    }
    return activityMention.sort(sortObjectsBy("creationDate", false))[0];
  } catch (error) {
    console.log("ERROR getActivitiesNotes", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and try again",
      },
    });
    return [];
  }
};

export const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  } else if (isChromium && isOpera === false && isIEedge === false) {
    return true;
  } else {
    return false;
  }
};

export async function getPurchaseOrders({
  purchaseOrderIds = [],
  companyId,
  user,
}) {
  const POPromisses = [];
  purchaseOrderIds.forEach((poId) => {
    POPromisses.push(
      getDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${poId}`
        )
      )
    );
  });
  const purchaseOrdersSnapDB = await Promise.all(POPromisses);
  let purchaseOrdersDB = purchaseOrdersSnapDB.map((doc) => doc.data());
  purchaseOrdersDB = purchaseOrdersDB.filter((po) => {
    if (!po) return false;
    const hasPermission = verifyPermission({
      user: user,
      permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
    });
    return hasPermission;
  });
  return purchaseOrdersDB;
}

const getEmailDataTaksCompleted = ({ notification, users, currentUser }) => {
  const {
    completedBy,
    assignedTo,
    description,
    SONumber,
    customerName,
    isAbleToModifyTaskDueDate,
    confirmedTask,
    PONumber,
    factoryName,
    shipmentNumber,
    shipment = {},
    salesOrderId,
    purchaseOrderId,
    scope,
    shipmentId,
  } = notification;
  console.log(notification, "-------");
  return {
    completedBy: getUserDisplayName(users, completedBy),
    assignedTo: getUserDisplayName(users, assignedTo),
    completedBySameUser: assignedTo === completedBy,
    description,
    SONumber,
    customerName,
    dayOffset: getDayOffset(notification),
    isAbleToModifyTaskDueDate,
    confirmedTask,
    PONumber,
    factoryName,
    shipmentNumber,
    finalDestination: shipment.finalDestination,
    salesOrderId,
    purchaseOrderId,
    shipmentId: shipmentId,
    scope,
    taskId: notification.mainDocumentId,
    email: currentUser.email,
  };
};
const sendCompletedTaskEmail = ({ notification, currentUser, users, type }) => {
  const isAbleToSendEmail =
    type === TYPE_NOTIFICATION.COMPLETE_LATE
      ? currentUser.lateTaskEmail
      : currentUser.earlyTaskEmail;
  if (isAbleToSendEmail) {
    const data = getEmailDataTaksCompleted({
      notification,
      users,
      currentUser,
    });
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "usernotifications",
        env: globalEnvironment,
        params: `/sendCompletedTaskEmail`,
      })
    );
    try {
      callableEndpoint({
        ...data,
        email: currentUser.email,
      });
    } catch (error) {
      console.log({ error });
    }
  }
};

export const stringLowerCase = (data = "") => data.toLowerCase();

export const getMaxDate = (typeDate) => {
  const limitDatebyType = ["Created Date"];
  return limitDatebyType.includes(typeDate) ? new Date() : "";
};

export const getScreenListGrid = ({ grid, filtersActive = [] }) => {
  const currentFilters = filtersActive.filter((filter) => filter);
  const repetFilterGrid =
    currentFilters.filter((filter) => filter).length === 0
      ? " "
      : ` repeat(${
          filtersActive.filter((filter) => filter).length
        }, minmax(0, 270px)) `;
  return `${grid}${repetFilterGrid}minmax(0, 175px) 1fr`;
};
