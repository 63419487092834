import { styled } from "@mui/material";

export const CreateReportFormContainerStyled = styled("form")({
  "&.formContainer": {},
  "& .itemFormContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    margin: "8px 0px",
    "& label": {
      margin: "0px 8px 0px 0px",
      width: 135,
    },
    "& select": {
      width: 350,
      border: "1px solid #000",
      borderRadius: 4,
      height: 40,
    },
    "& textarea": {
      width: 350,
      border: "1px solid #000",
      borderRadius: 4,
    },
  },
  "& .permissionsGroupContainer": {
    display: "flex",
    alignItems: "flex-start",
  },
  "& .permissionsGroupContent": {
    margin: "8px",
    width: "25%",
  },
  "& .viewPermissionOnUpload": {
    width: "505px",
  },
  "& .viewPermissionsLabelContainer": {
    display: "flex",
    fontSize: 14,
    marginLeft: 107,
    marginTop: 18,
  },
  "& .modalContainer": {
    background: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: 32,
    borderRadius: 5,
    minWidth: 680,
    "& .modal-container-title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 0px 24px 0px",
      fontSize: 18,
      fontWeight: 500,
    },
  },
  "& .permissionContent": {
    display: "flex",
    padding: "6px 4px",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "auto",
    lineHeight: 1.5,
    "& .labelText": {
      paddingLeft: 8,
      height: "auto",
      fontSize: 12,
      cursor: "pointer",
    },
    "& .permissionCheck": {
      cursor: "pointer",
    },
  },
});
