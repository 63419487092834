import React from "react";
import { getTheDate, sortObjectsBy } from "../../helpers/helpers";
import { NavLink } from "react-router-dom";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  screenPath,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import userTypes from "../../api/types/userTypes";
import { checkSpecificPermissions } from "../../hooks/permissions";
import { TableCell } from "@mui/material";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import SelectButton from "../../components/Selects/SelectButton";
import { getSelectedTemplate } from "../../components/SalesOrders/soTemplateHelper";
import { getVerificationPath } from "../../components/Sidebar/sidebarHelper";
import { dbTables } from "../../api/types/dbTables";

function InboxTableRow({
  item,
  user,
  isAllowed = () => {},
  customers,
  headers = [],
  isThereColumnSetting = false,
  soTemplates = [],
  onOpenAttentionModal = () => {},
}) {
  const customer = customers.find((cus) => cus.id === item.customerId);

  function SOTableRowSelector({ column, enabled, index }) {
    switch (column) {
      case headerColumns.SO:
        return (
          enabled && (
            <TableCell key={index} className="number" id="soContainerList">
              <div className={"number-table-container"}>
                <NavLink
                  to={`/app/${getVerificationPath({
                    id: item.id,
                    scope: dbTables.SALES_ORDERS,
                  })}`}
                  className="navLink"
                  id="list-view-content-salesorder"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <SalesOrderBadge number={item.number} />
                </NavLink>
              </div>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled &&
          customer && (
            <TableCell key={index} className="customerName">
              <TooltipTD label={customer.name} className="customized-tooltip">
                <NavLink
                  to={
                    !(
                      user.role === userTypes.SUPER_ADMIN ||
                      user.role === userTypes.TRADEDASH_EMPLOYEE ||
                      checkSpecificPermissions({
                        userPermissions: user.permissions,
                        permissionToCheck: [
                          item.customerId,
                          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                        ],
                      })
                    )
                      ? "#"
                      : `/app/${screenPath.CUSTOMER}/${item.customerId}`
                  }
                  className="navLink"
                  id="list-view-content-customer"
                >
                  <span className="span-description">{customer.name}</span>
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.CREATION_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="createDate">
              {getTheDate(item.creationDate, "M/D/YY")}
            </TableCell>
          )
        );
      case headerColumns.DOCUMENT_DATE:
        return (
          enabled && (
            <TableCell key={index} className="date" id="createDate">
              {getTheDate(item.date, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.TOTAL:
        return (
          enabled &&
          isAllowed(generalPermissions.SALES_ORDER_AMOUNT) && (
            <TableCell key={index} className="deposit" id="total">
              {item.totalString}
            </TableCell>
          )
        );
      case headerColumns.SALES_ORDER_TEMPLATE:
        const currentTemplate =
          soTemplates.find((template) => template.id === item.templateId) || {};
        return (
          <TableCell key={index} className="deposit" id="selectButton">
            {item.status === SALES_ORDER_STATUS.INBOX && (
              <SelectButton
                data={soTemplates.sort(sortObjectsBy("name")).map((el) => ({
                  value: el.id,
                  label: el.name,
                  emoji: el.emoji,
                  description: el.description,
                  isOutdated: el.isOutdated,
                }))}
                buttonSetup={getSelectedTemplate({
                  currentTemplate,
                })}
                disabled={item.status !== SALES_ORDER_STATUS.INBOX}
                onChange={(newId) => {
                  onOpenAttentionModal({
                    open: true,
                    soTemplate: soTemplates.find(
                      (soTemplate) => soTemplate.id === newId
                    ),
                    salesOrder: item,
                  });
                }}
                currentTemplate={currentTemplate}
                selectStyle={{ minWidth: 280, maxWidth: 280, width: "auto" }}
              />
            )}
            {item.status !== SALES_ORDER_STATUS.INBOX && (
              <span
                style={{ display: "flex" }}
              >{`${currentTemplate.emoji} ${currentTemplate.name}`}</span>
            )}
          </TableCell>
        );
      default:
        return <TableCell key={index} />;
    }
  }
  return (
    item && (
      <MainRowStyled
        key={item.id + "MainRowStyled"}
        id="salesOrderTableRow"
        className={cx(mainRowClasses.InboxTableRow, mainRowClasses.hoverEffect)}
      >
        {headers
          .filter(
            (header) =>
              !(
                header[GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT] &&
                !isAllowed(GENERAL_PERMISSION_VALUE.SALES_ORDER_AMOUNT)
              )
          )
          .map((header) =>
            SOTableRowSelector({
              column: header.name,
              enabled: header.enabled,
              index: item.id + header.name,
            })
          )}
        {isThereColumnSetting && (
          <TableCell key={item.id + item.date + "isThereColumnSetting"} />
        )}
      </MainRowStyled>
    )
  );
}

export default InboxTableRow;
