import React, { useEffect, useState } from "react";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

import { dbTables } from "../../api/types/dbTables";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useCompanyId } from "../../hooks";
import { TaskPopoverStyle } from "./styles";
import SimpleEditableLabel from "../TextFields/SimpleEditableLabel";
import { getDateByTimezone } from "../../helpers/helpers";
import moment from "moment";
import { getDisplayName, getUserAvatar } from "../../helpers/users";
import { useCompanyUsers, useUser } from "../../hooks/user";
import TooltipTD from "../Tooltip/TooltipTD";
import { DueLateDiamond } from "../../container/TaskListScreen/TaskScreenHelper";
import { cx } from "@emotion/css";
import { groupBy } from "lodash";
import {
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  TASK_TYPE,
  taskStatus,
} from "../../helpers/constants";
import { sortTaskList } from "../SalesOrders/soTemplateHelper";
import SalesOrderBadge from "../Badge/SalesOrderBadge";
import POBadge from "../Badge/POBadge";
import { getShipmentBadge } from "../../helpers/shipments";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { NavLink } from "react-router-dom";
import { getVerificationPath } from "../Sidebar/sidebarHelper";
import { getPOMetadada } from "../../helpers/salesOrderHelpers";
import { verifyPermission } from "../../hooks/permissions";
import AttentionModal from "../Modal/AttentionModal";

function TaskListPopover({
  anchorEl,
  onClose = () => {},
  entityId,
  entityDB,
  diamondStatus,
  item = {},
}) {
  const companyId = useCompanyId();
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const companyUsers = useCompanyUsers({});
  const currentUser = useUser();
  const taskGoupBy = (snapTasks) =>
    groupBy(snapTasks, (task) => {
      switch (task.type) {
        case TASK_TYPE.SHIPMENT:
          return task.shipmentId;
        case TASK_TYPE.PURCHASE_ORDER:
          return task.purchaseOrderId;

        default:
          return task.salesOrderId;
      }
    });

  const getTasksEntity = async () => {
    const { purchaseOrders = [], shipments = [] } = item;
    const taskPromises = [];
    taskPromises.push(
      getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${entityDB}/${entityId}/${dbTables.SALES_ORDER_TASKS}`
          ),
          where("status", "==", diamondStatus)
        )
      )
    );
    purchaseOrders.forEach((po) =>
      taskPromises.push(
        getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${po.id}/${dbTables.PURCHASE_ORDER_TASKS}`
            ),
            where("status", "==", diamondStatus)
          )
        )
      )
    );

    shipments.forEach((shipment) =>
      taskPromises.push(
        getDocs(
          query(
            collection(
              firestore,
              `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${shipment.id}/${dbTables.SHIPMENT_TASKS}`
            ),
            where("status", "==", diamondStatus)
          )
        )
      )
    );

    const results = await Promise.all(taskPromises);
    const snapTasks = results.flatMap((snapshot) =>
      snapshot.docs.map((doc) => doc.data())
    );
    const groupedTasks = taskGoupBy(snapTasks);
    setTasks(groupedTasks);
    setIsLoading(false);
  };

  const getTasks = async () => {
    const taskDB = {
      [dbTables.PURCHASE_ORDERS]: dbTables.PURCHASE_ORDER_TASKS,
      [dbTables.SHIPMENTS]: dbTables.SHIPMENT_TASKS,
    };

    const tasksSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${entityDB}/${entityId}/${taskDB[entityDB]}`
        ),
        where("status", "==", diamondStatus)
      )
    );
    const groupedTasks = taskGoupBy(
      tasksSnap.docs.map((tasksnap) => ({
        ...tasksnap.data(),
      }))
    );

    setTasks(groupedTasks);
    setIsLoading(false);
  };

  useEffect(() => {
    if (anchorEl) {
      if (entityDB !== dbTables.SALES_ORDERS) {
        getTasks();
      } else {
        getTasksEntity();
      }
    }
  }, []);

  const getBadgeTask = (id, tasks) => {
    if (id === item.id) {
      let badge;
      switch (entityDB) {
        case dbTables.SALES_ORDERS:
          badge = <SalesOrderBadge number={item.number} />;
          break;
        case dbTables.PURCHASE_ORDERS:
          badge = <POBadge number={item.number} />;
          break;

        default:
          badge = getShipmentBadge({
            shipment: item,
            styles: { margin: 0 },
            classes: shipmentBadgeClass.medium,
            onChangeShipment: () => {},
          });
          break;
      }
      return {
        isAvalible: true,
        badge: (
          <NavLink
            className={"badgeRow"}
            to={`/app/${getVerificationPath({
              id: id,
              scope: entityDB,
            })}`}
          >
            {badge}
          </NavLink>
        ),
      };
    } else {
      const { shipments = [], purchaseOrders = [] } = item;
      const currentPO = purchaseOrders.find((po) => po.id === id);
      if (currentPO) {
        const isAvalible = verifyPermission({
          user: currentUser,
          permissionToCheck: [
            currentPO.factoryId,
            GENERAL_PERMISSION_VALUE.ALL_VENDORS,
          ],
        });
        const lateCount = tasks.filter(
          (task) => task.status === taskStatus.LATE
        ).length;
        const nearDueCount = tasks.filter(
          (task) => task.status === taskStatus.NEAR_DUE
        ).length;
        return {
          isAvalible,
          badge: (
            <span className="poBadgeRow">
              <NavLink
                className={"badgeRow"}
                onClick={(ev) => {
                  if (!isAvalible) {
                    setOpenAttentionModal(true);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                }}
                to={
                  !isAvalible
                    ? "#"
                    : `/app/${getVerificationPath({
                        id: id,
                        scope: dbTables.PURCHASE_ORDERS,
                      })}`
                }
              >
                <TooltipTD
                  label={
                    isAvalible
                      ? getPOMetadada(currentPO)
                      : "You do not have permission to see this vendor"
                  }
                  className="customized-tooltip"
                >
                  <POBadge number={currentPO.number} isDisable={!isAvalible} />
                </TooltipTD>
              </NavLink>
              {!isAvalible && lateCount > 0 && (
                <span className="countData">
                  <DueLateDiamond
                    item={{ status: taskStatus.LATE }}
                    width={"100%"}
                    showTooltip={false}
                  />
                  {`x${lateCount}`}
                </span>
              )}
              {!isAvalible && nearDueCount > 0 && (
                <span className="countData">
                  <DueLateDiamond
                    item={{ status: taskStatus.NEAR_DUE }}
                    width={"100%"}
                    showTooltip={false}
                  />
                  {`x${nearDueCount}`}
                </span>
              )}
            </span>
          ),
        };
      }
      const currentShipment = shipments.find((shipment) => shipment.id === id);
      if (currentShipment) {
        return {
          isAvalible: true,
          badge: (
            <NavLink
              className={"badgeRow"}
              to={`/app/${getVerificationPath({
                id: id,
                scope: dbTables.SHIPMENTS,
              })}`}
            >
              {getShipmentBadge({
                shipment: currentShipment,
                styles: { margin: 0 },
                classes: shipmentBadgeClass.medium,
                onChangeShipment: () => {},
              })}
            </NavLink>
          ),
        };
      }
    }
    return <span>error</span>;
  };

  const getTaskData = (task) => {
    switch (task.type) {
      case TASK_TYPE.SHIPMENT:
        return { scope: dbTables.SHIPMENTS, currentOrderId: task.shipmentId };
      case TASK_TYPE.PURCHASE_ORDER:
        return {
          scope: dbTables.PURCHASE_ORDERS,
          currentOrderId: task.purchaseOrderId,
        };

      default:
        return {
          scope: dbTables.SALES_ORDERS,
          currentOrderId: task.salesOrderId,
        };
    }
  };

  return (
    <TaskPopoverStyle
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{ paper: "paperStyle" }}
      open={!!anchorEl}
      className="TaskPopoverContainer"
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title={"Attention"}
          description={PERMISSION_TEXT.VENDOR}
          onClick={() => {
            setOpenAttentionModal(false);
          }}
        />
      )}
      {isLoading && <LoadingBackdrop />}
      <div className="listTaskContainerPopover">
        {Object.entries(tasks).map(([curentId, tasksGroup]) => {
          const sortingTask = sortTaskList(tasksGroup, {});
          const { isAvalible, badge } = getBadgeTask(curentId, sortingTask);
          return (
            <div key={curentId} className="taskGroup">
              {badge}
              {isAvalible &&
                sortingTask.map((task, index) => {
                  const { scope, currentOrderId } = getTaskData(task);
                  const assignedUser = companyUsers.find(
                    (companyUser) => companyUser.id === task.assignedTo
                  );

                  return (
                    <div
                      key={task.id}
                      className={cx("taskRow", `task${task.type}`, {
                        firstTask: index === 0,
                      })}
                    >
                      <DueLateDiamond item={task} width={"100%"} />
                      <NavLink
                        to={`/app/${getVerificationPath({
                          id: currentOrderId,
                          scope: scope,
                          toThreePanel: true,
                        })}&&taskId=${task.id}`}
                      >
                        <SimpleEditableLabel
                          id={task.id + "-TaskDescriptionList"}
                          disable={true}
                          text={task.description}
                          className="taskLabelContainer"
                          textStyle={{
                            opacity: 1,
                          }}
                          labelDescription={
                            <span className={"labelContainer"}>
                              <span className="span-task-description">
                                {task.description}
                              </span>
                            </span>
                          }
                        />
                      </NavLink>

                      <div
                        id={"date-container-task-item" + task.id}
                        className="dateTaskBox"
                      >
                        {task.finishDate
                          ? getDateByTimezone({
                              date: task.finishDate,
                            })
                          : moment(new Date()).format("M/D/YY")}
                      </div>
                      <TooltipTD
                        label={getDisplayName({ user: assignedUser })}
                        className={"userTaskBox"}
                        id={"users-container-task-item" + task.id}
                      >
                        {task.assignedTo &&
                          getUserAvatar({
                            user: assignedUser,
                            styles: {
                              width: 20,
                              height: 20,
                              fontSize: 13,
                              outline: "2px solid #000",
                            },
                            showEmptyAvatar: true,
                          })}
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {task.assignedTo &&
                            getDisplayName({ user: assignedUser })}
                        </span>
                      </TooltipTD>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </TaskPopoverStyle>
  );
}

export default TaskListPopover;
