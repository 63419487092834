import {
  GET_DATA,
  ADD_DATA,
  UPDATE_DATA,
  DELETE_DATA,
  GET_DATA_WITH_CHILD,
  ADD_DATA_WITH_CHILD,
  UPDATE_DATA_WITH_CHILD,
  DELETE_DATA_WITH_CHILD,
  UNSUBSCRIBE_CURRENT_CUSTOMER,
  UNSUBSCRIBE_CURRENT_FACTORY,
  UNSUBSCRIBE_CURRENT_SHIPMENT,
  UNSUBSCRIBE_PURCHASE_ORDERS,
  DASHBOARD_BACKDROP,
  UNSUBSCRIBE_ITEMS,
  UNSUBSCRIBE_COLLECTION,
  ACTIVITY_STREAM,
  ACTIVITY_NOTE_DATE,
  GET_SO_DATA,
  UNSUBSCRIBE_SHIPMENTS,
  UNSUBSCRIBE_ROLE_PERMISSIONS,
  LOAD_NOTIFICATIONS,
  CLEAN_UNSUBSCRIBE_ITEMS,
  CLIENT_IS_OFFLINE,
} from "./types";
import { pathToString } from "../helpers/helpers";
import { dbTables } from "../api/types/dbTables";
import userTypes from "../api/types/userTypes";
import { firestore, performanceFirebase } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  doc,
  limit,
  orderBy,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { trace } from "firebase/performance";

const activeListeners = {};
const activeSOListeners = {};
let lastIdDocuments = {
  customer: false,
  factory: false,
  shipment: false,
  items: false,
};
const actionByKey = {
  customer: UNSUBSCRIBE_CURRENT_CUSTOMER,
  factory: UNSUBSCRIBE_CURRENT_FACTORY,
  shipment: UNSUBSCRIBE_CURRENT_SHIPMENT,
  items: UNSUBSCRIBE_ITEMS,
};
function addActiveSOListener(id) {
  activeSOListeners[id] = true;
}

function addActiveListener(listenerPath) {
  activeListeners[listenerPath] = true;
}

export const getDataFromFirestore = (dbCollection, user) => (dispatch) => {
  try {
    if (activeListeners[dbCollection]) {
      return;
    }
    let ref;
    if (user.role === userTypes.TRADEDASH_EMPLOYEE) {
      ref = collection(firestore, dbCollection);
    } else {
      ref = query(
        collection(firestore, dbCollection),
        where("id", "==", user.companyId)
      );
    }
    onSnapshot(
      ref,
      (snapshot) => {
        addActiveListener(dbCollection);
        if (snapshot.docChanges().every((change) => change.type === "added")) {
          dispatch({
            type: GET_DATA,
            table: dbCollection,
            payload: snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            })),
          });
        } else {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADD_DATA,
                  table: dbCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "modified":
                dispatch({
                  type: UPDATE_DATA,
                  table: dbCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "removed":
                dispatch({
                  type: DELETE_DATA,
                  table: dbCollection,
                  payload: change.doc.data().id,
                });
                break;
              default:
                break;
            }
          });
        }
      },
      (error) => {
        console.log("ERROR getDataFromFirestore", dbCollection, error);
      }
    );
  } catch (error) {
    console.log("ERROR getDataFromFirestore", dbCollection);
  }
};

export const getCompanyUsersDataFromFirestore =
  (dbCollection, companyId) => (dispatch) => {
    if (activeListeners[dbCollection]) {
      return;
    }
    onSnapshot(
      query(
        collection(firestore, dbCollection),
        where("companyId", "==", companyId)
      ),
      (snapshot) => {
        addActiveListener(dbCollection);
        if (snapshot.docChanges().every((change) => change.type === "added")) {
          dispatch({
            type: GET_DATA,
            table: dbCollection,
            payload: snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            })),
          });
        } else {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADD_DATA,
                  table: dbCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "modified":
                dispatch({
                  type: UPDATE_DATA,
                  table: dbCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "removed":
                dispatch({
                  type: DELETE_DATA,
                  table: dbCollection,
                  payload: change.doc.data().id,
                });
                break;
              default:
                break;
            }
          });
        }
      },
      (error) => {
        console.log(
          "ERROR getCompanyUsersDataFromFirestore",
          dbCollection,
          error
        );
      }
    );
  };

export const getUsersDataFromFirestore = (userId) => (dispatch) => {
  const table = userTypes.CURRENT_USER;
  if (activeListeners[table]) {
    return;
  }
  onSnapshot(
    doc(firestore, `${dbTables.USERS}/${userId}`),
    (snapshot) => {
      addActiveListener(table);
      if (snapshot.exists) {
        dispatch({
          type: GET_DATA,
          table: table,
          payload: {
            ...snapshot.data(),
            ref: snapshot.ref,
          },
        });
      } else {
        dispatch({
          type: DELETE_DATA,
          table: table,
          payload: {},
        });
      }
    },
    (error) => {
      console.log("ERROR getUsersDataFromFirestore", table, error);
    }
  );
};

export const getNestedCollectionFromFirestoreTable =
  ({
    path,
    queryParams = null,
    limitValue = null,
    orderByValue = null,
    dbTableRef = null,
    getParentId = false,
    reduxData,
  }) =>
  async (dispatch) => {
    if (!path) {
      return;
    }
    const pathArray = path.split("/");
    if (pathArray.includes("undefined") || pathArray.includes("notfound")) {
      return;
    }
    try {
      if (activeListeners[path]) {
        return true;
      }
      activeListeners[path] = true;
      const pathComponents = path.split("/");
      const isCollection = pathComponents.length % 2 !== 0;
      const childCollectionId = isCollection
        ? pathComponents[pathComponents.length - 2]
        : pathComponents[pathComponents.length - 1];
      let subCollection = isCollection
        ? pathComponents[pathComponents.length - 1]
        : pathComponents[pathComponents.length - 2];
      if (reduxData) {
        subCollection = reduxData;
      }
      let collectionRef = collection(firestore, path);
      if (queryParams) {
        const { field, condition, value } = queryParams;
        collectionRef = query(collectionRef, where(field, condition, value));
      }
      if (limitValue) {
        collectionRef = query(collectionRef, limit(limitValue));
      }
      if (orderByValue) {
        const { field, sort } = orderByValue;
        collectionRef = query(collectionRef, orderBy(field, sort));
      }
      let parentId = "";
      if (getParentId) {
        parentId = pathComponents[3];
      }
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          if (
            snapshot.docChanges().every((change) => change.type === "added")
          ) {
            dispatch({
              type: GET_DATA_WITH_CHILD,
              childKey: childCollectionId,
              table: subCollection,
              payload: snapshot.docs.map((doc) => ({
                ...doc.data(),
                ref: doc.ref,
                parentId: parentId || doc.data().parentId || "",
              })),
            });
          } else {
            snapshot.docChanges().forEach((change) => {
              const docParentId = change.doc.data().parentId;
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: {
                      ...change.doc.data(),
                      ref: change.doc.ref,
                      parentId: parentId || docParentId || "",
                    },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: {
                      ...change.doc.data(),
                      ref: change.doc.ref,
                      parentId: parentId || docParentId || "",
                    },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: DELETE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: change.doc.data().id,
                  });
                  break;
                default:
                  break;
              }
            });
          }
        },
        (error) => {
          console.log(
            "ERROR getNestedCollectionFromFirestoreTable",
            path,
            error
          );
        }
      );

      if (dbTableRef && unsubscribe) {
        const id = pathArray[pathArray.length - 2];
        return dispatch({
          type: actionByKey[dbTableRef] || "_",
          payload: { [id]: unsubscribe },
        });
      }
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH PATH 111", path, { error });
    }
  };

// REFACTORING GET NESTED COLLECTION FROM FIRESTORE TABLE
export const getNestedCollectionFromFirestoreTableRefactored =
  ({
    path,
    queryParams = null,
    limitParams = null,
    orderByParams = null,
    stateReference = null,
  }) =>
  (dispatch) => {
    if (!path) {
      return;
    }
    const pathArray = path.split("/");
    if (pathArray.includes("undefined") || pathArray.includes("notfound")) {
      return;
    }
    try {
      const pathComponents = path.split("/");
      const isCollection = pathComponents.length % 2 !== 0;
      const childCollectionId = isCollection
        ? pathComponents[pathComponents.length - 2]
        : pathComponents[pathComponents.length - 1];
      const subCollection = isCollection
        ? stateReference
        : pathComponents[pathComponents.length - 2];

      let collectionRef = collection(firestore, path);
      if (queryParams) {
        const { field, condition, value } = queryParams;
        collectionRef = query(collectionRef, where(field, condition, value));
      }
      if (orderByParams) {
        const { field, sort } = orderByParams;
        collectionRef = query(collectionRef, orderBy(field, sort));
      }
      if (limitParams) {
        collectionRef = query(collectionRef, limit(limitParams));
      }

      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        if (snapshot.empty && !navigator.onLine) {
          console.log("No internet connection for: ", path);
          dispatch({
            type: CLIENT_IS_OFFLINE,
            payload: {
              value: true,
              text: "Please wait a moment and refresh the page",
            },
          });
        } else if (
          snapshot.docChanges().every((change) => change.type === "added")
        ) {
          dispatch({
            type: GET_DATA_WITH_CHILD,
            childKey: childCollectionId,
            table: subCollection,
            payload: snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            })),
          });
        } else {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADD_DATA_WITH_CHILD,
                  childKey: childCollectionId,
                  table: subCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "modified":
                dispatch({
                  type: UPDATE_DATA_WITH_CHILD,
                  childKey: childCollectionId,
                  table: subCollection,
                  payload: { ...change.doc.data(), ref: change.doc.ref },
                });
                break;
              case "removed":
                if (limitParams) return true;
                dispatch({
                  type: DELETE_DATA_WITH_CHILD,
                  childKey: childCollectionId,
                  table: subCollection,
                  payload: change.doc.data().id,
                });
                break;
              default:
                break;
            }
          });
        }
      });
      if (stateReference) {
        const id = pathArray[pathArray.length - 2];
        return dispatch({
          type: UNSUBSCRIBE_COLLECTION || "_",
          payload: {
            documentId: id,
            callback: unsubscribe,
            reduxStateRefCallback: "unsubscribe_" + stateReference,
            reduxStateRef: stateReference,
          },
        });
      }
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH PATH 222", path);
    }
  };
//

// REFACTORING GET NESTED COLLECTION FROM FIRESTORE TABLE
export const getDocumentMiscellaneous =
  ({ path = "", stateReference = null, referenceId = "" }) =>
  (dispatch) => {
    if (!path) {
      return;
    }
    const pathArray = path.split("/");
    if (pathArray.includes("undefined") || pathArray.includes("notfound")) {
      return;
    }

    try {
      let docRef = doc(firestore, path);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          dispatch({
            type: GET_DATA,
            table: stateReference,
            payload: { [referenceId]: { ...doc.data(), ref: doc.ref } },
          });
        } else {
          // dispatch({
          //   type: DELETE_DATA,
          //   table: stateReference,
          //   payload: {
          //     [referenceId]: {},
          //   },
          // });
        }
      });
      if (stateReference) {
        const id = pathArray[pathArray.length - 2];
        return dispatch({
          type: UNSUBSCRIBE_COLLECTION || "_",
          payload: {
            documentId: id,
            callback: unsubscribe,
            reduxStateRefCallback: "unsubscribe_" + stateReference,
            reduxStateRef: stateReference,
          },
        });
      }
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH PATH 222", path);
    }
  };
//

export const getListenRolesPermissions =
  ({ roleId, companyId }) =>
  (dispatch) => {
    try {
      if (!roleId) {
        return;
      }
      const childCollectionId = roleId;
      const subCollection = dbTables.PERMISSIONS;
      const documentSnapshot = onSnapshot(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.ROLES}/${roleId}/${dbTables.PERMISSIONS}`
        ),
        (snapshot) => {
          if (
            snapshot.docChanges().every((change) => change.type === "added")
          ) {
            dispatch({
              type: GET_DATA_WITH_CHILD,
              childKey: childCollectionId,
              table: subCollection,
              payload: snapshot.docs.map((doc) => ({
                ...doc.data(),
                ref: doc.ref,
              })),
            });
          } else {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: DELETE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: change.doc.data().id,
                  });
                  break;
                default:
                  break;
              }
            });
          }
        }
      );
      dispatch({
        type: UNSUBSCRIBE_ROLE_PERMISSIONS,
        payload: documentSnapshot,
      });
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH new ROLE PERMISSIONS", error);
    }
  };

export const getListenerSKU =
  ({ poId, skuVersion, companyId }) =>
  (dispatch) => {
    try {
      const childCollectionId = skuVersion;
      const subCollection = dbTables.ITEMS;
      let collectionRef = collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${poId}/${dbTables.ITEM_TABLE}/${skuVersion}/${dbTables.ITEMS}`
      );
      const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
        if (snapshot.empty && !navigator.onLine) {
          console.log("No internet connection SKU");
          dispatch({
            type: CLIENT_IS_OFFLINE,
            payload: {
              value: true,
              text: "Please wait a moment and refresh the page",
            },
          });
        } else {
          if (
            snapshot.docChanges().every((change) => change.type === "added")
          ) {
            dispatch({
              type: GET_DATA_WITH_CHILD,
              childKey: childCollectionId,
              table: subCollection,
              payload: snapshot.docs.map((doc) => ({
                ...doc.data(),
                ref: doc.ref,
                parentId: poId,
              })),
            });
          } else {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: {
                      ...change.doc.data(),
                      ref: change.doc.ref,
                      parentId: poId,
                    },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: {
                      ...change.doc.data(),
                      ref: change.doc.ref,
                      parentId: poId,
                    },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: DELETE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: change.doc.data().id,
                  });
                  break;
                default:
                  break;
              }
            });
          }
        }
      });

      return dispatch({
        type: UNSUBSCRIBE_ITEMS,
        payload: { [skuVersion]: unsubscribe },
      });
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH PATH SKU", { error });
    }
  };

export const getListenUserMentions =
  ({ userId }) =>
  (dispatch) => {
    try {
      onSnapshot(
        collection(
          firestore,
          `${dbTables.USERS}/${userId}/${dbTables.MENTIONS}`
        ),
        (snapshot) => {
          if (snapshot.empty && !navigator.onLine) {
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: true,
                text: "Please wait a moment and refresh the page",
              },
            });
          } else {
            const mentionsList = {};
            snapshot.docs.forEach(
              (doc) =>
                (mentionsList[doc.id] = {
                  ...doc.data(),
                  ref: doc.ref,
                  id: doc.id,
                })
            );

            dispatch({
              type: GET_DATA,
              table: dbTables.MENTIONS,
              payload: mentionsList,
              id: snapshot.id,
            });
          }
        }
      );
    } catch (error) {
      console.log("ERROR IN MENTIONDATA ACTION WITH NEW MENTION", error);
    }
  };

export const getListenerSO =
  ({ salesOrderId, companyId }) =>
  (dispatch) => {
    try {
      if (activeSOListeners[salesOrderId]) {
        return;
      }
      let traceInstance = trace(
        performanceFirebase,
        "dashboard_salesOrder_listener"
      );
      traceInstance.start();
      addActiveSOListener(salesOrderId);
      onSnapshot(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}/${salesOrderId}`
        ),
        (snapshot) => {
          if (snapshot.empty && !navigator.onLine) {
            console.log("No internet connection Sales Order");
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: true,
                text: "Please wait a moment and refresh the page",
              },
            });
          } else {
            const payloadData = {
              ...snapshot.data(),
              ref: snapshot.ref,
              id: snapshot.id,
              exists: snapshot.exists,
            };
            dispatch({
              type: GET_SO_DATA,
              table: dbTables.SALES_ORDERS,
              payload: payloadData,
              id: snapshot.id,
            });
          }
          if (traceInstance) {
            traceInstance.stop();
            traceInstance = false;
          }
        }
      );
    } catch (error) {
      console.log("ERROR IN SODATA ACTION WITH NEW SO", error);
    }
  };

export const cleanPOsRedux =
  ({ companyId }) =>
  (dispatch) => {
    dispatch({
      type: GET_DATA_WITH_CHILD,
      childKey: companyId,
      table: dbTables.PURCHASE_ORDERS,
      payload: [],
    });
  };

export const clearSKURedux =
  ({ companyId }) =>
  (dispatch) => {
    dispatch({
      type: CLEAN_UNSUBSCRIBE_ITEMS,
      childKey: companyId,
      table: "items",
      payload: {},
    });
  };

export const cleanShipmentRedux =
  ({ companyId }) =>
  (dispatch) =>
    dispatch({
      type: GET_DATA_WITH_CHILD,
      childKey: companyId,
      table: dbTables.SHIPMENTS,
      payload: [],
    });

export const getListenerShipments =
  ({ purchaseOrderId, companyId }) =>
  (dispatch) => {
    try {
      let traceInstance = trace(
        performanceFirebase,
        "dashboard_shipments_listener"
      );
      traceInstance.start();
      const childCollectionId = companyId;
      const subCollection = dbTables.SHIPMENTS;
      const documentListener = onSnapshot(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
          ),
          where("purchaseOrderIds", "array-contains", purchaseOrderId)
        ),
        (snapshot) => {
          if (snapshot.empty && !navigator.onLine) {
            console.log("No internet connection Shipments");
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: true,
                text: "Please wait a moment and refresh the page",
              },
            });
          } else if (
            snapshot.docChanges().every((change) => change.type === "added")
          ) {
            dispatch({
              type: GET_DATA_WITH_CHILD,
              childKey: childCollectionId,
              table: subCollection,
              payload: snapshot.docs.map((doc) => ({
                ...doc.data(),
                ref: doc.ref,
              })),
            });
            if (traceInstance) {
              traceInstance.stop();
              traceInstance = false;
            }
          } else {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: DELETE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: change.doc.data().id,
                  });
                  break;
                default:
                  break;
              }
            });
          }
        }
      );

      dispatch({
        type: UNSUBSCRIBE_SHIPMENTS,
        payload: documentListener,
      });
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH NEW SHIPMENTS", error);
    }
  };

export const getListenerPOs =
  ({ salesOrderId, companyId }) =>
  (dispatch) => {
    try {
      let traceInstance = trace(
        performanceFirebase,
        "dashboard_purchaseOrders_listener"
      );
      traceInstance.start();
      const childCollectionId = companyId;
      const subCollection = dbTables.PURCHASE_ORDERS;
      const document = onSnapshot(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}`
          ),
          where("salesOrderIds", "array-contains", salesOrderId)
        ),
        (snapshot) => {
          if (snapshot.empty && !navigator.onLine) {
            console.log("No internet connection PO");
            dispatch({
              type: CLIENT_IS_OFFLINE,
              payload: {
                value: true,
                text: "Please wait a moment and refresh the page",
              },
            });
          } else if (
            snapshot.docChanges().every((change) => change.type === "added")
          ) {
            dispatch({
              type: GET_DATA_WITH_CHILD,
              childKey: childCollectionId,
              table: subCollection,
              payload: snapshot.docs.map((doc) => ({
                ...doc.data(),
                ref: doc.ref,
              })),
            });
            if (traceInstance) {
              traceInstance.stop();
              traceInstance = false;
            }
          } else {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: { ...change.doc.data(), ref: change.doc.ref },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: DELETE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: change.doc.data().id,
                  });
                  break;
                default:
                  break;
              }
            });
          }
        },
        (error) => {
          console.log("ERROR IN DATA ACTION WITH NEW PO", error);
        }
      );

      dispatch({
        type: UNSUBSCRIBE_PURCHASE_ORDERS,
        payload: document,
      });
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH new PO", error);
    }
  };

export const eraseBackdropData =
  ({
    infoCard = false,
    scrollProperty = {},
    documentScope = "",
    documentType = "",
    documentVersion = "",
  }) =>
  (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_BACKDROP,
        table: "backdrop",
        payload: {
          type: "",
          isOpen: "",
          id: "",
          infoCard,
          scrollProperty,
          documentScope,
          documentType,
          documentVersion,
        },
      });
    } catch (error) {
      console.log("error DASHBOARD_BACKDROP", error);
    }
  };

export const setBackdropPanelSection = ({
  type,
  id,
  documentScope,
  documentType,
  documentVersion,
  dashboard,
  dispatch,
  isOpenBackdrop = true,
}) => {
  if (isOpenBackdrop) {
    dispatch({
      type: DASHBOARD_BACKDROP,
      table: "backdrop",
      payload: {
        type,
        isOpen: true,
        id: id || type,
        documentScope,
        documentType,
        documentVersion,
        dashboard,
      },
    });
  }
};

export const setGlobalBackdrop =
  ({
    type,
    isOpen,
    id,
    infoCard = false,
    scrollProperty = {},
    documentScope = "",
    documentType = "",
    documentVersion = "",
    dashboard = false,
  }) =>
  (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_BACKDROP,
        table: "backdrop",
        payload: {
          type,
          isOpen,
          id,
          infoCard,
          scrollProperty,
          documentScope,
          documentType,
          documentVersion,
          dashboard,
        },
      });
    } catch (error) {
      console.log("error DASHBOARD_BACKDROP", error);
    }
  };

export const setGlobalNoteDate =
  ({ type, creationDate, noteId, wait }) =>
  (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_NOTE_DATE,
        table: "activityNote",
        payload: {
          type,
          creationDate,
          noteId,
          wait,
        },
      });
    } catch (error) {
      console.log("error NOTE_DATE", error);
    }
  };

export const setActivities =
  ({
    companyId,
    startDate,
    endDate,
    dbTable,
    dbTableId,
    reduxState,
    loadState = {},
    setLoadState,
  }) =>
  async (dispatch) => {
    try {
      if (isNaN(startDate) || isNaN(endDate)) {
        return;
      }
      const traceInstance = trace(
        performanceFirebase,
        `dashboard_get_activitie_${dbTable}`
      );
      traceInstance.start();
      const activitiesSnapDB = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTable}/${dbTableId}/${dbTables.ACTIVITIES}`
          ),
          where("creationDate", ">=", startDate),
          where("creationDate", "<=", endDate)
        )
      );
      const activitiesDB = activitiesSnapDB.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
      }));
      if (loadState.isLoading) {
        setLoadState((oldState) => {
          const limitStep = oldState.limitStep;
          const nextStep = oldState.initStep + 1;
          return {
            initStep: nextStep,
            isLoading: nextStep < limitStep,
            limitStep,
          };
        });
      }
      dispatch({
        type: ACTIVITY_STREAM,
        reduxState,
        payload: {
          activities: activitiesDB || [],
          documentId: dbTableId,
        },
      });
      traceInstance.stop();
    } catch (error) {
      console.log("error Activities", error);
    }
  };

export const getFirestoreDocument =
  ({ table, path, keyName = false, limit, orderBy }) =>
  (dispatch) => {
    try {
      if (!path) {
        return;
      }
      let callListener = true;
      if (keyName) {
        if (path === lastIdDocuments[keyName]) {
          callListener = false;
        } else {
          activeListeners[path] = false;
        }
        lastIdDocuments[keyName] = path;
      } else {
        callListener = !activeListeners[path];
      }
      if (!callListener) {
        return true;
      }
      activeListeners[path] = true;
      const pathComponents = path.split("/");
      let collectionRef;
      if (pathComponents % 2 !== 0) {
        collectionRef = doc(firestore, path);
      } else {
        collectionRef = collection(firestore, path);
        if (orderBy && orderBy.length >= 2) {
          collectionRef = query(collectionRef, orderBy(orderBy[0], orderBy[1]));
        }
        if (limit) {
          collectionRef = query(collectionRef, limit(limit));
        }
      }

      const unsubscribeDocument = onSnapshot(collectionRef, (snapshot) => {
        const isCollection = pathComponents.length % 2 !== 0;
        const payloadData = isCollection
          ? snapshot.docs.map((doc) => ({ ...doc.data(), ref: doc.ref })) || []
          : {
              ...snapshot.data(),
              ref: snapshot.ref,
            };

        dispatch({
          type: GET_DATA,
          table: table,
          payload: payloadData,
        });
      });

      dispatch({
        type: actionByKey[keyName] || "_",
        payload: unsubscribeDocument,
      });
    } catch (error) {
      console.log("ERROR IN DATA ACTION WITH PATH 6666", path, error);
    }
  };

export const getNotificationsFromFirestoreTable =
  (path, filters = {}) =>
  (dispatch) => {
    if (Object.keys(filters).length > 0) {
      activeListeners[path] = false;
    }
    if (activeListeners[path]) {
      return true;
    }
    activeListeners[path] = true;
    const pathComponents = path.split("/");
    const isCollection = pathComponents.length % 2 !== 0;
    const childCollectionId = isCollection
      ? pathComponents[pathComponents.length - 2]
      : pathComponents[pathComponents.length - 1];
    const subCollection = isCollection
      ? pathComponents[pathComponents.length - 1]
      : pathComponents[pathComponents.length - 2];
    onSnapshot(
      query(
        collection(firestore, path),
        orderBy("creationDate", "desc"),
        limit(10)
      ),
      (snapshot) => {
        if (snapshot.docChanges().every((change) => change.type === "added")) {
          dispatch({
            type: GET_DATA_WITH_CHILD,
            childKey: childCollectionId,
            table: subCollection,
            payload: snapshot.docs.map((doc) => ({
              ...doc.data(),
              ref: doc.ref,
            })),
          });
        } else {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                {
                  const doc = { ...change.doc.data(), ref: change.doc.ref };
                  dispatch({
                    type: ADD_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: doc,
                  });
                  dispatch({
                    type: LOAD_NOTIFICATIONS,
                    payload: doc,
                    isFromListener: true,
                  });
                }
                break;
              case "modified":
                {
                  const doc = { ...change.doc.data(), ref: change.doc.ref };
                  dispatch({
                    type: UPDATE_DATA_WITH_CHILD,
                    childKey: childCollectionId,
                    table: subCollection,
                    payload: doc,
                  });
                  dispatch({
                    type: LOAD_NOTIFICATIONS,
                    payload: doc,
                    isFromListener: true,
                  });
                }
                break;
              case "removed":
                dispatch({
                  type: DELETE_DATA_WITH_CHILD,
                  childKey: childCollectionId,
                  table: subCollection,
                  payload: change.doc.data().id,
                });
                break;
              default:
                break;
            }
          });
        }
      },
      (error) => {
        console.log("ERROR LISTENING NOTIFICATIONS ", error);
      }
    );
  };

export const addDataToFirestore = (dbCollection, document) => () => {
  setDoc(doc(firestore, `${dbCollection}/${document.id}`), document);
};

export const deleteDataFromFirestore = (dbCollection, documentId) => () => {
  deleteDoc(doc(firestore, `${dbCollection}/${documentId}`));
};

export const addCollectionToFirestore =
  (dbCollection, collectionId, collectionChild, collection) => () => {
    setDoc(
      doc(
        firestore,
        `${dbCollection}/${collectionId}/${collectionChild}/${collection.id}`
      ),
      collection
    );
  };

export const addGenericDataToFirestore =
  (arrayPath = [], data) =>
  () => {
    const path = pathToString([...arrayPath]);
    setDoc(doc(firestore, path), data);
  };

//

export const deleteDataCollectionFromFirestore =
  (dbCollection, collectionId, collectionChild, documentId) => () => {
    deleteDoc(
      doc(
        firestore,
        `${dbCollection}/${collectionId}/${collectionChild}/${documentId}`
      )
    );
  };
