import { Drawer, styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";

export const ReportStyled = styled("div")(() => ({
  "&.reportContainer": {
    width: "100%",
    height: "calc(100vh - 71px)",
    "& #grid-bottom-bar": {
      display: "none !Important",
    },
    "& .URL-to-update-report-content": {
      position: "absolute",
      top: 0,
      left: 120,
      zIndex: 50,
      alignItems: "center",
      height: 67,
      display: "flex",
      "& button": {
        padding: "8px 12px",
        background: "#39f",
        fontWeight: 400,
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& span": {
        fontSize: 13,
        fontWeight: 400,
        margin: 4,
      },
    },
  },
}));

export const ReportDrawerStyled = styled(Drawer)(() => ({
  "&.drawerRoot": {
    marginLeft: 86,
    marginTop: 66,
    position: "absolute",
    zIndex: 1,
    "& .MuiBackdrop-root": {
      marginLeft: 86,
      marginTop: 66,
    },
    "& .MuiDrawer-paper": {
      width: 609,
      marginLeft: 86,
      marginTop: 66,
      boxShadow: "15px -5px 21px 0px rgba(0, 0, 0, 0.25)",
      overflow: "hidden",
    },
    "& .reportList": {
      overflowY: "scroll",
      maxHeight: "calc(100vh - 195px)",
  
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent",
        width: 16,
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: 7,
      },
      "&::-webkit-scrollbar-track": {
        border: " solid 1px transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
          borderBlock: "1px solid transparent",
          borderInline: "5px solid transparent",
        },
      },
    },
  },

  "& .closeIconContainer": {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    "& .note-secction-cancel-icon": {
      cursor: "pointer",
      position: "relative !Important",
      top: "auto !Important",
      right: "auto !Important",
    },
  },
  "& .headerContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "12px 31px 24px 16px",
    gap: 10,
    "& .search-box-list": {
      width: "100% !Important",
    },
    "& .boxed-filter": {
      userSelect: "none",
      height: 40,
      minWidth: 53,
      borderRadius: 5,
      outline: "1px solid #d2dfec",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& .favoriteButton": {
        padding: 0,
        "&:hover": {
          background: "#F5F5F5",
        },
      },
    },
  },

  "& .reportListText": {
    padding: "5px 14px 5px 3px",
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    "& .favorite-container": {
      width: 20,
      minWidth: 20,
      height: "100%",
      marignLeft: 10,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      cursor: "pointer",
      "& .starIcon": {
        opacity: 0,
      },
      "& .activeIcon": {
        opacity: 1,
      },
    },
    "& .disclouserArrowStyle": {
      borderRadius: 5,
      cursor: "pointer",
      fontSize: 20,
      width: 24,
      minWidth: 24,
      height: 24,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
      "& .arrow": {
        transition: "transform 0.5s ease-in-out",
      },
    },
    "&:hover": {
      background: AppConfig.themeColors.yellowOnHover,
      "& .starIcon": {
        opacity: 1,
      },
      "& .icons-container": {
        opacity: 1,
      },
    },
    "& .report-icon": {
      marginRight: 5,
    },
    "& .report-name": {
      fontSize: 14,
      fontWeight: 500,
      color: colors.primaryDark,
      cursor: "pointer",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      width: "100%",
      textAlign: "justify",
      paddingRight: 15,
    },
    "& .icons-container": {
      display: "flex",
      alignItems: "center",
      opacity: 0,
      "& .icon": {
        margin: "0px 4px",
        cursor: "pointer",
        display: "flex",
      },
    },

    "& .folderIcon": {
      cursor: "unset",
    },
  },
  "& .blueBar": {
    boxShadow: "inset 4px 0 0 0 #39F",
  },
  "& .slideRight": {
    width: 609,
  },
  "& .slideLeft": {
    width: 0,
  },
  "& .reportPopoverContainer": {
    padding: "24px 24px 12px 24px",
    "&:hover": {
      "& img": {
        opacity: 1,
      },
    },

    "& .report-description": {
      fontSize: 13,
      color: colors.primaryDark,
      fontWeight: 400,
      width: 350,
      lineHeight: 1.5,
      "white-space": "pre-wrap",
    },
    // "& img": {
    //   color: colors.primaryDark,
    //   cursor: "pointer",
    //   opacity: 0,
    // },
  },
}));
