import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";

export const InboxListContainer = styled("div")(() => ({
  "&.root": {
    height: "100%",
    overflowY: "auto",
    position: "relative",
    padding: 16,
    backgroundColor: "#F6FBFF",
    zIndex: 0,
  },
  "& .contentContainer": {
    marginTop: 16,
    width: "100%",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    backgroundColor: "white",
    position: "relative",
    height: "calc(100vh - 118px)",
    display: "flex",
    flexDirection: "column",
  },
}));

export const InboxTableContainer = styled("div")(() => ({
  "&.contentListInbox": {
    display: "flex",
    height: "100%",
    "& .tableContainer": {
      marginRight: 4,
      overflow: "auto",
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.thumbScrollColor,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        height: 8,
        marginRight: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track:vertical": {
        marginTop: 52,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 5,
        marginLeft: 3,
      },
    },
  },
}));
