import React, { useCallback, useEffect } from "react";
import { useCompanyId } from "../../hooks";
import {
  isSuperAdmin,
  isTradeDashEmployee,
  listenToData,
} from "../../helpers/helpers";
import { useUser } from "../../hooks/user";
import { GENERAL_PERMISSION_VALUE } from "../../helpers/constants";
import { useRolesPermissionList } from "../../hooks/permissions";
import { TabRoutes } from "./Tabs";
import { updateDoc } from "firebase/firestore";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

function AdminLayOut() {
  const user = useUser();
  const tradedashEmployee = isTradeDashEmployee(user);
  const superAdminUser = isSuperAdmin(user);
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const params = useParams();
  const rolePermissions = useRolesPermissionList();
  const allPermission = tradedashEmployee || superAdminUser;
  const dispatch = useDispatch();

  const listenToSalesOrderTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.SALES_ORDER_TEMPLATES],
    })(dispatch)
  );
  const listenToPOTemplates = useCallback(() =>
    listenToData({
      path: [dbTables.COMPANIES, companyId, dbTables.PURCHASE_ORDER_TEMPLATES],
    })(dispatch)
  );

  useEffect(() => {
    if (companyId) {
      listenToSalesOrderTemplates();
      listenToPOTemplates();
    }
  }, [companyId]);

  useEffect(() => {
    if (
      params.companyId &&
      tradedashEmployee &&
      companyId !== params.companyId &&
      params.companyId !== "settings"
    ) {
      updateDoc(user.ref, { observedCompany: params.companyId });
    }
  }, [params.companyId]);

  useEffect(() => {
    if (!params.companyId && (rolePermissions.size > 0 || allPermission)) {
      let isAllowedProject =
        rolePermissions.get(GENERAL_PERMISSION_VALUE.SETTINGS_PROJECTS) || {};
      isAllowedProject = isAllowedProject.enabled || allPermission;
      let tabPath;
      if (isAllowedProject) {
        tabPath = "projects";
      } else {
        const allowedRoute = TabRoutes.find((route) => {
          const permission = rolePermissions.get(route.permission) || {};
          return permission.enabled;
        }) || { url: "notFound" };
        tabPath = allowedRoute.url;
      }
      navigate(`/app/admin/${companyId || "settings"}/${tabPath}`, {
        replace: true,
      });
    }
  }, [rolePermissions]);

  return <Outlet />;
}

export default AdminLayOut;
