import React, { useState } from "react";
import { sortObjectsBy } from "../../helpers/helpers";
import { useCompanyId } from "../../hooks";
import { useCompanyUsers } from "../../hooks/user";
import { MultiUserPickerPopoverStyled } from "../DatePicker/styles";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { getUserAvatar } from "../../helpers/users";

function PermissionsToNofity({ item, isOpen, onSubmit }) {
  const companyId = useCompanyId();

  const companyUsers = useCompanyUsers({
    id: companyId,
    showBotUser: false,
    showInactiveUsers: false,
  });
  const [permissions, setPermissions] = useState(item.permissionUsers);

  return (
    <MultiUserPickerPopoverStyled
      open={!!isOpen}
      onClose={() =>
        onSubmit({ item: { ...item, permissionUsers: permissions } })
      }
      anchorEl={isOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{ paper: "multiUserPapper" }}
      className="multiUserPickerPopoverContainer"
    >
      <ul className="listContainer">
        {companyUsers.sort(sortObjectsBy("displayName", false)).map((user) => (
          <li
            key={user.id}
            className="listItem"
            onClick={(ev) => {
              setPermissions((oldPermission) => ({
                ...oldPermission,
                [user.id]: !oldPermission[user.id],
              }));
              ev.preventDefault();
              ev.stopPropagation();
            }}
          >
            <CustomCheckbox
              checked={permissions[user.id]}
              id={user.id + "-checkbox-notification-user"}
              iconSize={20}
              styles={{ padding: 0, marginBottom: -1 }}
            />

            {getUserAvatar({
              user: user,
              styles: {
                width: 24,
                height: 24,
                fontSize: 13,
                outline: "2px solid #000",
              },
            })}
            <span>{user.displayName}</span>
          </li>
        ))}
      </ul>
    </MultiUserPickerPopoverStyled>
  );
}

export default PermissionsToNofity;
