import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import createImagePlugin from "@draft-js-plugins/image";
import createAlignmentPlugin from "@draft-js-plugins/alignment";
import createFocusPlugin from "@draft-js-plugins/focus";
import createResizeablePlugin from "@draft-js-plugins/resizeable";
import createBlockDndPlugin from "@draft-js-plugins/drag-n-drop";
import createMentionPlugin from "@draft-js-plugins/mention";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import {
  convertToRaw,
  RichUtils,
  AtomicBlockUtils,
} from "draft-js";
import { InlineStyleControls } from "./Controller/InlineStyleControls";
import { BlockStyleControls } from "./Controller/BlockStyleControls";
import { FontStyleControls } from "./Controller/FontStyleControls";
import { MentionControl } from "./Controller/MentionControl";
import { NoteEditorStyled } from "./style";
import EmojiImage from "../../assets/toolbarIcon/Emoji.svg";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/focus/lib/plugin.css";
import "@draft-js-plugins/alignment/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";

import {
  priorityList,
  EntryComponent,
  insertFieldEntry,
  mapKeyToEditorCommand,
  ShipmentMentions,
  UserMention,
  DocumentMentions,
  customStyleMap,
  getBlockStyle,
  changeInlineStyle,
  changeNameDocumentByOrder,
} from "./ComponentHelper";
import Dropzone from "react-dropzone";
import { storage } from "../../firebase";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { dbTables } from "../../api/types/dbTables";
import { ColorStyleControls } from "./Controller/ColorStyleControls";
import { cx } from "@emotion/css";
import { Box } from "@mui/material";
import { NOTES_SCOPE } from "../../helpers/constants";

const linkifyPlugin = createLinkifyPlugin();
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const emojiPlugin = createEmojiPlugin({
  selectButtonContent: (
    <img className="emoji-button" src={EmojiImage} alt="emoji" />
  ),
  useNativeArt: true,
  priorityList,
});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

const mentionPlugin = createMentionPlugin({
  entityMutability: "IMMUTABLE",
  mentionComponent: UserMention,
  supportWhitespace: true,
});
const userPlugin = mentionPlugin;

export default function RichText({
  userMentions = [],
  editorState,
  setEditorState,
  shipmentMentions = [],
  saveNote = () => {},
  typeNote = NOTES_SCOPE.SALES_ORDER,
  setHasMention = () => {},
  note,
  companyId,
  setIsLoading = () => {},
  urlUploaded = [],
  setUrlUploaded = () => {},
  handleDocumentComponent = () => {},
  isDocumentOpen,
  documentData,
  purchaseOrders = [],
  shipments = [],
  hasFlagPermission = false,
}) {
  const editorRef = useRef(null);
  const [openFontList, setOpenFontList] = useState(false);
  const [openColorList, setOpenColorList] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [openShipment, setOpenShipment] = useState(false);
  const [searchShipment, setSearchShipment] = useState("");
  const [searchUser, setSearchUser] = useState("");

  const openListMention = useCallback((open, setOpenList) => {
    setOpenList(open);
  }, []);

  useEffect(() => {
    setTimeout(() => focus(), 400);
  }, [typeNote]);

  useEffect(() => {
    if ((documentData.type, documentData.withButton)) {
      const renamedDocument = changeNameDocumentByOrder({
        documentData: {
          ...documentData,
          id: documentData.isVersion
            ? documentData.id.slice(0, -1)
            : documentData.id,
        },
        shipments,
        purchaseOrders,
      });
      const newState = insertFieldEntry({
        editorState,
        documentData: renamedDocument,
      });
      setEditorState(newState);
    }
  }, [documentData]);

  const focus = () => editorRef.current.focus();

  const onChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const entityKeys = convertToRaw(contentState).entityMap;
    let hasMention = false;

    for (const property in entityKeys) {
      const mention = entityKeys[property] || {};
      hasMention = hasMention || mention.type === "mention";
    }
    setHasMention(hasMention);
    setEditorState(editorState);
  };

  function toggleBlockType(blockType) {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }

  function toggleInlineStyle(type) {
    focus();
    const newState = editorState;
    const inlineStyle = newState.getCurrentInlineStyle();
    const inlineState = changeInlineStyle({ newState, type, inlineStyle });
    onChange(inlineState);
  }

  const onSearchUser = useCallback(({ value = "" }) => {
    const parseValue = value.toLowerCase();
    setSearchUser(parseValue);
  }, []);

  const onSearchShipment = useCallback(({ value }) => {
    const parseValue = (value || "").toLowerCase();
    setSearchShipment(parseValue);
  }, []);

  const userSuggestions = userMentions.filter((user = {}) => {
    const text = `${user.dataType === "user" ? user.suffix : user.prefix}`;
    return text.toLowerCase().includes(searchUser || "");
  });

  const shipmentSuggestions = shipmentMentions.filter((shipment = {}) => {
    const text = `${shipment.prefix}${shipment.suffix}`;
    return text.toLowerCase().includes(searchShipment || "");
  });

  const { ShipmentComponentMention, shipmentPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionComponent: ShipmentMentions,
      mentionTrigger: "/",
      supportWhitespace: true,
    });
    return {
      shipmentPlugin: mentionPlugin,
      ShipmentComponentMention: mentionPlugin.MentionSuggestions,
    };
  }, []);

  const { DocumentComponentMention, documentMentionPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionComponent: DocumentMentions,
      mentionTrigger: "*_",
      supportWhitespace: true,
    });
    return {
      documentMentionPlugin: mentionPlugin,
      DocumentComponentMention: mentionPlugin.MentionSuggestions,
    };
  }, []);

  const uploadImage = async (file) => {
    setIsLoading(true);
    let metadata = {
      type: "image/jpeg",
    };
    const renameFile = new File([file], `${moment().valueOf()}.jpg`, metadata);
    const storageRef = await ref(
      storage,
      `${dbTables.COMPANIES}/${companyId}/${dbTables.ACTIVITIES}/${note.id}/${renameFile.name}`
    );
    await uploadBytesResumable(storageRef, renameFile);
    const url = await getDownloadURL(storageRef);
    setUrlUploaded([...urlUploaded, url]);
    const entityKey = editorState
      .getCurrentContent()
      .createEntity("IMAGE", "INMUTABLE", {
        src: url,
        width: 80,
      })
      .getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );
    setEditorState(newEditorState);
    setIsLoading(false);
  };

  const contentState = editorState.getCurrentContent();
  const firstElement = contentState.getBlockMap().first().getType();

  const UserComponentMention = mentionPlugin.MentionSuggestions;
  return (
    <NoteEditorStyled
      className={cx("richTextBoxContainer", "typeNote-" + typeNote)}
    >
      <div
        className={cx("RichEditor-editor", `${typeNote}Style`)}
        onClick={focus}
        id="DraftEditor-root"
        onDrop={(ev) => {
          if (documentData && documentData.type) {
            const renamedDocument = changeNameDocumentByOrder({
              documentData: {
                ...documentData,
                id: documentData.isVersion
                  ? documentData.id.slice(0, -1)
                  : documentData.id,
              },
              shipments,
              purchaseOrders,
            });
            const newState = insertFieldEntry({
              editorState,
              documentData: renamedDocument,
            });
            setEditorState(newState);
            ev.stopPropagation();
          }
        }}
      >
        <Dropzone
          onDrop={(accept) => {
            const [fileAccept] = accept;
            if (fileAccept) {
              uploadImage(fileAccept);
            }
          }}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
          }}
          maxFiles={1}
          multiple={false}
          noClick
          noKeyboard
          style={{ height: "100%" }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: "dropRichText",
              })}
              style={{ height: "100%" }}
            >
              <Editor
                customStyleMap={customStyleMap}
                blockStyleFn={getBlockStyle}
                editorState={editorState}
                handlePastedFiles={(files) => {
                  const currentFile = files[0];
                  if (currentFile) {
                    uploadImage(files[0]);
                  }
                }}
                handleKeyCommand={() => {}}
                keyBindingFn={(event) => {
                  mapKeyToEditorCommand({
                    editorState,
                    event,
                    saveNote,
                    toggleBlockType,
                    toggleInlineStyle,
                  });
                }}
                onChange={onChange}
                placeholder={
                  firstElement === "unstyled" ? "Type to enter note..." : ""
                }
                ref={editorRef}
                stripPastedStyles={true}
                plugins={[
                  userPlugin,
                  shipmentPlugin,
                  documentMentionPlugin,
                  blockDndPlugin,
                  focusPlugin,
                  alignmentPlugin,
                  resizeablePlugin,
                  imagePlugin,
                  emojiPlugin,
                  linkifyPlugin,
                ]}
              />
              <UserComponentMention
                open={openUser}
                onOpenChange={(open) => openListMention(open, setOpenUser)}
                suggestions={userSuggestions}
                onSearchChange={onSearchUser}
                entryComponent={EntryComponent}
              />
              <ShipmentComponentMention
                open={openShipment}
                onOpenChange={(open) => setOpenShipment(open)}
                suggestions={shipmentSuggestions}
                onSearchChange={onSearchShipment}
                entryComponent={EntryComponent}
              />
              <DocumentComponentMention
                open={false}
                onOpenChange={() => {}}
                suggestions={[]}
                onSearchChange={() => {}}
                entryComponent={EntryComponent}
              />
              <AlignmentTool />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
      </div>

      <Box className="toolbar">
        <FontStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
          openList={openFontList}
          handleOpenList={setOpenFontList}
          classes="headline"
          editorRef={editorRef}
        />
        <ColorStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
          openList={openColorList}
          handleOpenList={setOpenColorList}
          classes="headline"
          editorRef={editorRef}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <MentionControl
          editorState={editorState}
          onChange={onChange}
          classes="headline"
          editorRef={editorRef}
          uploadImage={uploadImage}
          EmojiSelect={EmojiSelect}
          isDocumentOpen={isDocumentOpen}
          handleDocumentComponent={handleDocumentComponent}
          hasFlagPermission={hasFlagPermission}
        />
        <EmojiSuggestions />
      </Box>
    </NoteEditorStyled>
  );
}
