import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { cx } from "@emotion/css";

function NavItem({ item, disabled, style = {}, path }) {
  const location = useLocation();
  const url = { pathname: `${path}/${item.id}` };
  const active = location.pathname.includes(item.id);
  return (
    <NavLink
      className={disabled ? "disabled" : cx("container", { active: active })}
      to={disabled ? "#" : url}
      id={`nav-link-${item.id}`}
      style={{ ...style, display: "flex", alignItems: "center" }}
    >
      {`${item.name}  ${item.number && "(" + item.number + ")"}`}
    </NavLink>
  );
}

export default NavItem;
