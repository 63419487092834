import React, { useState } from "react";
import { CreationTaksButtonStyle } from "./styles";
import "jspdf-autotable";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { useCompanyId } from "../../hooks";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../hooks/salesOrders";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

function CreationTaskButton({ setAttentionModalText, currentFactory }) {
  const companyId = useCompanyId();
  const currentSalesOrder = useCurrentSalesOrder({});
  const currentPO = useCurrentPurchaseOrder();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    if (currentFactory.inactive) {
      setAttentionModalText({
        title: "Attention",
        isOpen: true,
        message:
          "This vendor was marked as inactive, make sure the vendor is active to proceed",
        openTemplateModal: false,
      });
      setLoading(false);
      return;
    }
    try {
      const functions = getFunctions();
      const buildingTaksEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "buildingTasks",
          env: globalEnvironment,
          params: "/purchaseOrder",
        })
      );
      const result = await buildingTaksEndpoint({
        companyId,
        salesOrderId: currentSalesOrder.id,
        purchaseOrderId: currentPO.id,
      });
      const data = result.data;
      if (data.status === 400 || data.error) {
        setAttentionModalText({
          title: "Attention",
          isOpen: true,
          message: data.message || "ERROR",
          openTemplateModal:
            data.message ===
              "Vendor is missing PO template binding to the current project" ||
            data.message ===
              "Vendor is missing PQ template binding to the current project",
        });
      } else {
        setAttentionModalText({
          title: "",
          isOpen: true,
          message: "The tasks were created successfully",
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("error");
      setAttentionModalText({
        isOpen: true,
        message: "Error",
        title: "Attention",
      });
      setLoading(false);
    }
  };

  return (
    <CreationTaksButtonStyle
      className={"createTaskButton"}
      onClick={onClick}
      disabled={loading}
      id={`creationTaskOutdated`}
    >
      <span>Tasks are pending</span>
      {loading && <LoadingBackdrop size={20} />}
    </CreationTaksButtonStyle>
  );
}

export default CreationTaskButton;
