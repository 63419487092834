/**
 * Nav Menu Item
 */
import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// intl messages
import IntlMessages from "../../util/IntlMessages";
import { usePermissions } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { useCompanies, useCompanyId } from "../../hooks";
import { useUser } from "../../hooks/user";
import { REPORTS_VIEW } from "../../actions/types";
import { reduxState } from "../../api/types/dbTables";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { hasFeatureFlagPermission } from "../../helpers/helpers";
import { Badge, ListItemButton } from "@mui/material";
import { cx } from "@emotion/css";
import { NavigationLinkStyled } from "./styles";
import { useInboxSalesOrders } from "../../hooks/salesOrders";

function NavMenuItem({ menu }) {
  const user = useUser() || {};
  const location = useLocation();
  const companyId = useCompanyId();
  const [company = {}] = useCompanies();
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags({ companyId });
  const salesOrders = useInboxSalesOrders();
  const permissions = usePermissions();
  if (!user.active) {
    return null;
  }
  const checkFeatureFlag = menu.permissionFlag
    ? hasFeatureFlagPermission({
        featureFlags,
        user,
        featureFlagName: menu.permissionFlag,
      })
    : true;
  if (!checkFeatureFlag && user.role !== userTypes.TRADEDASH_EMPLOYEE) {
    return null;
  }

  if (
    !verifyPermissions(permissions, menu.permission_name) &&
    menu.hiddenNoPermission
  ) {
    return null;
  }

  function verifyPermissions(permissions, itemId) {
    if (user.role === userTypes.TRADEDASH_EMPLOYEE) {
      if (!user.observedCompany && itemId === "settings") {
        return true;
      } else if (user.observedCompany) {
        return true;
      } else {
        return false;
      }
    }
    if (!company.enabled) return false;
    if (user.role === userTypes.SUPER_ADMIN) return true;
    return permissions.some(
      (permission) => permission.enabled && permission.id === itemId
    );
  }

  let path = menu.path;
  if (
    menu.path.includes("/app/report") &&
    window.location.href.includes("/app/report")
  ) {
    path = window.location.href;
  }
  return (
    <ListItemButton
      disabled={!verifyPermissions(permissions, menu.permission_name)}
      style={{
        flexDirection: "column",
        display: "flex",
        padding: 0,
      }}
      component="li"
    >
      <NavigationLinkStyled
        className={cx(
          "navigation-link-container",
          location.pathname.includes(menu.path) ? "active" : ""
        )}
        to={path}
        onClick={() => {
          if (menu.menu_title === "Reports") {
            dispatch({
              type: REPORTS_VIEW,
              table: reduxState.REPORTS_VIEW,
              payload: {
                open: true,
              },
            });
          }
        }}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          max={9}
          badgeContent={menu.badge && salesOrders.length}
          classes={{ badge: "small-badge" }}
        >
          {menu.svgIcon && <div>{menu.menu_icon}</div>}
          {!menu.svgIcon && (
            <img
              id="sidebar-menu-icons-img"
              width={28}
              height={28}
              src={menu.menu_icon}
              style={{ objectFit: "center" }}
              alt={"menu_icon"}
            />
          )}
        </Badge>
        <span
          style={{ textAlign: "center", fontSize: 10, marginTop: 4 }}
          className="menu"
        >
          <IntlMessages id={menu.menu_title} />
        </span>
        {location.pathname.includes(menu.path) && <div className="blue-bar" />}
      </NavigationLinkStyled>
    </ListItemButton>
  );
}

export default NavMenuItem;
