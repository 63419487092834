import React, { useState } from "react";
import {
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  PO_STATUS,
  screenPath,
} from "../../../helpers/constants";
import { getFactoryByField } from "../../../helpers/helpers";
import { WarningVendorIcon } from "../../../helpers/iconSvgPath";
import { orderType } from "../../../helpers/salesOrder";
import { useCompanyId, useQueryParams } from "../../../hooks";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../../hooks/salesOrders";
import TooltipTD from "../../Tooltip/TooltipTD";
import { POMetadataContainterStyled } from "../styles";
import IntlMessages from "../../../util/IntlMessages";
import { colors } from "../../../assets/jss/variables";
import { useFactories } from "../../../hooks/factories";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import { useUser } from "../../../hooks/user";

import POMetadataInfo from "./POMetadataInfo";
import POSKUSection from "./POSKUSection";
import { cx } from "@emotion/css";
import { NavLink, useNavigate } from "react-router-dom";
import POBadge from "../../Badge/POBadge";
import CreationTaskButton from "../../Buttons/AddTaskToPO";
import AttentionModal from "../../Modal/AttentionModal";

function PurchaseOrderMetadata({ vendorPermission }) {
  const currentSalesOrder = useCurrentSalesOrder({});
  const navigate = useNavigate();
  const purchaseOrder = useCurrentPurchaseOrder();
  const queryParams = useQueryParams();
  const user = useUser() || {};
  const companyId = useCompanyId();
  const factories = useFactories();
  const isAllowed = useIsAllowedFunction();
  const [attentionModalText, setAttentionModalText] = useState({
    isOpen: false,
    message: "",
    title: "",
  });
  if (queryParams.toVerify) {
    return "";
  }

  if (!vendorPermission) {
    return (
      <div className="blockContainer">
        <p className="blockText">
          {purchaseOrder.id && PERMISSION_TEXT.VENDOR}
        </p>
      </div>
    );
  }

  return (
    <div className={"purchase-order-info"} id="purchase-order-info">
      {attentionModalText.isOpen && (
        <AttentionModal
          title={attentionModalText.title}
          description={attentionModalText.message}
          isOpen={attentionModalText.isOpen}
          onClick={() => {
            if (attentionModalText.openTemplateModal) {
              navigate(
                `/app/factories/${purchaseOrder.factoryId}?bindPO=${currentSalesOrder.templateId}`
              );
            }
            setAttentionModalText({ isOpen: false });
          }}
          cancellable={attentionModalText.openTemplateModal}
          acceptBlue
          cancelText="Ok"
          confirmationText={
            attentionModalText.openTemplateModal ? "Open vendor template" : "Ok"
          }
          onClose={() => setAttentionModalText("")}
        />
      )}

      {purchaseOrder.id && (
        <POMetadataContainterStyled
          className={cx(
            "poContainer",
            `content-card-content purchase-order-content`
          )}
        >
          <div className={"poTitleContent"}>
            <span style={{ color: colors.darkGray, addingRight: 8 }}>
              <IntlMessages
                id={
                  purchaseOrder.type === orderType.PURCHASE_QUOTE
                    ? "order.type.purchase.quote"
                    : "order.type.purchase.order"
                }
              />
              :
            </span>
            <span>
              <POBadge
                number={purchaseOrder.number}
                className="purchase-order-badge-white"
              />
            </span>
            <span style={{ color: colors.darkGray }}>Vendor:</span>
            {purchaseOrder.isOutdated && (
              <TooltipTD
                label={
                  <span>
                    The template for this vendor is pending validation
                    <br /> since the last change to the Sales Order Template
                  </span>
                }
                style={{ height: 18 }}
              >
                <WarningVendorIcon
                  width={21}
                  height={18}
                  style={{ marginBottom: 1 }}
                />
              </TooltipTD>
            )}
            <NavLink
              className={"vendorLink"}
              to={`/app/${screenPath.VENDOR}/${purchaseOrder.factoryId}`}
            >
              {getFactoryByField({
                factories,
                factoryId: purchaseOrder.factoryId,
                field: "number",
              })}
            </NavLink>
            {getFactoryByField({
              factories,
              factoryId: purchaseOrder.factoryId,
              field: "name",
            })}
            {purchaseOrder.status !== PO_STATUS.VOIDED &&
              purchaseOrder.isOutdated &&
              isAllowed(
                GENERAL_PERMISSION_VALUE.MODIFY_FACTORIES_TEMPLATES
              ) && (
                <CreationTaskButton
                  setAttentionModalText={setAttentionModalText}
                  currentFactory={factories.find(
                    (factory) => factory.id === purchaseOrder.factoryId
                  )}
                />
              )}
          </div>
          <POMetadataInfo />
          <POSKUSection
            purchaseOrder={purchaseOrder}
            companyId={companyId}
            user={user}
            key={"SectionPoSKU"}
          />
        </POMetadataContainterStyled>
      )}
    </div>
  );
}

export default PurchaseOrderMetadata;
