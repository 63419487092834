import React, { useState, useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import AttentionModal from "../Modal/AttentionModal";
import {
  GENERAL_PERMISSION_VALUE,
  regexEmailValidator,
} from "../../helpers/constants";
import FieldByProperty from "../../components/Inputs/FieldByProperty";
import { updateDoc } from "firebase/firestore";
import { CustomerInfoStyled } from "./styles";
import AutocompleteCategory from "../Categories/AutocompleteCategory";
import { useCategories, useCategoryItems } from "../../hooks";
import { sortObjectsBy } from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { cx } from "@emotion/css";

const CUSTOMER_FIELD = {
  NUMBER: "number",
  INACTIVE: "inactive",
  NAME: "name",
  MAIN_CONTACT: "mainContact",
  JOB_TITLE: "jobTitle",
  EMAIL: "email",
  CELL_PHONE: "cellPhone",
  LATE_PENALTY_TERMS: "latePenaltyTerms",
  TERMS: "terms",
  CATEGORIES: "categories",
  NOTES: "notes",
};

const customerFields = [
  CUSTOMER_FIELD.NUMBER,
  CUSTOMER_FIELD.INACTIVE,
  CUSTOMER_FIELD.NAME,
  CUSTOMER_FIELD.MAIN_CONTACT,
  CUSTOMER_FIELD.JOB_TITLE,
  CUSTOMER_FIELD.EMAIL,
  CUSTOMER_FIELD.CELL_PHONE,
  CUSTOMER_FIELD.LATE_PENALTY_TERMS,
  CUSTOMER_FIELD.TERMS,
  CUSTOMER_FIELD.CATEGORIES,
  CUSTOMER_FIELD.NOTES,
];

function validateEmail(email) {
  return regexEmailValidator.test(String(email).toLowerCase());
}

function CustomerInfo({
  customer = {},
  customers = [],
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateSummary = () => {},
}) {
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const categoryItems = useCategoryItems({});
  const categories = useCategories();
  const isAllowed = useIsAllowedFunction();
  const [selectedCategories, setSelectedCategories] = React.useState(
    categoryItems.filter((category) =>
      customer.categories?.includes(category.id)
    ) || []
  );

  useEffect(() => {
    setCurrentCustomer(customer);
  }, [customer]);

  function handleInactiveCustomer(checked, property) {
    setCurrentCustomer({ ...currentCustomer, [property]: checked });
    updateDoc(customer.ref, { [property]: checked, triggerType: property });
    handleUpdateSummary({ ...customer, [property]: checked });
  }

  function handleSaveCustomer(property) {
    if (currentCustomer[property] === customer[property]) {
      return;
    }
    if (
      property === "email" &&
      currentCustomer[property] !== "" &&
      !validateEmail(currentCustomer[property])
    ) {
      setOpenAttentionModal(true);
      setDescriptionModal("Invalid email format, please choose a valid email");
      setCurrentCustomer({
        ...currentCustomer,
        [property]: customer[property] || "",
      });
    } else if (property === "notes") {
      updateDoc(customer.ref, {
        [property]: currentCustomer[property],
        triggerType: property,
      });
    } else {
      currentCustomer[property] = currentCustomer[property]
        .replace(/\s\s+/g, " ")
        .trim();
      updateDoc(customer.ref, {
        [property]: currentCustomer[property],
        triggerType: property,
      });
    }
  }

  function handleChange(event, property) {
    setCurrentCustomer({ ...currentCustomer, [property]: event.target.value });
  }

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          title="Attention"
          description={descriptionModal}
          isOpen={openAttentionModal}
          onClick={() => setOpenAttentionModal(false)}
          onClose={() => setOpenAttentionModal(false)}
        />
      )}
      <CustomerInfoStyled className="container">
        {customers.length > 0 && (
          <React.Fragment>
            <div className="documents-header">
              <IntlMessages id={"general.basicinfo"} />
            </div>
            <ul>
              {customerFields.map((property, index) => {
                if (property === CUSTOMER_FIELD.CATEGORIES) {
                  if (
                    !isAllowed(
                      GENERAL_PERMISSION_VALUE.VISIBLE_IN_CUSTOMER_PAGE
                    )
                  ) {
                    return null;
                  }
                  return (
                    <li
                      key={currentCustomer.id + property}
                      className={"infoField"}
                    >
                      <span className="fieldName">
                        {<IntlMessages id={`customer.${property}`} />}:
                      </span>
                      <AutocompleteCategory
                        options={categoryItems}
                        value={selectedCategories.sort(
                          sortObjectsBy("name", false)
                        )}
                        onChange={(event, newValue) => {
                          setSelectedCategories(newValue);
                        }}
                        onBlur={() => {
                          updateDoc(customer.ref, {
                            categories: selectedCategories.map(
                              (category) => category.id
                            ),
                          });
                        }}
                        selectedOptions={selectedCategories}
                        categories={categories}
                        styles={{
                          display: "block",
                          width: "70%",
                        }}
                        onCreateCategory={({ categoryItem }) => {
                          const selectedCategoriesCpy = [
                            ...selectedCategories,
                            categoryItem,
                          ];
                          setSelectedCategories(selectedCategoriesCpy);
                          updateDoc(customer.ref, {
                            categories: selectedCategoriesCpy.map(
                              (category) => category.id
                            ),
                          });
                        }}
                        canAssign={isAllowed(
                          GENERAL_PERMISSION_VALUE.ASIGN_CATEGORIES
                        )}
                        canUnassign={isAllowed(
                          GENERAL_PERMISSION_VALUE.UN_ASSIGN_CATEGORIES
                        )}
                        canCreateCategory={isAllowed(
                          GENERAL_PERMISSION_VALUE.CREATE_CATEGORIES
                        )}
                      />
                    </li>
                  );
                } else {
                  return (
                    <li
                      className={cx("infoField", {
                        initStyle: index === 0,
                      })}
                      key={currentCustomer.id + property}
                    >
                      <span className="fieldName">
                        {<IntlMessages id={`customer.${property}`} />}:
                      </span>
                      <FieldByProperty
                        property={property}
                        currentItem={currentCustomer}
                        allowedKey={"modify_customers"}
                        isReadOnly={isReadOnly}
                        handleSave={handleSaveCustomer}
                        handleChange={handleChange}
                        handleInactive={handleInactiveCustomer}
                        handleReadOnlyModal={handleReadOnlyModal}
                        type={
                          property === CUSTOMER_FIELD.TERMS ? "number" : "text"
                        }
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </React.Fragment>
        )}
      </CustomerInfoStyled>
    </React.Fragment>
  );
}

export default CustomerInfo;
