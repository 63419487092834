import { colors } from "../../assets/jss/variables";
import { Button, styled } from "@mui/material";

export const ThreePanelButtonStyled = styled("div")({
  "&.buttonContainerThreePanel": {
    display: "flex",
    borderRadius: 3,
    height: 22,
    width: 22,
    cursor: "pointer",
    marginRight: "auto",
    marginLeft: 5,
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: colors.backgroundWhite,
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& line": {
        strokeWidth: 2.5,
      },
    },
  },
});

export const SKUContainerButtonStyled = styled("div")({
  "&.itemsButtonsModal": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 5,
    marginTop: 22,
    gap: 16,
    paddingRight: 9,
    "& .purchaseOrder": {
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
    },
    "& .salesOrder": {
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
    },
    "& .shipment": {
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
    },
  },

  "&.itemsButtons": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 5,
    paddingRight: 12,
    gap: 16,
    "& .focus": {
      background: "white !important",
    },
    "& .purchaseOrder": {
      "&:hover": {
        background: colors.backgroundBlue,
      },
      "&:active": {
        background: colors.backgroundBlueAction,
      },
    },
    "& .salesOrder": {
      "&:hover": {
        background: colors.backgroundGreen,
      },
      "&:active": {
        background: colors.backgroundGreenAction,
      },
    },
    "& .shipment": {
      "&:hover": {
        background: colors.backgroundPink,
      },
      "&:active": {
        background: colors.backgroundPinkAction,
      },
    },
  },

  "& .iconContainer": {
    cursor: "pointer",
    height: 36,
    width: 36,
    minWidth: 36,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    "&:hover": {
      "& .expandTableIcon": {
        "& path": {
          strokeWidth: 1,
          stroke: colors.primaryDark,
        },
      },
      "& .copyIcon": {
        "& path": {
          strokeWidth: 2.5,
        },
      },
      "& .expandRow": {
        "& path": {
          strokeWidth: 2.5,
        },
      },
      "& .csvIcon": {
        "& .arrow": {
          stroke: colors.primaryDark,
          strokeWidth: 1,
        },
        "& .arrowContainer": {
          strokeWidth: 3,
        },
      },
    },
    "&:active": {
      transform: "scale(1)",
      "& .expandTableIcon": {
        "& path": {
          strokeWidth: 2,
          stroke: colors.primaryDark,
        },
      },
      "& .copyIcon": {
        "& .item2": {
          transform: "translate(2px, 2px)",
        },
        "& path": {
          strokeWidth: 3.2,
        },
      },
      "& .expandRow": {
        "& .item1": {
          transform: "translateY(1px)",
        },
        "& .item3": {
          transform: "translateY(-1px)",
        },
        "& path": {
          strokeWidth: 3,
        },
      },
      "& .csvIcon": {
        "& .arrow": {
          transform: "translateY(3px)",
        },
      },
    },
  },

  "& .searchIcon": {
    width: 36,
    height: 36,
    borderRadius: 5,
    padding: 0,
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important",
    cursor: "pointer",
    background: "unset",
    "&:hover": {
      background: `${colors.backgroundWhite}`,
    },
    "&:active": {
      background: `${colors.backgroundWhiteAction} `,
    },
  },
});

export const CreationTaksButtonStyle = styled(Button)({
  "&.createTaskButton": {
    background: "white",
    color: colors.primaryDark,
    border: "1px solid #25486d",
    fontSize: 12,
    fontWeight: 500,
    padding: 5,
    borderRadius: 4,
  },
});
