import React, { useContext } from "react";
import { getDiamondStatus } from "../../../helpers/ScreenComponetHelper/componetHelper";
import { cx } from "@emotion/css";
import TooltipTD from "../../../components/Tooltip/TooltipTD";
import { NavLink } from "react-router-dom";
import {
  getDashboardSearchPath,
  getProgressValue,
} from "../../../helpers/helpers";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../../components/PurchaseOrderDashboard/sections/helpers";
import TimeLineIcon from "../../../assets/flag-icons/timeline-icon-v1.svg";
import { TaskGroupedByProjectContext } from "../TaskByProject/TaskByProjectContext";
import { getFilterTasksByStatus } from "../TaskScreenHelper";
import { CircularStaticProgress } from "../../../components/CircularProgress/CircularStaticProgress";
import { colors } from "../../../assets/jss/variables";
import SalesOrderBadge from "../../../components/Badge/SalesOrderBadge";
import { screenPath } from "../../../helpers/constants";

function SalesOrderInfoRow({
  salesOrder,
  setOpenTimeLine = () => {},
  filters = {},
  purchaseOrder,
  companyUsers = [],
}) {
  const { taskByProjectContext } = useContext(TaskGroupedByProjectContext);
  const { tasks = {} } = taskByProjectContext;
  const filteredTasks =
    getFilterTasksByStatus({
      filters: filters,
      tasks: tasks[salesOrder.id] || [],
      companyUsers,
    }) || [];
  const totalTasks = filteredTasks.length;
  const { id: purchaseOrderId = "", shipmentIds = [] } = purchaseOrder;
  return (
    <div className="orderTaskRow">
      <div className={"cellRow"}>
        <img
          className="timeline-icon"
          src={TimeLineIcon}
          onClick={() => setOpenTimeLine(true)}
          alt="timeline1"
        />
      </div>
      <div className="cellRow" style={{ padding: "3px 0px 0px 0px" }}>
        <CircularStaticProgress
          label={`${salesOrder.completedTasks}/${salesOrder.totalTasks} Tasks`}
          value={+getProgressValue(salesOrder)}
          thickness={16}
          size={18}
          style={{ transform: "rotate(270deg)", color: colors.primaryDark }}
          backgroundColor="#B9B9B9"
          withBorder={7}
        />
      </div>

      <div className={cx("cellRow", "soNumber")}>
        {getDiamondStatus({
          diamondStatus: salesOrder.diamondStatus,
          itemStatus: salesOrder.status,
          className: "diamond",
          style: {
            width: "auto",
            height: 42,
            alignItems: "center",
            justifyContent: "center",
          },
        })}
        <NavLink
          to={getDashboardSearchPath({
            salesOrderId: salesOrder.id,
            purchaseOrderId: purchaseOrderId,
            shipmentId: shipmentIds[0],
            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
          })}
          className="navLink"
          id="poNumber"
        >
          <span className="span-description" style={{ textDecoration: "none" }}>
            <SalesOrderBadge
              number={salesOrder.number}
              className="sales-order-badge-white"
            />
          </span>
        </NavLink>
      </div>
      <div className={"cellRow"}>
        <TooltipTD label={salesOrder.customerName}>
          <NavLink
            to={`/app/${screenPath.CUSTOMER}/${salesOrder.customerId}`}
            className="navLink"
            id="list-view-content-customer"
          >
            <span className="span-description" style={{ maxWidth: 260 }}>
              {salesOrder.customerName}
            </span>
          </NavLink>
        </TooltipTD>
      </div>
      <div className={"cellRow"}>
        {totalTasks > 1 ? `${totalTasks} tasks` : `${totalTasks} task`}
      </div>
      <div className={"cellRow"} style={{ gridColumn: "span 6" }} />
    </div>
  );
}

export default SalesOrderInfoRow;
