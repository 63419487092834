import React, { useState } from "react";
import {
  getTagsLabel,
  getTheDate,
  sortObjectsBy,
  isTradeDashEmployee,
  getDashboardSearchPath,
} from "../../helpers/helpers";
import FilterTablePO from "./FilterTablePO";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { TableCell } from "@mui/material";
import numeral from "numeral";
import { removeFavorite, addFavorite } from "../../helpers/favoritesHelper";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import ProgressStage from "./ProgressStage";
import { NavLink } from "react-router-dom";
import TagsModal from "../../components/Modal/TagsModal";
import {
  BACKDROP_TYPE,
  generalPermissions,
  GENERAL_PERMISSION_VALUE,
  headerColumns,
  notAllowedToSee,
} from "../../helpers/constants";
import { dbTables } from "../../api/types/dbTables";
import { useDispatch } from "react-redux";
import { setBackdropPanelSection } from "../../actions/DataActions";
import { useStorage } from "../../hooks";
import { getTagStatus } from "../../helpers/salesOrder";
import FilterTableShipment from "./FilterTableShipment";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { colors } from "../../assets/jss/variables";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import {
  MainRowStyled,
  POSipmentRowStyle,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { cx } from "@emotion/css";
import {
  getDiamondStatus,
  getDisclouserArrow,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";

function GroupBySalesOrder({
  item = {},
  favorites = [],
  user,
  onUpdatePhasesWidth,
  headerStages = {},
  handleRemoveChargedSPTaskAndPOs,
  tags,
  onUpdateItem,
  headers,
  isThereProposalQuoteColumn,
  filters,
  onOpenAttentionModal,
  favoritesDB = [],
  setInternalLoading = () => {},
}) {
  const filterTag = filters.tag || [];
  const [expanded, setExpanded] = useState(false);
  const [SOTaskByPhases, setSOTaskByPhases] = useState({});
  const [POWithTaskByPhases, setPOWithTaskByPhases] = useState([]);
  const [shipmentWithTaskByPhases, setShipmentWithTaskByPhases] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [, setBackdropStorage] = useStorage("backdrop");
  const [, setTableItemStorage] = useStorage("tableItems");
  const handleBackdropStorage = (type) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: type,
      infoCard: true,
    });
  };

  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };

  const [purchaseOrderTableFilter, setPurchaseOrderTableFilter] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const [shipmentTableFilter, setShipmentTableFilter] = useState({
    sortedColumn: "number",
    orderBy: "ASC",
  });
  const isAllowed = useIsAllowedFunction();

  async function handleChargeSOTasksAndPOs(companyId, salesOrderId) {
    try {
      const functions = getFunctions();
      const callableEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview2",
          params: "/orderbyphasetasks",
          env: globalEnvironment,
        })
      );
      const result = await callableEndpoint({
        companyId,
        salesOrderId,
        userId: user.id,
      });

      const { SOTaskByPhases, purchaseOrders, shipments } = result.data;
      onUpdatePhasesWidth(SOTaskByPhases, purchaseOrders, item.id);
      setSOTaskByPhases(SOTaskByPhases);
      setPOWithTaskByPhases(
        purchaseOrders.sort(
          sortObjectsBy(
            purchaseOrderTableFilter.sortedColumn,
            purchaseOrderTableFilter.orderBy === "ASC" ? true : false
          )
        )
      );
      setShipmentWithTaskByPhases(
        shipments.sort(
          sortObjectsBy(
            shipmentTableFilter.sortedColumn,
            shipmentTableFilter.orderBy === "ASC" ? true : false
          )
        )
      );
      setInternalLoading(false);
      setTimeout(() => {
        setLoading(false);
        setExpanded(true);
      }, 1000);
    } catch (error) {
      console.log("Error ->", error);
      setLoading(false);
      setInternalLoading(false);
      setExpanded(!expanded);
    }
  }

  function handleUpdatePOItem(item) {
    let POWithTaskByPhasesCpy = [...POWithTaskByPhases];
    POWithTaskByPhasesCpy = POWithTaskByPhasesCpy.map((po) => {
      if (item.id === po.id) {
        return {
          ...item,
        };
      }
      return po;
    });
    setPOWithTaskByPhases(POWithTaskByPhasesCpy);
  }

  function handleUpdateShipmentItem(item) {
    let shipmentWithTaskByPhasesCpy = [...shipmentWithTaskByPhases];
    shipmentWithTaskByPhasesCpy = shipmentWithTaskByPhasesCpy.map(
      (shipment) => {
        if (item.id === shipment.id) {
          return {
            ...item,
          };
        }
        return shipment;
      }
    );
    setShipmentWithTaskByPhases(shipmentWithTaskByPhasesCpy);
  }

  const openSalesOrderRow = () => {
    if (expanded) {
      handleRemoveChargedSPTaskAndPOs(item.id);
      setExpanded(!expanded);
    } else {
      setLoading(true);
      setInternalLoading(true);
      handleChargeSOTasksAndPOs(item.companyId, item.id);
    }
  };
  function OBFGroupBySOTableRowSelector({ column, enabled, index }) {
    const purchaseOrders = item.purchaseOrders || [];
    switch (column) {
      case headerColumns.EXPAND:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("expandAndCollapse", { expanded })}
              style={{ overflow: "unset" }}
            >
              {getDisclouserArrow({
                handleChangeSetup: openSalesOrderRow,
                currentSetup: expanded,
                type: "orderGroup",
                min: 0,
                items: purchaseOrders.filter(
                  (purchaseOrder) =>
                    isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS) ||
                    isAllowed(purchaseOrder.factoryId)
                ),
              })}
            </TableCell>
          )
        );

      case headerColumns.FAVORITE:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("favorite", { expanded })}
              style={{ overflow: "unset" }}
            >
              {getStarFavorite({
                favorites,
                itemId: item.id,
                addFavorite: () =>
                  addFavorite({
                    favoritesDB,
                    id: item.id,
                    user,
                  }),
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: item.id }),
              })}
            </TableCell>
          )
        );

      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell
              key={index}
              style={{ overflow: "unset" }}
              className={cx("diamond", { expanded })}
            >
              {getDiamondStatus({
                diamondStatus: item.diamondStatus,
                itemStatus: item.status,
              })}
            </TableCell>
          )
        );

      case headerColumns.SALES_ORDER:
        const [firstPO = {}] = item.purchaseOrders || [];
        const shipmentsIds = firstPO.shipmentIds || [];
        const salesOrderTag = item.tags || [];
        return (
          enabled && (
            <TableCell key={index} className={cx("number", { expanded })}>
              <div className={"number-table-container"}>
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={item.id}
                    label={getTagsLabel(tags, salesOrderTag).tagsString}
                    tags={getTagsLabel(tags, salesOrderTag).tagsArray}
                    allTags={tags}
                    item={item}
                    currentUser={user}
                    tagReference={dbTables.SALES_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: salesOrderTag,
                      currentTags: filterTag,
                    })}
                  />
                )}
                <NavLink
                  to={getDashboardSearchPath({
                    salesOrderId: item.id,
                    purchaseOrderId: firstPO.id,
                    shipmentId: shipmentsIds[0],
                    section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                  })}
                  style={{
                    color: colors.primaryDark,
                    textDecoration: "none",
                  }}
                  id="list-view-content-salesorder"
                >
                  <SalesOrderBadge
                    number={item.number}
                    style={{
                      transition: "all 0.5s",
                    }}
                    className={
                      expanded ? "sales-order-badge-white" : "sales-order-badge"
                    }
                  />
                </NavLink>
                <ThreePanelButton
                  soId={item.id}
                  poId={firstPO.id}
                  shipmentId={shipmentsIds[0]}
                />
              </div>
            </TableCell>
          )
        );

      case headerColumns.CUSTOMER:
        return (
          enabled && (
            <TableCell key={index} className={cx("customerName", { expanded })}>
              <TooltipTD
                label={item.customerName}
                style={{ width: "fit-content", display: "flex" }}
              >
                <NavLink
                  to={`/app/customers/${item.customerId}`}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  id="list-view-content-customer"
                >
                  {item.customerName}
                </NavLink>
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.TOTAL:
        return (
          enabled && (
            <TableCell
              key={index}
              id="total"
              className={cx("amount", { expanded })}
            >
              {isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
                ? numeral(item.total).format("$0,0.00")
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.SO_SHIP_DATE:
        return (
          enabled && (
            <TableCell
              key={index}
              id="shipDate"
              className={cx("shipDate", { expanded })}
            >
              {getTheDate(item.shipDate, "M/D/YY")}
            </TableCell>
          )
        );

      case headerColumns.POS:
        return (
          enabled && (
            <TableCell
              key={index}
              id="totalPO"
              className={cx("quantityPOs", { expanded })}
            >
              {item.quantityPOs}
            </TableCell>
          )
        );

      case headerColumns.SHIPMENTS:
        return (
          enabled && (
            <TableCell
              key={index}
              id="totalShipment"
              className={cx("quantityPOs", { expanded })}
            >
              {item.quantityShipments}
            </TableCell>
          )
        );

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled &&
          isThereProposalQuoteColumn && (
            <TableCell
              key={index}
              className={cx("proposalTasks", { expanded })}
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? { width: headerStages.proposalQuoteMinWidh }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.proposalTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("preProductionTasks", { expanded })}
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.preProductionMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.preProductionTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("productionTasks", { expanded })}
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.productionMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.productionTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("bookingTransitTasks", { expanded })}
              style={
                Object.keys(SOTaskByPhases).length > 0
                  ? {
                      width: headerStages.bookingTransitMinWidh,
                    }
                  : {}
              }
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.bookingTransitTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell
              key={index}
              className={cx("paymentBillingTasks", { expanded })}
            >
              {expanded && Object.keys(SOTaskByPhases).length > 0 && (
                <ProgressStage
                  styles={{
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 10px",
                  }}
                  tasks={SOTaskByPhases.paymentBillingTasks}
                  purchaseOrderId={item.purchaseOrders[0].id}
                  salesOrderId={item.id}
                />
              )}
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell className={cx("extra", { expanded })} />;

      default:
        return <TableCell key={index} className={cx({ expanded })} />;
    }
  }

  const getHeigthRow = () => {
    let size = 235;
    const poTaksSize = POWithTaskByPhases.length;
    size = size + (poTaksSize === 0 ? 75.5 : poTaksSize * 47);
    const shipmentTaksSize = shipmentWithTaskByPhases.length;
    size = size + (shipmentTaksSize === 0 ? 75.5 : shipmentTaksSize * 47);
    return size;
  };
  return (
    <>
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <MainRowStyled
        key={item.id}
        className={cx(
          mainRowClasses.SOGroupTableRow,
          mainRowClasses.hoverEffect
        )}
        style={expanded ? { background: colors.salesOrderBackgroundColor } : {}}
      >
        {headers.map((header) =>
          OBFGroupBySOTableRowSelector({
            column: header.name,
            enabled: header.enabled,
            index: item.id + header.name,
          })
        )}
      </MainRowStyled>
      <tr>
        <td className="cellRow" colSpan={16} style={{ padding: 0 }}>
          <POSipmentRowStyle
            className="RowGoupChildEntity"
            colSpan={16}
            style={{
              height: expanded ? getHeigthRow() : 0,
              padding: expanded ? "" : 0,
            }}
          >
            <FilterTablePO
              purchaseOrderList={POWithTaskByPhases.filter(
                (purchaseOrder) =>
                  isAllowed(GENERAL_PERMISSION_VALUE.ALL_VENDORS) ||
                  isAllowed(purchaseOrder.factoryId)
              ).sort(
                sortObjectsBy(
                  purchaseOrderTableFilter.sortedColumn,
                  purchaseOrderTableFilter.orderBy === "ASC" ? true : false
                )
              )}
              expanded={expanded}
              headerStages={headerStages}
              topHeader={headers}
              filtersTable={purchaseOrderTableFilter}
              onUpdateFilters={(filters) =>
                setPurchaseOrderTableFilter(filters)
              }
              onUpdateItem={handleUpdatePOItem}
              tags={tags}
              isAllowed={isAllowed}
              isThereProposalQuoteColumn={isThereProposalQuoteColumn}
              filters={filters}
              onSetBackdrop={(isRedirect) => {
                if (isRedirect) {
                  setBackdrop({ type: BACKDROP_TYPE.PURCHASE_ORDER_INFO });
                  setTableItemStorage("purchaseOrder", true);
                }
                handleBackdropStorage(BACKDROP_TYPE.PURCHASE_ORDER_INFO);
              }}
            />

            <FilterTableShipment
              shipmentList={shipmentWithTaskByPhases
                .filter(
                  (shipment) =>
                    isAllowed(GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS) ||
                    isAllowed(shipment.customerId)
                )
                .sort(
                  sortObjectsBy(
                    shipmentTableFilter.sortedColumn,
                    shipmentTableFilter.orderBy === "ASC" ? true : false
                  )
                )}
              expanded={expanded}
              headerStages={headerStages}
              topHeader={headers}
              filtersTable={shipmentTableFilter}
              onUpdateFilters={(filters) => setShipmentTableFilter(filters)}
              onUpdateItem={handleUpdateShipmentItem}
              tags={tags}
              isAllowed={isAllowed}
              isThereProposalQuoteColumn={isThereProposalQuoteColumn}
              filters={filters}
              onSetBackdrop={(type, id, scrollProperty) => {
                setBackdrop({ type, id, scrollProperty });
                handleBackdropStorage(type);
              }}
              salesOrder={item}
              onOpenAttentionModal={onOpenAttentionModal}
            />
          </POSipmentRowStyle>
        </td>
      </tr>
    </>
  );
}

export default GroupBySalesOrder;
