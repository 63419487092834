import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";


export const PDFPortalStyled = styled("div")(() => ({
  "&.portalPDF": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    background: "rgba(0, 0, 0, 0.3)",
    justifyContent: "center",
    "& .pg-viewer-wrapper": {
      width: "100%",
      "& .react-grid-Container": {
        width: "100%",
      },
    },
    "& .document-container": {
      width: "98%",
      border: `2px ${colors.primaryDark} solid`,
    },
    "& iframe": {
      width: "100%",
      height: "100%",
      zIndex: 2,
    },
    "& .cancel-icon-pdf": {
      width: 24,
      height: 24,
      padding: 4,
      border: "2px solid",
      borderRadius: "50%",
      position: "absolute",
      right: 22,
      cursor: "pointer",
      color: "white",
      background: "white",
      zIndex: 10,
      marginTop: 5,
      "&:hover": {
        background: "gray",
        color: "gray",
      },
    },
  },
}));
