import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class TaskTemplateVersion {
  constructor({
    id = getRandomId(),
    creationDate = now(),
    createdBy = "",
    version = 1,
    description = "",
    salesOrderTemplateId = "",
    salesOrderTemplateVersion = "",
    templateIndexes = {},
    subVersion = 0
  }) {
    this.id = id;
    this.creationDate = creationDate;
    this.createdBy = createdBy;
    this.version = version;
    this.description = description;
    this.salesOrderTemplateId = salesOrderTemplateId;
    this.salesOrderTemplateVersion = salesOrderTemplateVersion;
    this.templateIndexes = templateIndexes;
    this.subVersion = subVersion;
  }
}
