import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { getTheDate, pathToString, sortObjectsBy } from "../../helpers/helpers";
import { orderType } from "../../helpers/salesOrder";
import { getShipmentsByPOs } from "../../helpers/salesOrderHelpers";
import TooltipTD from "../Tooltip/TooltipTD";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import {
  ShipmentDateWindowPopoverStyled,
  ShipmentDateWindowStyled,
} from "./styles";
import WindowTable from "./WindowTable";
import { TableContainer } from "@mui/material";
import { useDispatch } from "react-redux";

function ShipmentDateWindow({
  purchaseOrders = [],
  shipments = [],
  title = "Shipment Window",
  field = "shipDate",
  headCells,
  purchaseOrderId,
  onChangePurchaseOrder,
  propertyScope,
  companyId,
  label,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipmentDB, setShipmentsDB] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsLoading(true);
  };

  const handleClose = () => {
    if (!isLoading) setAnchorEl(null);
  };

  const setShipments = async () => {
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
      dispatch: dispatch,
    });
    if (newShipments?.length > 0) {
      setShipmentsDB(newShipments);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (anchorEl) {
      if (propertyScope === orderType.SALES_ORDER) {
        setShipments();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [anchorEl]);
  useEffect(() => {
    if (propertyScope === orderType.SALES_ORDER && purchaseOrders.length > 0) {
      setShipments();
    }
  }, [purchaseOrders.length]);

  let shipmentsToRender = [...shipmentDB];
  if (shipments.length > 0) {
    shipmentsToRender = shipmentsToRender.filter(
      (shipment) => !shipments.find((sh) => sh.id === shipment.id)
    );
    shipmentsToRender = [...shipmentsToRender, ...shipments];
  }
  const allShipments = [];
  purchaseOrders.forEach((purchaseOrder) => {
    if (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds.length > 0) {
      purchaseOrder.shipmentIds.forEach((shipmentId) => {
        if (!allShipments.includes(shipmentId)) {
          allShipments.push(shipmentId);
        }
      });
    }
  });
  shipmentsToRender = shipmentsToRender
    .filter((shipment) => allShipments.includes(shipment.id))
    .sort(sortObjectsBy(field, false));

  function getDateRangeLabel({ shipmentsToRender, totalShipment = 0 }) {
    if (shipmentsToRender.length === 0 && totalShipment === 0) {
      return "  ";
    } else if (shipmentsToRender.length === 0 && totalShipment !== 0) {
      return "Dates pending";
    } else if (shipmentsToRender.length === 1) {
      return `${getTheDate(shipmentsToRender[0][field])} -`;
    } else if (shipmentsToRender.length > 1) {
      return `${getTheDate(shipmentsToRender[0][field])} - ${getTheDate(
        shipmentsToRender[shipmentsToRender.length - 1][field]
      )}`;
    }
    return "";
  }

  return (
    <ShipmentDateWindowStyled
      className="shipmentDateWindowContainer"
      style={{ cursor: "pointer" }}
    >
      <div className="valueContainer" onClick={handleClick}>
        <TooltipTD label={"Click to see more"}>
          {label ||
            getDateRangeLabel({
              shipmentsToRender: shipmentsToRender.filter(
                (shipment) => shipment[field]
              ),
              totalShipment: shipmentsToRender.length,
            })}
        </TooltipTD>
      </div>
      {!!anchorEl && (
        <ShipmentDateWindowPopoverStyled
          id="simple-popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: "shipmentDateWindowPaperContainer" }}
          className="shipmentDateWindowPopoverContainer"
        >
          <div className="sdw-title-container">{title}</div>

          <div className="sdw-divider" />

          <div className="sdw-body-container">
            {isLoading && <LoadingBackdrop withLogo={true} />}

            <TableContainer>
              <WindowTable
                shipmentsToRender={shipmentsToRender}
                headCells={headCells}
                purchaseOrder={purchaseOrders.find(
                  (purchaseOrder) => purchaseOrder.id === purchaseOrderId
                )}
                onClose={handleClose}
                onChangePurchaseOrder={onChangePurchaseOrder}
              />
            </TableContainer>
          </div>
        </ShipmentDateWindowPopoverStyled>
      )}
    </ShipmentDateWindowStyled>
  );
}

export default ShipmentDateWindow;
