import React from "react";
import { dbTables } from "../../api/types/dbTables";
import { useFirestoreData } from "../../hooks";
import { pathToString } from "../../helpers/helpers";

function FactoryItem({ onItemClick, factory, companyId }) {
  const [templateTasks] = useFirestoreData(
    pathToString([
      dbTables.COMPANIES,
      companyId,
      dbTables.FACTORIES,
      factory.id,
      dbTables.MINI_PROJECT_TEMPLATE,
    ])
  );
  const isUpdated =
    templateTasks.every((task) =>
      !task.dependsOnSOT
        ? templateTasks.map((item) => item.id).includes(task.dependency)
        : true
    ) && templateTasks.length;
  return (
    <li
      onClick={() => onItemClick(factory)}
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 10,
        alignItems: "center",
        paddingBlock: 5,
      }}
    >
      <p
        style={{
          width: 150,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {factory.name}
      </p>
      {!isUpdated && (
        <label style={{ fontSize: 12 }}>Template needs update</label>
      )}
    </li>
  );
}

export default FactoryItem;
