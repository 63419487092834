import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { convertToTreeRefactored, sortObjectsBy } from "../../helpers/helpers";
import SearchBox from "../../components/SearchBox/SearchBox";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import { useFavoritesList, useJSONLocalStorage } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import Mark from "mark.js";
import { ClickAwayListener } from "@mui/material";
import { ReportDrawerStyled } from "../ReportScreen/styles";
import { StarIcon } from "../../helpers/iconSvgPath";
import ReportTreeList from "./ReportTreeList";
import {
  filterReportAndChildren,
  getDescendants,
} from "../../components/ReportsTree/helpers";
import { REPORTS_TYPE } from "../../helpers/constants";

const IDENTATION = 22;

const ReportDrawer = ({
  open,
  handleIsOpen,
  onChange,
  reports,
  currentReport = {},
}) => {
  const { get, set } = useJSONLocalStorage(ReportDrawer);
  const [openedFolders, setOpenedFolders] = useState(get() || {});
  const [favOpenedFolders, setFavOpendFolders] = useState({});
  const [filters, setFilters] = useState({
    favorite: false,
    searchbox: "",
  });

  const favorites = useFavoritesList({ dbTable: dbTables.REPORT_FAVORITES });

  function getFilteredReports({ reports = [], filters = {} }) {
    let reportsCpy = [...reports];
    let reportTree = convertToTreeRefactored({
      documents: reportsCpy,
      favorites,
    });
    if (filters.favorite || filters.searchbox) {
      reportTree = reportTree.filter((reportLeaf) =>
        filterReportAndChildren({ reportLeaf, filters, favorites })
      );
    }
    return reportTree.sort(sortObjectsBy("name", false));
  }
  useEffect(() => {
    if (filters.favorite) {
      let openFolders = {};
      reports.forEach((report) => {
        if (report.type === REPORTS_TYPE.FOLDER) {
          openFolders = { ...openFolders, [report.id]: true };
        }
      });
      setFavOpendFolders(openFolders);
    }
  }, [filters.favorite]);

  useEffect(() => {
    performMark(filters.searchbox);
  }, [filters.searchbox]);

  useEffect(() => {
    if (open) {
      const element = document.getElementById("generic-searchbox");
      if (element) {
        element.focus();
      }
    }
  }, [open]);

  const markInstance = new Mark(document.getElementById("reports-wrapper"));
  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [],
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  function recursiveReports({ report = {}, childNumber = 0 }) {
    const { child = [] } = report;
    const isSelected = report.id === currentReport.id;
    const childIdsList = getDescendants({
      documents: reports,
      originId: report.id,
    });
    const currentOpenList = filters.favorite
      ? favOpenedFolders[report.id]
      : openedFolders[report.id];
    const isFolderOpen = currentOpenList && report.type === REPORTS_TYPE.FOLDER;
    return (
      <div
        key={report.id + "active"}
        style={{
          width: "-webkit-fill-available",
        }}
      >
        <ReportTreeList
          handleOpenReport={(report) => {
            onChange({ report });
            handleIsOpen();
          }}
          levelMargin={IDENTATION * childNumber}
          isSelected={isSelected}
          report={report}
          isOpen={isFolderOpen}
          handleOpenFolder={(reportId) => {
            if (!filters.favorite) {
              setOpenedFolders((oldValue) => {
                const localStorageData = get() || {};
                set({
                  ...localStorageData,
                  [reportId]: !oldValue[reportId] === false ? undefined : true,
                });
                return {
                  ...oldValue,
                  [reportId]: !oldValue[reportId],
                };
              });
            } else {
              setFavOpendFolders((oldValue) => ({
                ...oldValue,
                [reportId]: !oldValue[reportId],
              }));
            }
          }}
        />
        <div
          className="children-container"
          style={{
            maxHeight: isFolderOpen ? childIdsList.length * 55 : "0px",
            overflow: "hidden",
            transition: "max-height 1s ease-in-out",
          }}
        >
          {child.sort(sortObjectsBy("name", false)).map((cReport) =>
            recursiveReports({
              report: cReport,
              childNumber: childNumber + 1,
            })
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={(ev) => {
          if (open) {
            handleIsOpen();
          }
          ev.stopPropagation();
        }}
      >
        <ReportDrawerStyled
          open={open}
          className={"drawerRoot"}
          onClose={handleIsOpen}
          transitionDuration={800}
        >
          <div className={"containerDrawer"}>
            <div className={"closeIconContainer"}>
              <CloseIcon
                className="note-secction-cancel-icon"
                onClick={() => handleIsOpen()}
                style={{ position: "absolute", right: 15, top: 15 }}
              />
            </div>
            <div className="headerContainer">
              <ButtonFilter
                buttonId="reports-view-button-filter-favorite"
                value={filters.favorite}
                onClick={() =>
                  setFilters({ ...filters, favorite: !filters.favorite })
                }
                className="buttonStar"
                style={{
                  width: 40,
                }}
                boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
              >
                <StarIcon />
              </ButtonFilter>

              <SearchBox
                placeholder="Search report titles or folder names"
                value={filters.searchbox}
                filters={filters}
                onDebounceValue={(filters, value) => {
                  setFilters({ ...filters, searchbox: value });
                }}
                hasBeenReseted={() => setFilters({ ...filters, searchbox: "" })}
              />
            </div>
            <div id="reports-wrapper" className="reportList">
              {getFilteredReports({ filters, reports }).map((report) => {
                return recursiveReports({
                  childNumber: 0,
                  report,
                  isRoot: true,
                });
              })}
            </div>
          </div>
        </ReportDrawerStyled>
      </ClickAwayListener>
    </>
  );
};

export default ReportDrawer;
