import { getDoc, doc } from "firebase/firestore";
import { uniq } from "lodash";
import numeral from "numeral";
import { dbTables } from "../api/types/dbTables";
import { firestore } from "../firebase";
import { getActivitiesNotes, getTheDate, sortObjectsBy } from "./helpers";
import { CLIENT_IS_OFFLINE } from "../actions/types";

export async function getLastActivityDate({
  salesOrderMention = {},
  POsMention = [],
  shipmentsMention = [],
  user = {},
  companyId = "",
  dispatch = () => {},
}) {
  if (salesOrderMention.id) {
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: salesOrderMention.id,
      userId: user.id,
      orderTable: dbTables.SALES_ORDERS,
      dispatch,
    });
  }
  if (POsMention.length > 0) {
    const purchaseOrder = POsMention.sort(sortObjectsBy("number", true))[0];
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: purchaseOrder.id,
      userId: user.id,
      orderTable: dbTables.PURCHASE_ORDERS,
      dispatch,
    });
  }
  if (shipmentsMention.length > 0) {
    const shipment = shipmentsMention.sort(sortObjectsBy("number", true))[0];
    return await getActivitiesNotes({
      companyId: companyId,
      orderId: shipment.id,
      userId: user.id,
      orderTable: dbTables.SHIPMENTS,
      dispatch,
    });
  }
}

function searchPOsByQuery({ purchaseOrders = [], searchText }) {
  const purchaseOrdersDB = purchaseOrders.filter((purchaseOrder) =>
    purchaseOrder.number.toLowerCase().includes(searchText.toLowerCase())
  );
  return purchaseOrdersDB.length > 0 ? true : false;
}

function searchPOsAnVendorByQuery({ purchaseOrders = [], searchText }) {
  const purchaseOrdersDB = purchaseOrders.filter(
    (purchaseOrder) =>
      purchaseOrder.number.toLowerCase().includes(searchText.toLowerCase()) ||
      purchaseOrder.factoryName.toLowerCase().includes(searchText.toLowerCase())
  );
  return purchaseOrdersDB.length > 0 ? true : false;
}

function searchSalesOrdersByQuery({ salesOrders = [], searchText }) {
  const salesOrdersDB = salesOrders.filter((salesOrder) =>
    salesOrder.number.toLowerCase().includes(searchText.toLowerCase())
  );
  return salesOrdersDB.length > 0 ? true : false;
}

function searchByShipments({ shipments = [], searchText = "" }) {
  const shipmentsDB = shipments.filter((shipment) =>
    shipment.number.toString().toLowerCase().includes(searchText.toLowerCase())
  );
  return shipmentsDB.length > 0 ? true : false;
}

export function filterList({
  list = [],
  nonLookUpfilters = {},
  headerCells,
  headerColumns,
  favorites,
  screenType = "",
  userMentions = {},
}) {
  let filteredList = [
    ...list.map((item) => ({
      ...item,
      totalMentions:
        screenType === SCREEN_TYPE.SALES_ORDER_LIST
          ? getAllOrderMentions({
              userMentions,
              soId: item.id,
              purchaseOrders: item.purchaseOrders,
            }).totalOrderMention
          : userMentions[item.id] || 0,
    })),
  ];
  if (nonLookUpfilters.user) {
    filteredList = filteredList.filter((el) => el.totalMentions > 0);
  }
  if (nonLookUpfilters.favorite) {
    filteredList = filteredList.filter((item) =>
      favorites.map((favoriteId) => favoriteId).includes(item.id)
    );
  }
  if (nonLookUpfilters.query) {
    const enabledFieldToSearch = [];
    headerCells.forEach((header) => {
      if (header.enabled && !!header.searchBy) {
        enabledFieldToSearch.push(header.searchBy);
      }
    });
    filteredList = filteredList.filter((el) => {
      return enabledFieldToSearch.some((field) => {
        if (field && field === headerColumns.PO_ORDER_READY_DATE_VENDOR) {
          if (
            searchPOsAnVendorByQuery({
              purchaseOrders: el.purchaseOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (field && field === headerColumns.POS) {
          if (
            searchPOsByQuery({
              purchaseOrders: el.purchaseOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (
          field &&
          (field === headerColumns.SHIPMENTS ||
            field === headerColumns.SHIPMENT)
        ) {
          if (
            searchByShipments({
              shipments: el.shipments,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (
          screenType === SCREEN_TYPE.SHIPMENT_LIST &&
          field &&
          field === "salesOrder"
        ) {
          if (
            searchSalesOrdersByQuery({
              salesOrders: el.salesOrders,
              searchText: nonLookUpfilters.query,
            })
          ) {
            return true;
          }
        } else if (
          field &&
          el[field] &&
          el[field]
            .toString()
            .toLowerCase()
            .includes(nonLookUpfilters.query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    });
  }
  filteredList.sort(
    sortObjectsBy(
      nonLookUpfilters.sortedColumn,
      nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false
    )
  );

  let totals = 0;
  let totalSubtotals = 0;
  let totalDeposit = 0;
  let totalBalance = 0;
  let totalPotentialLateFee = 0;
  let totalCBM = 0;
  let totalDiscount = 0;
  let totalWeight = 0;

  filteredList.forEach((order) => {
    totals += +numeral(order.total).value() || 0;
    totalPotentialLateFee += +numeral(order.potentialLateFee).value() || 0;
    totalCBM += +numeral(order.CBM).value() || 0;
    totalDiscount += +numeral(order.discount).value() || 0;
    totalSubtotals += +numeral(order.subtotal).value() || 0;
    totalDeposit += +numeral(order.deposit).value() || 0;
    totalBalance += +numeral(order.balance).value() || 0;
    totalWeight += +numeral(order.totalWeight).value() || 0;
  });
  const totalAmounts = {
    totals: numeral(totals).format("$ 0,0.00"),
    totalPotentialLateFee: numeral(totalPotentialLateFee).format("$ 0,0.00"),
    totalCBM: numeral(totalCBM).format("0,0.00"),
    totalDiscount: numeral(totalDiscount).format("$ 0,0.00"),
    totalSubtotals: numeral(totalSubtotals).format("$ 0,0.00"),
    totalDeposit: numeral(totalDeposit).format("$ 0,0.00"),
    totalBalance: numeral(totalBalance).format("$ 0,0.00"),
    totalWeight: numeral(totalWeight).format("0,0.00"),
  };
  return { filteredList, totalAmounts };
}

export const orderTypeSort = {
  ASC: "ASC",
  DESC: "DESC",
};

export const SCREEN_TYPE = {
  SALES_ORDER_LIST: "SALES_ORDER_LIST",
  PURCHASE_ORDER_LIST: "PURCHASE_ORDER_LIST",
  SHIPMENT_LIST: "SHIPMENT_LIST",
};

export const TASK_TEMPLATE_OPTIONS = {
  DELETE_TASK: "DELETE_TASK",
  ADD_TASK: "ADD_TASK",
  TASK_CHANGED_POSITION: "TASK_CHANGED_POSITION",
  CHANGED_DATA: "CHANGED_DATA",
  TASK_CHAGE_DEPENDECY: "TASK_CHAGE_DEPENDECY",
};

export const getShipmentsByPOs = async ({
  purchaseOrders = [],
  path = "",
  dispatch,
}) => {
  try {
    let currentShipmentIds = [];
    purchaseOrders.forEach(
      (po) =>
        (currentShipmentIds = [
          ...currentShipmentIds,
          ...(po.shipmentIds || []),
        ])
    );
    currentShipmentIds = uniq(currentShipmentIds);
    const shipmentPromises = [];
    currentShipmentIds.forEach((shipmentId) => {
      shipmentPromises.push(getDoc(doc(firestore, `${path}/${shipmentId}`)));
    });

    const resolved = await Promise.all(shipmentPromises);
    return resolved.map((doc) => ({ ...doc.data(), ref: doc.ref }));
  } catch (error) {
    console.log("ERROR getShipmentsByPOs", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and refresh the page",
      },
    });
    return null;
  }
};

const processMentions = (mentionsList, orderList) => {
  const filterMentionList = {};
  let totalMentions = 0;
  orderList.forEach((currentOrder) => {
    if (mentionsList[currentOrder.id] && mentionsList[currentOrder.id] > 0) {
      filterMentionList[currentOrder.id] = mentionsList[currentOrder.id];
      totalMentions += mentionsList[currentOrder.id];
    }
  });
  return { filterMentionList, totalMentions };
};

export const getAllOrderMentions = ({
  purchaseOrders = [],
  soId = "",
  userMentions = {},
}) => {
  const getMentionsList = (tableName) => userMentions[tableName] || {};
  const soMentionsList = getMentionsList(dbTables.SALES_ORDERS);
  const poMentionsList = getMentionsList(dbTables.PURCHASE_ORDERS);
  const shipmentMentionsList = getMentionsList(dbTables.SHIPMENTS);
  let totalOrderMention = 0;
  const currentShipmentIds = Array.from(
    new Set(
      purchaseOrders.reduce(
        (ids, po) => [...ids, ...(po.shipmentIds || [])],
        []
      )
    )
  ).map((id) => ({ id }));
  const { filterMentionList: soMentions, totalMentions: soTotalMention } =
    processMentions(soMentionsList, [{ id: soId }]);
  const { filterMentionList: posMentions, totalMentions: poTotalMention } =
    processMentions(poMentionsList, purchaseOrders);
  const {
    filterMentionList: shipmentMentions,
    totalMentions: shipmentTotalMention,
  } = processMentions(shipmentMentionsList, currentShipmentIds);
  totalOrderMention = soTotalMention + poTotalMention + shipmentTotalMention;

  return {
    soMentions,
    posMentions,
    shipmentMentions,
    totalOrderMention,
  };
};

export const getPOMetadada = ({
  factoryName = "",
  shipmentIds = [],
  orderReadyDate = "",
}) => {
  const text = [];
  if (factoryName) {
    text.push(factoryName);
  }
  if (orderReadyDate) {
    text.push(`Order Ready Date: ${getTheDate(orderReadyDate, "M/D/YY")}`);
  }
  if (shipmentIds.length) {
    text.push(
      `${shipmentIds.length} ${
        shipmentIds.length === 1 ? "shipment" : "shipments"
      }`
    );
  }
  return text.join(",\u00A0");
};
