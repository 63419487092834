import { Accordion, Autocomplete, styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const DocumentPlaceholderStyled = styled("div")(() => ({
  "&.documentPlaceholderContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
  },
  "& .document-template-container": {
    display: "flex",
    margin: "5px 5px",
    height: "calc(100vh - 150px)",
    overflowX: "auto",
    overflowY: "hidden",
  },
  "& .root": {
    width: "100%",
  },
  "& .heading": {
    fontSize: 15,
    fontWeight: 500,
  },
  "& .gridContainer": {
    display: "grid",
    gridTemplateColumns: "15% 25% 40% 20%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
  "& .gridContainerUpdatedSOsAndPOs": {
    display: "grid",
    gridTemplateColumns: "15% 25% 15% 15% 15% 15%",
    gridTemplateRows: "max-content",
    gridGap: "15px",
    width: "50%",
    marginBottom: "16px",
  },
}));

export const SettingsStyled = styled("div")(() => ({
  "& .settingsContainer": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    display: "block",
  },
}));

export const ErrorLogStyled = styled("div")(() => ({
  "&.errorLogContainer": {
    boxShadow: "0 2px 4px 2px #ccc",
    width: "100%",
    height: "100%",
    display: "block",
    zIndex: 0,
    overflow: "auto",
    "& .load-more-button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 60,
      "& button": {
        padding: "16px 32px",
        background: colors.primaryDark,
        color: "#fff",
        border: "none",
        cursor: "pointer",
        borderRadius: 4,
        fontSize: 14,
        fontWeight: 600,
      },
    },
  },
}));

export const AccordionImportedDataStyled = styled(Accordion)(() => ({
  "& .container": {
    margin: "0px 4px 4px 4px",
    boxShadow: "0 2px 4px 2px #ccc",
    height: "calc(100vh - 165px)",
    display: "block",
    padding: 16,
  },
  "& .expandIcon": {
    position: "absolute",
    left: "0px",
  },
  "& .content": {
    marginLeft: "32px !Important",
  },
}));

export const SettingsTabTable = styled("div")(() => ({
  "& .avatarTable": {
    width: "20px !important",
    height: "20px !important",
    outline: "1px solid #000",
    fontSize: 10,
    marginRight: 4,
  },
  "& .cellTable": {
    display: "-webkit-box",
    fontSize: "0.875rem",
    fontWeight: 400,
    color: colors.primaryDark,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: 45,
  },
  "&.SupplierTable": {
    gridTemplateColumns: "500px 120px 1fr",
    display: "grid",
    "& .name": {
      padding: "12px 10px",
      lineHeight: "16px",
    },
    "& .trashIcon": { cursor: "pointer" },
  },
  "&.GLAccountsTable": {
    gridTemplateColumns: "1fr 1fr 120px",
    display: "grid",
    "& .InactiveContainer": {
      gridColumn: "span 3",
    },
    "& .name": {
      padding: "12px 10px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      wordBreak: "break-word",
      lineHeight: "16px",
    },
    "& .headerTable": {
      padding: 9,
      "& .arrow": {
        opacity: 0,
      },
      "&:hover": {
        "& .arrow": {
          opacity: 1,
        },
      },
    },
  },
  "&.CompanyUserTable": {
    gridTemplateColumns: "180px 180px 150px 140px 1fr 115px 1fr 70px 100px",
    display: "grid",
    "& .InactiveContainer": {
      gridColumn: "span 9",
    },
    "& .name": {
      padding: "12px 10px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      wordBreak: "break-word",
      lineHeight: "16px",
    },
    "& .avatar": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .headerTable": {
      padding: 10,
      "& .arrow": {
        opacity: 0,
      },
      "&:hover": {
        "& .arrow": {
          opacity: 1,
        },
      },
    },
  },
  "&.EmployeeUserTable": {
    gridTemplateColumns:
      "180px 180px 150px 140px 1fr 115px 1fr 130px 100px 70px 100px",
    "& .InactiveContainer": {
      gridColumn: "span 11",
    },
  },
  "&.LabelsTable": {
    gridTemplateColumns: "1fr 1fr 180px 100px 220px 100px 150px",
    display: "grid",
    minWidth: 1080,
    "& .InactiveContainer": {
      gridColumn: "span 7",
    },
    "& .name": {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      textOverflow: "ellipsis",
      whiteSpace: "unset",
      height: 50,
      padding: "8px 16px",
      "& .spanText": {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        wordBreak: "break-word",
      },
    },
    "& .labelContainer": {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    "& .headerTable": {
      top: 0,
      "& .arrow": {
        opacity: 0,
      },
      "&:hover": {
        "& .arrow": {
          opacity: 1,
        },
      },
    },
    "& .systemLabel": {
      paddingLeft: 40,
      fontSize: 14,
      color: colors.primaryDark,
      fontStyle: "italic",
      paddingTop: 16,
      gridColumn: "span 7",
    },
    "& .divider": {
      borderTop: "2px solid #D0DCEA",
      margin: "8px 0px",
    },
  },
  "&.AccessGroupTable": {
    gridTemplateColumns: "300px 0.5fr 2fr 230px",
    display: "grid",
    minWidth: 1080,
    "& .InactiveContainer": {
      gridColumn: "span 4",
    },
    "& .groupCellTable": {
      display: "-webkit-box",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: colors.primaryDark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: 330,
    },
    "& .actionButtonsContainer": {
      fontSize: 14,
      fontWeight: "bold",
    },
    "& .name": {
      display: "flex",
      alignItems: "center",
      paddingInline: "16px 5px",
    },
    "& .descriptionContainer": {
      display: "flex",
      alignItems: "center",
    },
    "& .description": {
      padding: "5px 5px 0px 16px",
      wordBreak: "break-word",
      lineHeight: "21px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 5,
      display: "-webkit-box",
      fontSize: "0.875rem",
      fontWeight: "400",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: 110,
    },
    "& .groupContainer": {
      padding: 10,
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      wordBreak: "break-word",
      overflow: "unset",
    },
  },
  "&.CategoriesTable": {
    gridTemplateColumns: "1fr 1fr 220px 100px 220px 100px 120px",
    display: "grid",
    minWidth: 1080,
    "& .InactiveContainer": {
      gridColumn: "span 7",
    },
    "& .name": {
      padding: 16,
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      wordBreak: "break-word",
    },

    "& .description": {
      padding: "16px 16px 16px 17px",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      wordBreak: "break-word",
    },
    "& .createdBy": {
      padding: 16,
      display: "flex",
      alignItems: "center",
    },
    "& .dateCreated": {
      padding: "16px 32px 16px 18px",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  "& .headerTable": {
    fontSize: "0.875rem",
    background: colors.tableHeaderColor,
    height: 51,
    borderBottom: "1px solid #87A3C2",
    borderTop: "1px solid #87A3C2",
    zIndex: 2,
    padding: 16,
    fontWeight: 700,
    color: colors.primaryDark,
    display: "flex",
    alignItems: "center",
    paddingInline: "16px 5px",
    paddingBlock: 0,
    position: "sticky",
    top: 50,
    gap: 5,
    "& .whiteDivider": {
      width: 2,
      height: "60%",
      background: "#e6f0f9",
      position: "absolute",
      right: 0,
    },
    "& .arrow": {
      opacity: 0,
    },
    "&:hover": {
      "& .arrow": {
        opacity: 1,
      },
    },
  },
  "& .rowTable": {
    display: "contents",
    "& .actionButtonsContainer": {
      opacity: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
    },
    "&:hover": {
      backgroundColor: colors.onHoverBackground,
      "& .cellTable": {
        backgroundColor: colors.onHoverBackground,
      },
      "& .groupCellTable": {
        backgroundColor: colors.onHoverBackground,
      },
      "& .actionButtonsContainer": {
        opacity: 1,
      },
    },
  },
}));

export const DeleteNotesStyled = styled("div")(() => ({
  "&.deleteNotesContainer": {
    backgroundColor: "#fff",
    "& .select-scope-container": {
      "& .select-scope": {
        display: "flex",
        alignItems: "center",
        gap: 12,
        justifyContent: "flex-start",
      },
    },
    "& .select-options-container": {
      display: "flex",
      alignItems: "center",
      gap: 24,
      padding: 24,
    },
    "& .body-content-activities": {
      height: "calc(100vh - 207px)",
      overflow: "auto",
      width: "fit-content",
      margin: "auto",
      "&::-webkit-scrollbar": {
        width: 6,
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 7,
      },
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.thumbScrollColor,
        },
      },
      "& .button-to-delete-container": {
        display: "flex",
        alignItems: "center",
        padding: 16,
        justifyContent: "flex-start",
        position: "relative",
        width: 175,
        "& button": {},
      },
      "& .group-activities-content": {
        width: "100%",

        "& .group-activity-date": {
          fontSize: 13,
          fontWeight: 500,
          color: colors.primaryDark,
          background: "#FFF",
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: 60,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "12px 4px",
          marginLeft: 2.5,
        },
      },
      "& .activity-container": {
        width: "calc(100% - 250px)",
      },
    },
  },
}));

export const AutoCompleteEntityStyled = styled(Autocomplete)(() => ({
  "&.autocomplete-entity": {
    "& .clear-indicator": {
      minWidth: 0,
      minHeight: 0,
      height: "fit-content",
      right: 8,
      top: 3,
      "&:hover": {
        cursor: "pointer",
        background: "transparent",
        userSelect: "none",
      },
    },
  },
}));
