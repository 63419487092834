import { getDocs, collection } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import { TASK_TYPE } from "../../helpers/constants";
import AppConfig from "../../constants/AppConfig";
import { sortObjectsBy } from "../../helpers/helpers";

export const fetchSalesOrderTasks = async ({
  companyRef,
  salesOrderTemplateId,
  factory = {},
  versionId,
}) => {
  try {
    if (!versionId) {
      console.error(
        "ERROR RETRIEVING SALES ORDER TASKS TEMPLATE: versionId is not defined"
      );
      return [];
    }
    let factoryCpy = factory;
    const factoryIndexes = factoryCpy.vendorTemplateIndexes || {};
    const snapshot = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.SALES_ORDER_TEMPLATES}/${salesOrderTemplateId}/${dbTables.VERSIONS}/${versionId}/${dbTables.TASKS_TEMPLATE}`
      )
    );

    const salesOrderTasks = snapshot.docs
      .map((doc) => {
        const templateTask = doc.data();
        const templateIndex =
          factoryIndexes[templateTask.id] || templateTask.listIndex;
        return {
          ...templateTask,
          listIndex: templateIndex,
          type: TASK_TYPE.SALES_ORDER,
          ref: doc.ref,
          color: AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        };
      })
      .sort(sortObjectsBy("number"));

    return salesOrderTasks;
  } catch (err) {
    console.error("ERROR RETRIEVING SALES ORDER TASKS TEMPLATE", err);
    throw err;
  }
};

export const fetchPOTasks = async ({
  companyRef,
  purchaseOrderTemplate = {},
}) => {
  try {
    const { currentVersionId, id: purchaseOrderTemplateId } =
      purchaseOrderTemplate;
    if (!currentVersionId) {
      console.error(
        "ERROR RETRIEVING SALES ORDER TASKS TEMPLATE: currentVersionId is not defined"
      );
      return [];
    }
    const templateIndexes = purchaseOrderTemplate.templateIndexes || {};
    const snapshot = await getDocs(
      collection(
        firestore,
        `${companyRef}/${dbTables.PURCHASE_ORDER_TEMPLATES}/${purchaseOrderTemplateId}/${dbTables.VERSIONS}/${purchaseOrderTemplate.currentVersionId}/${dbTables.TASKS_TEMPLATE}`
      )
    );

    const purchaseOrderTasks = snapshot.docs
      .map((doc) => {
        const templateTask = doc.data();
        const templateIndex =
          templateIndexes[templateTask.id] || templateTask.listIndex;
        return {
          ...templateTask,
          listIndex: templateIndex,
          type: templateTask.type || TASK_TYPE.PURCHASE_ORDER,
          color:
            doc.data().type === TASK_TYPE.SHIPMENT
              ? AppConfig.themeColors.shipmentBackgroundGanttChartColor
              : AppConfig.themeColors.purchaseOrderBorderColor,
          ref: doc.ref,
        };
      })
      .sort(sortObjectsBy("number"));

    return purchaseOrderTasks;
  } catch (err) {
    console.error("ERROR RETRIEVING SALES ORDER TASKS TEMPLATE", err);
    throw err;
  }
};
