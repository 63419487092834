import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables } from "../../api/types/dbTables";
import SelectButton from "../../components/Selects/SelectButton";
import { BindFactoryTemplatesStyled } from "./styles";
import CustomButton from "../../components/Buttons/CustomButton";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { sortObjectsBy } from "../../helpers/helpers";

function BindFactoryTemplates({
  isOpen,
  closeTemplate,
  companyId,
  factory = {},
  bindPO,
}) {
  const [salesOrderTemplates, setSalesOrderTemplates] = useState([]);
  const [purchaseOrderTemplates, setPurchaseOrderTemplates] = useState([]);

  useEffect(() => {
    if (isOpen) {
      getSalesOrderTemplates();
      getPurchaseOrderTemplates();
    }
  }, [isOpen]);

  async function getSalesOrderTemplates() {
    const salesOrderTemplatesSnap = await getDocs(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATES}`
      )
    );
    const salesOrderTemplates = salesOrderTemplatesSnap.docs.map((doc) => ({
      ...doc.data(),
      ref: doc.ref,
      purchaseOrderTemplateId: factory?.linkedTemplates
        ? factory.linkedTemplates[doc.id]
        : "",
    }));
    setSalesOrderTemplates(salesOrderTemplates);
  }

  async function getPurchaseOrderTemplates() {
    const purchaseOrderTemplatesSnap = await getDocs(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDER_TEMPLATES}`
      )
    );
    const purchaseOrderTemplates = purchaseOrderTemplatesSnap.docs.map(
      (doc) => ({
        ...doc.data(),
        ref: doc.ref,
      })
    );
    setPurchaseOrderTemplates(purchaseOrderTemplates);
  }

  function handleChangeAssignPOTemplate({
    purchaseOrderTemplateId,
    salesOrderTemplate,
    salesOrderTemplates,
  }) {
    let salesOrdersTemplatesCpy = [...salesOrderTemplates];
    salesOrdersTemplatesCpy = salesOrdersTemplatesCpy.map((template) => {
      if (template.id === salesOrderTemplate.id) {
        return {
          ...template,
          purchaseOrderTemplateId: purchaseOrderTemplateId,
        };
      }
      return template;
    });

    setSalesOrderTemplates(salesOrdersTemplatesCpy);
  }

  function getPOSelected({ linkedTemplates = {}, salesOrderTemplate = {} }) {
    if (
      linkedTemplates[salesOrderTemplate.id] ||
      salesOrderTemplate.purchaseOrderTemplateId
    ) {
      const purchaseOrderTemplate = purchaseOrderTemplates.find(
        (template) => template.id === salesOrderTemplate.purchaseOrderTemplateId
      );
      return {
        emoji: purchaseOrderTemplate?.emoji,
        label: purchaseOrderTemplate?.name,
        id: purchaseOrderTemplate?.id,
      };
    }
    return { emoji: "", id: "", label: "Select a PO Template" };
  }
  return (
    <BindFactoryTemplatesStyled
      className={"bind-factory-modal"}
      isOpen={true}
      onClose={closeTemplate}
      header={
        <div>
          Bind each Sales Order Template to a <br />
          specific PO Template for this vendor
        </div>
      }
      modalStyle={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
      headerStyles={{
        height: "auto",
      }}
      footer={
        <div className="action-buttons-container">
          <CustomButton
            type="success"
            noSubmit
            onClick={() => {
              const linkedTemplates = {};
              salesOrderTemplates.forEach((template) => {
                if (template.purchaseOrderTemplateId) {
                  linkedTemplates[template.id] =
                    template.purchaseOrderTemplateId;
                }
              });

              updateDoc(
                doc(
                  firestore,
                  `${dbTables.COMPANIES}/${companyId}/${dbTables.FACTORIES}/${factory.id}`
                ),
                {
                  linkedTemplates,
                }
              );
              closeTemplate();
            }}
          >
            Save
          </CustomButton>

          <CustomButton onClick={closeTemplate} type={"cancel"}>
            Cancel
          </CustomButton>
        </div>
      }
    >
      <div className="bind-factory-template-body">
        {salesOrderTemplates
          .sort(sortObjectsBy("name"))
          .reduce((acc, template) => {
            if (bindPO && template.id === bindPO) {
              return [template, ...acc];
            } else {
              return [...acc, template];
            }
          }, [])
          .map((salesOrderTemplate) => {
            const disabledBlind = bindPO
              ? bindPO !== salesOrderTemplate.id
              : false;

            return (
              <div
                className="sales-order-template-content"
                key={salesOrderTemplate.id}
                style={{ opacity: disabledBlind ? 0.5 : "" }}
              >
                <div className="sales-order-template-name">
                  <span className="emoji">
                    {salesOrderTemplate.emoji || "🎰"}
                  </span>
                  <TooltipTD
                    className="template-name"
                    label={salesOrderTemplate.name}
                  >
                    {salesOrderTemplate.name}
                  </TooltipTD>
                </div>

                <SelectButton
                  selectStyle={{ width: 240, minWidth: 240, maxWidth: 240 }}
                  data={purchaseOrderTemplates
                    .sort(sortObjectsBy("name"))
                    .filter(
                      (template) =>
                        template.salesOrderTemplateId === salesOrderTemplate.id
                    )
                    .map((el) => ({
                      value: el.id,
                      label: el.name,
                      emoji: el.emoji,
                      isOutdated: el.isOutdated,
                    }))}
                  buttonSetup={getPOSelected({
                    linkedTemplates: factory.linkedTemplates,
                    salesOrderTemplate,
                  })}
                  onChange={(purchaseOrderTemplateId) => {
                    handleChangeAssignPOTemplate({
                      salesOrderTemplate,
                      salesOrderTemplates,
                      purchaseOrderTemplateId,
                    });
                  }}
                  warningIcon
                  disabled={disabledBlind}
                />
              </div>
            );
          })}
      </div>
    </BindFactoryTemplatesStyled>
  );
}

export default BindFactoryTemplates;
