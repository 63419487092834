import React, { useEffect, useState } from "react";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/helpers";
import { ErrorLogStyled } from "./styles";
import { TableCell, TableRow } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import moment from "moment";
import { useParams } from "react-router-dom";

import { loadDataBatches } from "../../helpers/settings.helpers";

function ErrorLog() {
  const { companyId } = useParams();

  const [errorLogDB, setErrorLogDB] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [pendingToLoad, setPendingToLoad] = useState(true);

  async function loadErrorLog({ lastDoc }) {
    const {
      docs,
      lastDoc: lastDocResult,
      pendingToLoad,
    } = await loadDataBatches({
      path: `${dbTables.COMPANIES}/${companyId}/${dbTables.API_ERROR_LOG}`,
      lastDoc: lastDoc,
      limitFilter: 50,
      orderByField: "date",
    });
    setLastDoc(lastDocResult);
    setPendingToLoad(pendingToLoad);
    if (!lastDoc) {
      setErrorLogDB(docs);
    } else {
      setErrorLogDB((prev) => [...prev, ...docs]);
    }
  }

  useEffect(() => {
    loadErrorLog({ lastDoc: null });
  }, [companyId]);

  const tbodyDisplay =
    errorLogDB &&
    errorLogDB.sort(sortObjectsBy("date", true)).map((item) => {
      return (
        <TableRow key={item.id}>
          <TableCell>{moment(item.date).format("M/D/YY hh:mm:ss a")}</TableCell>
          <TableCell style={{ textAlign: "left" }}>
            {item.error_message_id}
          </TableCell>
          <TableCell>
            {item.description}{" "}
            <span
              style={{
                background: "lightgray",
                borderRadius: 9,
                padding: "2px 6px",
                fontSize: 12,
                fontWeight: 500,
                color: "#25486d",
              }}
            >
              {item.end_point}
            </span>{" "}
          </TableCell>
        </TableRow>
      );
    });

  const thead = [
    {
      label: "Date and Time",
      styles: {
        width: "210px",
      },
    },
    {
      label: "Error ID",
      styles: {
        width: "90px",
      },
    },
    {
      label: "Description",
      styles: {
        width: "auto",
      },
    },
  ];
  const theadDisplay = thead.map((thead) => {
    return <TableCell style={thead.styles}>{thead.label}</TableCell>;
  });

  return (
    <ErrorLogStyled className="errorLogContainer">
      <DataTable header={theadDisplay} className="row-hover-over">
        {tbodyDisplay}
      </DataTable>
      {pendingToLoad && (
        <div className="load-more-button">
          <button onClick={() => loadErrorLog({ lastDoc })}>Load More</button>
        </div>
      )}
    </ErrorLogStyled>
  );
}

export default ErrorLog;
