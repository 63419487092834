export const colors = {
  primary: "#c0d5ee",
  primaryDark: "#25486D",
  primaryLight: "#e6f0f9",
  lightYellow: "rgba(255, 255, 0, 0.1)",
  onHoverColor: "rgb(246, 251, 255)",
  borderGray: "#4F4F4F",
  dangerRed: "#f44336",
  diamondRed: "#E02020",
  diamondOrange: "#FA9917",
  diamondGreen: "#30D536",
  diamondWhiteBorder: "#bdbdbd",
  sliderGreen: "#149532",
  skyBlue: "#CAE0FC",
  whiteBlue: "#D0DCEA",
  backgroundWhite: "#E0E0E0",
  backgroundWhiteAction: "#BCBCBC",
  backgroundBlue: "#B1D9FE",
  backgroundBlueAction: "#65B0F6",
  backgroundGreen: "#C5E569",
  backgroundGreenAction: "#9DC725",
  backgroundPink: "#F6C4FF",
  backgroundPinkAction: "#EA84FC",
  shipmentBadge: "#F6E6F9",
  buttomToolbarActive: "#EBEBEB",
  buttomDisabled: "#D0DCEA26",
  gray: "#DDDDDD",
  darkGray: "#757575",
  salesOrderBackgroundColor: "#EBF3D4",
  salesOrderDocBackgroundColor: "#DBE8B2",
  salesOrderBorderColor: "#B6C193",
  salesOrderSelectedBackgroundColor: "#C8E48C",
  salesOrderSelectedBorderColor: "#398F66",
  salesOrderHoverBackgroundColor: "#F4FDD8",
  salesOrderHoverColor: "#C5E569",
  salesOrderSelectedColor: "#90A352",
  purchaseOrderBackgroundColor: "#E6F0F9",
  purchaseOrderDocBackgroundColor: "#CEE1F3",
  purchaseOrderBorderColor: "#90AED1",
  purchaseOrderSelectedBackgroundColor: "#B1D6FA",
  purchaseOrderSelectedBorderColor: "#2C6CC9",
  purchaseOrderHoverBackgroundColor: "#DCEEFF",
  purchaseOrderHoverColor: "#97CCFD",
  purchaseOrderSelectedColor: "#4E89CD",
  shipmentDocBackgroundColor: "#F7D4FD",
  shipmentBorderColor: "#DAA2E3",
  shipmentSelectedBackgroundColor: "#EDAFF9",
  shipmentSelectedBorderColor: "#C731DF",
  shipmentHoverBackgroundColor: "#FEF6FF",
  shipmentHoverColor: "#EBB3F5",
  shipmentSelectedColor: "#C87DD4",
  dividerColor: "#87A3C2",
  thumbScrollColor: "#898989",
  tableHeaderColor: "#c5dbf6",
  touchRipple: "#000000",
  salesOrderActivityHeaderColor: "#B6C193",
  salesOrderActivityBackgroundColor: "#EBF3D4",
  purchaseOrderActivityHeaderColor: "#A4D2FC",
  purchaseOrderActivityBackgroundColor: "#E6F0F9",
  shipmentActivityHeaderColor: "#E5BFEC",
  shipmentActivityBackgroundColor: "#F6E6F9",
  proceedButtonColor: "#218CFF",
  onHoverBackground: "#fffce6",
  yellowOnHover: "#FEFCE7",
  mediumGray: "#BBC7D4",
  radioCheck: "#5D92F4",
  yellowStar: "#F3CA3E",
  yellowHighlight: "#ffff00",
  navigationActiveColor: "#CFE4FB",
  totalDividerColor: "#578EC7",
  blueButton: "#2476C0",
};

export const boxShadowColor = {
  yellow: "rgba(255, 236, 215, 0.7)",
  yellowActive: "rgba(255, 236, 215, 1)",
  red: "rgba(224, 32, 32, 0.1)",
  redActive: "rgba(224, 32, 32, 0.25)",
  orange: "rgba(250, 153, 23, 0.1)",
  orangeActive: "rgba(250, 153, 23, 0.25)",
  green: "rgba(48, 213, 54, 0.1)",
  greenActive: "rgba(48, 213, 54, 0.25)",
  white: "rgba(189, 189, 189, 0.1)",
  whiteActive: "rgba(189, 189, 189, 0.25)",
  gray: "rgba(189, 189, 189, 0.1)",
  grayActive: "rgba(189, 189, 189, 0.25)",
};
