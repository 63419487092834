import { cx } from "@emotion/css";
import { InputAdornment, MenuItem } from "@mui/material";
import React, { useRef, useState } from "react";

import {
  SalesOrderTemplateTextFieldStyled,
  SelectPopoverStyled,
} from "./styles";
import { EditIcon, WarningVendorIcon } from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import AttentionModal from "../Modal/AttentionModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../../assets/jss/variables";
function SelectButtonIcon({
  onChange,
  data = [],
  onEdit,
  currentTemplate = {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [attentionModalText, setAttentionModalText] = useState("");
  const iconRef = useRef(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? "customized-select-options" : undefined;

  return (
    <>
      {!!attentionModalText && (
        <AttentionModal
          title="Attention"
          description={attentionModalText}
          isOpen={!!attentionModalText}
          onClick={() => {
            setAttentionModalText("");
            setAnchorEl(iconRef.current);
          }}
          cancellable
          confirmationText="Ok"
          onClose={() => setAttentionModalText("")}
        />
      )}
      <SalesOrderTemplateTextFieldStyled
        className="soTemplateTextFieldContainer"
        inputProps={{
          className: "soTemplateFieldInput",
        }}
        InputLabelProps={{
          classes: {
            formControl: "soTemplateFieldLabel",
          },
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ExpandMoreIcon
                className={cx("arrowDropDownIcon", { arrowActive: open })}
                style={{ color: colors.primaryDark, padding: 0 }}
              />
            </InputAdornment>
          ),
          classes: {
            root: "soTemplateFieldInputRoot",
          },
          style: {
            textAlign: "left",
          },
        }}
        ref={iconRef}
        label="Parent Sales Order Template"
        value={
          currentTemplate.id
            ? currentTemplate.emoji + "  " + currentTemplate.name
            : "Select Parent Template"
        }
        aria-readonly={true}
        style={{ width: 250 }}
        onClick={() =>
          setAttentionModalText(
            <span
              style={{
                display: "flex",
                fontSize: 17,
                fontWeight: 400,
                lineHeight: "25px",
                width: 300,
                marginInline: "auto",
              }}
            >
              Changing the Sales Order Template this PO Template is based on
              will erase all the PO tasks’ predecessors
            </span>
          )
        }
        onMouseDown={(event) => event.preventDefault()}
      />
      {open && (
        <SelectPopoverStyled
          className="selectButtonPopoverContainer"
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {data.length === 0 ? (
            <MenuItem className="menu-item">No available templates</MenuItem>
          ) : (
            data.map((option) => (
              <MenuItem
                key={option.value}
                className={cx("menu-item", {
                  selected: currentTemplate.id === option.value,
                })}
                onClick={() => {
                  onChange(option.value);
                  handleClose();
                }}
              >
                {option.isOutdated && <WarningVendorIcon width={14} />}
                <span style={{ width: 20 }}>{option.emoji}</span>
                <TooltipTD className="textFilter" label={option.label}>
                  {option.label}
                </TooltipTD>
                {onEdit && (
                  <span
                    className="editIcon"
                    onClick={(ev) => {
                      onChange(option.value);
                      handleClose();
                      onEdit(option);
                      ev.stopPropagation();
                    }}
                  >
                    <EditIcon width={15} />
                  </span>
                )}
              </MenuItem>
            ))
          )}
        </SelectPopoverStyled>
      )}
    </>
  );
}

export default SelectButtonIcon;
