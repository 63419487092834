import { colors } from "../../assets/jss/variables";
import CustomModal from "../../components/Modal/Modal";
import { screenHeight } from "../../helpers/constants";
import { styled } from "@mui/material";

export const FactoryViewStyled = styled("div")(() => ({
  "&.factoryViewContainer": {
    height: screenHeight,
    overflow: "auto",
    position: "relative",
    zIndex: 0,
    margin: 16,
    "& .contentContainer": {
      display: "flex",
      height: "100%",
      overflowY: "hidden",
      width: "calc(100vw - 480px)",
    },
  },
}));

export const BindFactoryTemplatesStyled = styled(CustomModal)(() => ({
  "&.bind-factory-modal": {
    "& .customModalContainer": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .modalHeader": {
      marginBottom: 24,
    },
    "& .sales-order-template-content": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 8,
      gap: 32,
      "& .sales-order-template-name": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        padding: 8,
        "& .emoji": {
          fontSize: 20,
        },
        "& .template-name": {
          fontSize: 14,
          fontWeight: 400,
          color: colors.primaryDark,
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          wordBreak: "break-word",
          maxWidth: 320,
        },
      },
      "& .label-emoji-container .textFilter": {
        maxWidth: 151,
      },
    },
    "& .action-buttons-container": {
      display: "flex",
      justifyContent: "center",
      paddingTop: 24,
      gap: 16,
      height: "auto",
    },
    "& .bind-factory-template-body": {
      display: "flex",
      flexDirection: "column",
      maxHeight: "70vh",
      overflow: "auto",
    },
  },
}));
