import { doc, increment, setDoc } from "firebase/firestore";
import { dbTables } from "../../api/types/dbTables";
import { firestore } from "../../firebase";
import {
  PO_SCOPE,
  SHIPMENT_SCOPE,
  SO_SCOPE,
} from "./ActivityStream/ActivityHelpers";


export const checkOrderMention = ({
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  userMentions = {},
}) => {
  const salesOrderMentions = userMentions[dbTables.SALES_ORDERS] || {};
  const purchaseOrderMentions = userMentions[dbTables.PURCHASE_ORDERS] || {};
  const shipmentMentions = userMentions[dbTables.SHIPMENTS] || {};
  let mention = 0;
  mention += salesOrderMentions[salesOrder.id] || 0;
  mention += purchaseOrderMentions[purchaseOrder.id] || 0;
  mention += shipmentMentions[currentShipment.id] || 0;
  return mention;
};

export const getPathByScope = ({
  currentScope,
  companyId,
  currentOrder,
  activity,
}) => {
  if (SO_SCOPE.includes(currentScope)) {
    return `${companyId}/${dbTables.SALES_ORDERS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  } else if (PO_SCOPE.includes(currentScope)) {
    return `${companyId}/${dbTables.PURCHASE_ORDERS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  } else if (SHIPMENT_SCOPE.includes(currentScope)) {
    return `${companyId}/${dbTables.SHIPMENTS}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  }
};

export const updateUserMentionDoc = ({ newMentions = {}, currentOrder }) => {
  Object.keys(newMentions).map((userId) => {
    const path = `${dbTables.USERS}/${userId}/${dbTables.MENTIONS}/${currentOrder.ref.parent.id}`;
    return setDoc(
      doc(firestore, path),
      {
        [currentOrder.id]: increment(1),
      },
      { merge: true }
    );
  });
};
