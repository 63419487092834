import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { EditableLabelStyled } from "./styles";
import { cx } from "@emotion/css";

function SimpleEditableNumber({
  number = 0,
  onSave = () => {},
  onCancel = () => {},
  className = "",
  allowDecimal = false,
  allowNegative = false,
  disable = false,
  showEditIcon = false,
  allowZero = true,
}) {
  const [value, setValue] = useState("");
  const [editing, setEditing] = useState(false);
  const [activeOnBlur, setActiveOnBlur] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      setValue(number);
      inputRef.current.focus();
      inputRef.current.select();
      setActiveOnBlur(true);
    }
  }, [editing, number]);

  function handleSave() {
    if (value === number || !value.trim()) {
      handleCancel();
    } else {
      let parseValue;
      if (allowDecimal) {
        parseValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      } else {
        parseValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
      }
      const ev = { target: { value: parseValue } };
      onSave(ev);
      setEditing(false);
    }
  }

  const handleCancel = () => {
    onCancel();
    setValue(number);
    setEditing(false);
  };

  return (
    <EditableLabelStyled className={cx("containerEditableLabel", className)}>
      {editing ? (
        <input
          id="number-editable-label"
          type="number"
          ref={inputRef}
          className="editableLabel"
          value={value}
          onChange={(e) => {
            let { value } = e.target;
            if (!allowDecimal) {
              value = value.replaceAll(".", "");
            }
            if (!allowNegative) {
              value = value.replaceAll("-", "");
            }
            if (!allowZero) {
              value = value < 1 ? "1" : value;
            }
            setValue(value);
          }}
          autoFocus={true}
          onBlur={(ev) => {
            if (!activeOnBlur) {
              return;
            }
            if (editing) {
              handleSave(value);
            }
            setEditing(false);
            ev.stopPropagation();
          }}
          onKeyUp={(ev) => {
            if (ev.key === "Enter") {
              handleSave();
            } else if (ev.key === "Escape") {
              handleCancel();
            }
            ev.stopPropagation();
          }}
        />
      ) : (
        <>
          <span
            style={{
              cursor: disable ? "default" : "pointer",
              fontWeight: disable ? "400" : "",
              opacity: disable ? 0.5 : 1,
            }}
            className="text-static-name"
            onClick={(ev) => {
              if (!editing && !disable) {
                setTimeout(() => setEditing(true), 50);
                setEditing(true);
              }
              ev.stopPropagation();
            }}
          >
            {number}
          </span>
          {!disable && showEditIcon && (
            <EditIcon
              className={"edit-icon"}
              onClick={(ev) => {
                setTimeout(() => setEditing(true), 50);
                setEditing(true);
                ev.stopPropagation();
              }}
            />
          )}
        </>
      )}
    </EditableLabelStyled>
  );
}

export default SimpleEditableNumber;
