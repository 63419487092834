import { getRandomId } from "../../helpers/helpers";
import { now } from "moment";

export default class SalesOrderTemplateObj {
  constructor({
    id = getRandomId(),
    name = "",
    description = "",
    creationDate = now(),
    createdBy = "",
    currentVersionId = "",
    emoji
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.creationDate = creationDate;
    this.createdBy = createdBy;
    this.currentVersionId = currentVersionId;
    this.emoji = emoji;
  }
}
