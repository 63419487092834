import React, { useState, useEffect } from "react";
import { InboxListContainer, InboxTableContainer } from "./styles";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import { dbTables, globalScopes } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/helpers";
import { useIsAllowedFunction } from "../../hooks/permissions";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import {
  generalPermissions,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { InboxHeaderSetup } from "../../helpers/screenHeaderSetup";
import { useUser } from "../../hooks/user";
import { useCustomers } from "../../hooks/customers";
import AttentionModal from "../../components/Modal/AttentionModal";

import { useFeatureFlags } from "../../hooks/featureFlags";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import InboxTableRow from "./InboxTableRow";
import { inboxHeaderCells } from "../../components/Managers/TableHeaderProvider";
import {
  getFormattedItems,
  getItemsAmount,
} from "../../helpers/orderDashboardRefactored";
import numeral from "numeral";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import BasicPagination from "../../components/Tables/BasicPagination";

function InboxScreen() {
  const { get, set } = useJSONLocalStorage("InboxListFilters");
  const dataLocalStorage = get() || {};
  const user = useUser();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    dataLocalStorage.rowsPerPage || 10
  );
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();
  const customers = useCustomers();
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [salesOrders, setSalesOrders] = useState([]);
  const [soTemplates, setSOTemplates] = useState([]);
  const [headerCells, setHeaderCells] = useState([]);
  const [openModalData, setOpenModalData] = useState({
    soTemplate: {},
    open: false,
  });
  const featureFlags = useFeatureFlags({ companyId });

  async function getSalesOrders() {
    setLoading(true);
    try {
      const salerOrderSnapDB = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDERS}`
          ),
          where("status", "==", SALES_ORDER_STATUS.INBOX)
        )
      );
      const soTemplatesSnap = await getDocs(
        query(
          collection(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SALES_ORDER_TEMPLATES}`
          )
        )
      );
      setSOTemplates(
        soTemplatesSnap.docs.map((soTemplate) => ({
          ...soTemplate.data(),
          ref: soTemplate.ref,
        }))
      );

      setSalesOrders(
        salerOrderSnapDB.docs.map((so) => {
          const currentSO = so.data();
          const formattedItems = getFormattedItems({
            items: currentSO.items,
            scope: globalScopes.SALES_ORDER,
            orderId: currentSO.id,
          });
          const discount = parseFloat(currentSO.discount || 0);
          let total = getItemsAmount({ items: formattedItems });
          const totalDiscount = (total * discount) / 100;
          if (discount) {
            total = total - totalDiscount;
          }

          const currentCustomer =
            customers.find(
              (customer) => customer.id === currentSO.customerId
            ) || "";
          return {
            ...currentSO,
            ref: so.ref,
            customerName: currentCustomer.name || "",
            totalString: numeral(total).format("$0,0.00"),
            total: total,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  useEffect(() => {
    getSalesOrders();
    set({ ...nonLookUpfilters });
  }, []);

  useEffect(() => {
    function onAdjustHeader() {
      let userHeaderCell = InboxHeaderSetup;
      userHeaderCell = userHeaderCell.map((header) => {
        const headerValue = inboxHeaderCells[header.name];
        if (
          headerValue &&
          headerValue[generalPermissions.SALES_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...headerValue,
            styles: {
              ...inboxHeaderCells[header.name].styles,
              width: header.width || 0,
              display: "none",
            },
          };
        }
        return {
          ...header,
          ...inboxHeaderCells[header.name],
          styles: {
            ...inboxHeaderCells[header.name].styles,
            width: header.width || 0,
          },
        };
      });

      let headerCellsCpy = [...userHeaderCell];
      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [
    nonLookUpfilters,
    salesOrders,
    isAllowed(generalPermissions.SALES_ORDER_AMOUNT),
  ]);

  useEffect(() => {
    set({ ...nonLookUpfilters });
  }, [nonLookUpfilters]);

  const setTemplate = async () => {
    setLoading(true);
    const { soTemplate = {}, salesOrder = {} } = openModalData;
    try {
      const functions = getFunctions();
      const buildingTaksEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "buildingTasks",
          env: globalEnvironment,
          params: "/salesOrder",
        })
      );
      const result = await buildingTaksEndpoint({
        companyId,
        salesOrderId: salesOrder.id,
        templateId: soTemplate.id,
      });
      const data = result.data;
      if (data.status === 400) {
        setOpenModalData({
          open: true,
          message: data.message,
          error: true,
        });
      } else {
        setSalesOrders((oldData) =>
          oldData.map((soData) => ({
            ...soData,
            templateId:
              soData.id === salesOrder.id
                ? soTemplate.id
                : soData.templateId || "",
            status:
              soData.id === salesOrder.id
                ? SALES_ORDER_STATUS.IN_PROGRESS
                : soData.status,
          }))
        );
        setOpenModalData({
          open: true,
          message: "Sales Order Template successfully assigned",
          error: true,
          hiddenTitle: true,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("error");
      setOpenModalData({
        open: true,
        message: "Error",
        error: true,
      });
      setLoading(false);
    }
  };

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  const getAttentionText = () => {
    const { soTemplate = {}, salesOrder = {} } = openModalData;
    return (
      <div
        style={{
          width: 400,
          marginInline: "auto",
          fontSize: 17,
          fontWeight: 400,
          lineHeight: "25px",
        }}
      >
        {loading && (
          <LoadingBackdrop
            size={60}
            backdropStyle={{
              position: "fixed",
            }}
            withLogo={true}
          />
        )}
        <span>
          Are you sure you want to use
          <br />
          {`${soTemplate.emoji || ""} ${soTemplate.name || ""}`}
          <br />
          as the Sales Order Template for
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <SalesOrderBadge
              number={salesOrder.number}
              style={{ pointerEvents: "none" }}
            />
            {`${salesOrder.customerName}`}
          </div>
        </span>
        <br />

        <span style={{ color: "#FF5F58" }}>This cannot be undone</span>
      </div>
    );
  };

  return (
    <>
      {openModalData.open && (
        <AttentionModal
          title={openModalData.hiddenTitle ? "" : "Attention"}
          isOpen={openModalData.open}
          description={openModalData.message || getAttentionText()}
          onClose={() => {
            if (!loading) {
              setOpenModalData({ open: false });
            }
          }}
          onClick={() => {
            if (!openModalData.error) {
              setTemplate();
            } else {
              setOpenModalData({ open: false });
            }
          }}
          cancellable={!openModalData.error}
          confirmationText={openModalData.error ? "Ok" : "Procced"}
          acceptBlue
          styleModal={{ height: !openModalData.error ? 380 : "" }}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <InboxListContainer className="root">
        <div className="contentContainer">
          <InboxTableContainer className="contentListInbox">
            <div className={"tableContainer"}>
              <MainTableRefactor
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={salesOrders
                  .sort(
                    sortObjectsBy(
                      nonLookUpfilters.sortedColumn,
                      nonLookUpfilters.orderBy !== "ASC"
                    )
                  )
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                nonLookUpfilters={nonLookUpfilters}
                emptyText={"No pending Sales Orders"}
                renderRow={(item, index) => {
                  return (
                    <InboxTableRow
                      key={item.id + index}
                      item={item}
                      user={user}
                      isAllowed={isAllowed}
                      customers={customers}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      onOpenAttentionModal={setOpenModalData}
                      featureFlags={featureFlags}
                      soTemplates={soTemplates}
                    />
                  );
                }}
                filters={nonLookUpfilters}
                onUpdateFilters={(nonLookUpfilters) => {
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
              />
            </div>
            <BasicPagination
              style={{ bottom: 0 }}
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(salesOrders, rowsPerPage)}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(+event.target.value);
                setPage(1);
              }}
            />
          </InboxTableContainer>
        </div>
      </InboxListContainer>
    </>
  );
  function getCountPagination(salesOrders, rowsPerPage) {
    return Math.ceil(salesOrders.length / rowsPerPage);
  }
}

export default InboxScreen;
