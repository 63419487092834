import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { Popover, styled } from "@mui/material";
import CustomModal from "../Modal/Modal";
import { dbTables } from "../../api/types/dbTables";
/* eslint-disable no-useless-computed-key */

export const DragAndDropStyled = styled("div")(() => ({
  "&.styled_dnd": {
    padding: "9px 34px",
  },
  "& .dragDropContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 2,
    height: 46,
    border: "2px dashed #ccc",
    backgroundColor: "#fff",
    borderRadius: 4,
    "& p": {
      fontSize: 14,
      color: "#828282",
      lineHeight: "20px",
      margin: 0,
    },
  },
  "& .activeDrop": {
    height: 50,
    backgroundColor: "transparent !important",
    "& p": {
      fontSize: 16,
    },
  },
  [`& .dnd${dbTables.SALES_ORDERS}`]: {
    borderColor: colors.salesOrderBorderColor,
    backgroundColor: "#cfdba8",
  },
  [`& .dnd${dbTables.PURCHASE_ORDERS}`]: {
    borderColor: colors.purchaseOrderBorderColor,
    backgroundColor: "#cee1f3",
  },
  [`& .dnd${dbTables.SHIPMENTS}`]: {
    borderColor: colors.shipmentBorderColor,
    backgroundColor: "#EABFF2",
  },
}));


export const PopoverSortingStyled = styled(Popover)(() => ({
  "& .popoverPaper": {
    boxShadow: "0px 10px 50px 3px rgba(1,31,64,0.59)",
    background: "#F7F9FC",
  },
  "& .list": {
    display: "flex",
    flexDirection: "column",
    color: colors.primaryDark,
    "& .option": {
      padding: 15,
      "&:hover": {
        cursor: "pointer",
        background: "#f5f5f5",
      },
    },
    "& .selected": {
      boxShadow: "4px 0px 0px 0px #218cff inset",
    },
  },
}));

export const ManagementDocumentHeaderStyled = styled("div")(() => ({
  "&.documentsHeader": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 45,
    padding: "0px 50px 0px 33px",
    color: "#25486d",
    gap: 10,
  },
  "& .iconContainer": {
    cursor: "pointer",
    height: 36,
    width: 36,
    minWidth: 36,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: colors.backgroundWhite,
      "& .expandRow": {
        "& path": {
          strokeWidth: 2.5,
        },
      },
    },
    "&:active": {
      background: colors.backgroundWhiteAction,
      "& .expandRow": {
        "& .item1": {
          transform: "translateY(1px)",
        },
        "& .item3": {
          transform: "translateY(-1px)",
        },
        "& path": {
          strokeWidth: 3,
        },
      },
    },
  },
  [`& .${dbTables.SALES_ORDERS}Button`]: {
    "&:hover": {
      background: colors.backgroundGreen,
    },
    "&:active": {
      background: colors.backgroundGreenAction,
    },
  },
  [`& .${dbTables.PURCHASE_ORDERS}Button`]: {
    "&:hover": {
      background: colors.backgroundBlue,
    },
    "&:active": {
      background: colors.backgroundBlueAction,
    },
  },
  [`& .${dbTables.SHIPMENTS}Button`]: {
    "&:hover": {
      background: colors.backgroundPink,
    },
    "&:active": {
      background: colors.backgroundPinkAction,
    },
  },
  "& .sortOrderButton": {
    background: "white",
    color: "#25486d",
    border: "1px solid #25486d",
    height: 26,
    fontSize: 10,
    fontWeight: 500,
    padding: 5,
    width: 70,
    minWidth: "70px !important",
    borderRadius: 4,
    lineHeight: "16px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "white",
    },
  },
  "& .documentsHeaderTitleDocument": {
    fontSize: 19,
    fontWeight: 500,
  },
  "& .documentsHeaderButtonAddDocument": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "70%",
    gap: 10,
    marginLeft: "auto",
    "& .addNewFolder": {
      margin: 4,
      "&:active": {
        transform: "scale(0.9)",
      },
      "&:hover": {
        cursor: "pointer",
      },
      "& span": {
        fontSize: 13,
        fontWeight: 600,
        paddingLeft: 4,
      },
    },
  },
  "& .imgNewFolderPlaceholder": {
    width: 15,
    height: 16,
    paddingBottom: 1,
  },
  "& .documentsIconAddDocument": {
    cursor: "pointer",
  },
}));

export const ListItemDocumentStyled = styled("div")(() => ({
  "&.listItemDocumentContainer": {
    "& .listDocumentsEffect": {
      "& .img-menu-options": {
        opacity: 0,
        cursor: "pointer",
      },
      "&:hover": {
        borderBottom: 0,
        "& .img-menu-options": {
          opacity: 1,
        },
        "& .shipmentBadgeDocument": {
          border: "2px solid #CB5EDE",
        },
        "& .fileinfo": {
          fontWeight: 500,
        },
        "& .filename": {
          fontWeight: 700,
        },
        "& .versionSquareSO": {
          outline: `3px solid ${colors.salesOrderBorderColor}`,
          fontWeight: 600,
        },
        "& .versionSquarePO": {
          outline: `3px solid ${colors.purchaseOrderBorderColor}`,
          fontWeight: 600,
          background: "#DDEFFF",
        },
        "& .versionSquareSH": {
          outline: "3px solid #CB5EDE",
          fontWeight: 600,
        },
        "& .versionSquareWHITE": {
          background: "white",
          outline: `3px solid ${colors.dividerColor}`,
          fontWeight: 600,
        },
      },
    },

    "& .listemItemDocument": {
      margin: "0px 10px",

      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
      },
      "& .listItemFile": {
        borderRadius: 4,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,

        "&:hover": {
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },

      "& .listItemFolderClose": {
        border: "1px solid #e5edf7",
        borderRadius: 4,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },

      "& .listItemFolderOpen": {
        marginTop: 10,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 8,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
      },
      "& .listItemFolderCloseMainFile": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        "&:hover": {
          outline: "4px solid #698EC5",
          outlineOffset: -2,
          borderRadius: 4,
        },
        height: 75,
        margin: "10px 0px",
      },

      "& .F_SALES_ORDER": {
        "&:hover": {
          outline: `4px solid ${colors.salesOrderBorderColor}`,
        },
      },
      "& .F_PURCHASE_ORDER": {
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        },
      },

      "& .F_CUSTOMER": {
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        },
      },

      "& .F_FACTORY": {
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
        },
      },

      "& .SALES_ORDER": {
        border: `2px solid ${colors.salesOrderBorderColor}`,
        background: colors.salesOrderDocBackgroundColor,
        "&:hover": {
          outline: `4px solid ${colors.salesOrderBorderColor}`,
        },
      },
      "& .PURCHASE_ORDER": {
        border: `2px solid ${colors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderDocBackgroundColor,
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
          background: "#DDEFFF",
        },
      },

      "& .CUSTOMER": {
        border: `2px solid #e5edf7`,
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
          background: "#DDEFFF",
        },
      },

      "& .FACTORY": {
        border: `2px solid #e5edf7`,
        "&:hover": {
          outline: `4px solid ${colors.purchaseOrderBorderColor}`,
          background: "#DDEFFF",
        },
      },
    },

    "& .dotsImage": {
      transform: "rotate(90deg)",
    },

    "& .listItemIcon": {
      minWidth: 0,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& img": {
        margin: "0px 6px 15px 14px",
      },
    },
    "& .imgListItemIcon": {
      marginLeft: 16,
    },
    "& .leftContainerLineDivider": {
      width: "2%",
      marginTop: -30,
    },
    "& .dividerPlaceholderDocuments": {
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 5px",
    },
    "& .labelVersionSmallCenter": {
      width: 24,
      height: 18,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 12,
      fontWeight: 400,
      position: "absolute",
      left: -16,
      paddingTop: 3,
      background: "#FFF",
    },
    "& .labelVersionBotton": {
      border: "2px solid #e5edf7",
      background: "white",
      width: 24,
      height: 16,
      borderRadius: 3,
      textAlign: "center",
      fontSize: 12,
      fontWeight: 400,
      position: "absolute",
      left: -16,
      top: 32,
      paddingLeft: 2,
    },
    "& .listItemDocumentCollapseItemsFolder": {
      display: "flex",
      "& .root-list-content": {
        marginLeft: 16,
      },
    },
    "& .listItemDocumentCollapseItems": {
      "& div": {
        "& ul": {
          marginLeft: 12,
        },
      },
    },
  },
}));

export const CreateNewFolderStyled = styled("div")(() => ({
  "&.listem-item-document": {
    transition: "height 0.3s ease-in-out",
    height: 60,
    padding: "0px 0px 0px 8px",
    opacity: 1,
    display: "flex",
    paddingRight: 38,
    marginLeft: 46,
    gap: 8,
    "& .editable-label": {
      width: "100% !Important",
      margin: "0px 4px !Important",
      "& .input-editable-label": {
        width: "100% !Important",
      },
    },
    "& .itemDataContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    "& .hiddenData": { opacity: 0 },
    "& .listItemIcon": {
      minWidth: 0,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "&.hiddenContainer": {
    height: 0,
    overflow: "hidden",
  },
}));

export const ModalPermissionStyled = styled(CustomModal)(() => ({
  "&.modalClass": {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .filesThreeContainer": {
    maxHeight: "60vh",
    overflow: "auto",
    marginBottom: 5,
    "&::-webkit-scrollbar": {
      width: 6,
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 7,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
  },
  "&.modal-container-permissions": {
    padding: 0,
  },
  "& .dragAndDropModalDialog": {},
  "& .selectAllNoneContainer": {
    fontWeight: 400,
    color: "#1C53B7",
    marginRight: 25,
    fontSize: "0.80em",
    "& .select-all-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    "& .select-none-button": {
      margin: "0px 2px",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  "& .modalTitle": {
    height: 85,
    fontSize: 20,
    textAlign: "center",
    letterSpacing: 0.43,
    color: "#25486d",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .selectDocumentType": {
    color: "#25486d",
    fontFamily: "Roboto",
    fontSize: 20,
    letterSpacing: 0.43,
    textAlign: "center",
    marginBottom: 0,
    maxWidth: 545,
  },
  "& .wrongUploadFileContainer": {
    display: "flex",
    justifyContent: "center",
    paddingTop: 16,
  },
  "& .wrongUploadFile": {
    width: 25,
    height: 25,
  },
  "& .onlyOneFileCanBe": {
    color: "#f36",
    fontFamily: "Roboto",
    fontSize: 16,
    letterSpacing: 0.34,
    marginBottom: 0,
    paddingLeft: 5,
  },
  "& .modalDocumentSectionOnUpload": {
    height: 120,
    background: "#e9f4ff",
    display: "flex",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    justifyContent: "center",
    minHeight: 66,
    "& .filesize-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .filesize": {
        margin: 0,
      },
    },
  },
  "& .modalDocumentSectionUploaded": {
    height: 120,
    background: "#e9f4ff",
    display: "flex",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
    justifyContent: "center",
    minHeight: 100,
    "& .filesize-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .filesize": {
        margin: 0,
      },
    },
  },
  "& .fileInformation": {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  "& .listItemTextPrimary": {
    width: 348,
    paddingLeft: 8,
    "& label": {
      margin: 0,
    },
  },
  "& .filename": {
    fontSize: 14,
    fontWeight: 600,
    color: "#798a9b",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    lineHeight: "16px",
    whiteSpace: "pre-wrap",
    position: "relative",
    maxWidth: 270,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .filesize": {
    fontSize: 14,
    fontWeight: 400,
    color: "#798a9b",
    paddingLeft: 10,
    fontFamily: "Roboto",
    width: 70,
    textAlign: "right",
  },
  "& .listItemTextSecondary": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .fileinfo": {
    fontSize: 12,
    color: "#798a9b",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    margin: 0,
  },
  "& .progressBar": {
    background: "none",
    "& div": {
      backgroundColor: "none",
      borderRadius: 100,
      "& div": {
        backgroundColor: "#39f",
      },
    },
  },
  "& .successfulUpload": {
    width: 12,
    height: 12,
    position: "relative",
    zIndex: 2,
    right: 12,
    background: "white",
    borderRadius: "100%",
    padding: 1,
  },
  "& .modalPermissionsOnUpload": {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 18,
  },
  "& .documentType": {
    width: 220,
    minWidth: 220,
    "& label": {
      display: "block",
      height: 14,
      color: colors.darkGray,
      fontFamily: "Roboto",
      fontSize: 12,
      letterSpacing: 0.26,
      margin: 0,
      fontWeight: 800,
    },
    "& select": {
      marginLeft: 0,
      boxSizing: "border-box",
      height: 42,
      borderRadius: 5,
      backgroundColor: "#fff",
      border: "1px solid #a8b9ca",
      width: "100%",
    },
  },
  "& .documentTypeTitleContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .select-all-none-button": {
      fontSize: 8,
      fontWeight: 500,
      border: "1px solid #25486D",
      padding: 2,
      borderRadius: 5,
      color: colors.primaryDark,
      cursor: "pointer",
    },
  },

  "& .permissionsGroupContainer": {},
  "& .multiselect": {},
  "& .permissionContent": {
    display: "flex",
    padding: "6px 4px",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "auto",
    lineHeight: 1.5,
    "& .labelText": {
      paddingLeft: 8,
      height: "auto",
      fontSize: 12,
      cursor: "pointer",
      display: "flex",
    },
    "& .permissionCheck": {
      cursor: "pointer",
    },
  },
  "& .viewPermissionOnUpload": {
    width: "100%",
    "& label": {
      marginBottom: 12,
      display: "block",
      height: 14,
      width: 100,
      color: colors.darkGray,
      fontFamily: "Roboto",
      fontSize: 12,
      letterSpacing: 0.26,
    },
    "& input": {
      marginLeft: 0,
    },
  },
  "& .modalButtonsSection": {
    height: 85,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .btn-cancel": {
      height: 54,
      width: 119,
      color: "#25486d",
      fontSize: 14,
      background: "rgba(168, 182, 200, 0.15)",
      boxSizing: "border-box",
      borderRadius: 3,
      border: "1px solid #a8b6c8",
    },
    "& .btn-done ": {
      width: 219,
      height: 54,
      background: "#39f",
      color: "#fff",
      fontSize: 14,
      fontWeight: 700,
    },
  },
}));
