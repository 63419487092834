import { cx } from "@emotion/css";
import React, { useState } from "react";
import TooltipTD from "../../Tooltip/TooltipTD";
import defaultImage from "../../../assets/flag-icons/no_image_available.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getRowStyles, getSKUProperties } from "./SKUHelper";
import { formatCash, formatWithComma } from "../../../helpers/helpers";
import itemStatus from "../../../api/types/itemStatus";
import { useQueryParams } from "../../../hooks";
import {
  COMPANY_PERMISSION_VALUE,
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
  generalPermissions,
  itemTableReference,
} from "../../../helpers/constants";
import AttentionModal from "../../Modal/AttentionModal";
import {
  useIsAllowedCompany,
  useIsAllowedFunction,
} from "../../../hooks/permissions";
import { useCustomers } from "../../../hooks/customers";
import { useShipments } from "../../../hooks/shipments";

import SalesOrderItemCells from "./SalesOrderItemCells";
import SKUEditableLabelV2 from "./SKUEditableLabelV2";
import ShipmentItemsCells from "./ShipmentItemsCells";
import { useBackdropState } from "../../../hooks/user";
import ItemsEmptyPOCells from "./ItemsEmptyPOCells";

function CollapsePORow({
  itemSKU,
  showImage,
  handlePhotosViewer,
  entity,
  prefix,
  purchaseOrders,
  onChangeData = () => {},
  onOpenRow = () => {},
  open,
  onOpenModal,
  lastItem,
  index,
}) {
  const {
    description,
    extendedCost,
    image,
    itemNumber,
    quantity,
    purchaseOrder = {},
    totalRows,
    salesOrders = [],
    unitCost,
    shipmentIds,
  } = getSKUProperties({ itemSKU, prefix, entity, purchaseOrders });
  const shipmentsDB = useShipments();
  const isCompanyAllowed = useIsAllowedCompany();
  const isAllowed = useIsAllowedFunction();
  const mismatch = itemSKU.mismatchStatus || {};
  const queryParams = useQueryParams();
  const [modalAttention, setModalAttention] = useState(false);
  const isReadOnly =
    itemSKU.status === itemStatus.READ_ONLY ||
    entity.status === SALES_ORDER_STATUS.VOIDED;
  const isAllowedToShowPrice = isAllowed(
    generalPermissions.PURCHASE_ORDER_AMOUNT
  );
  const customers = useCustomers();
  const backdrop = useBackdropState();

  return (
    <div
      className={cx("itemSKURow", { withImage: showImage, lastItem })}
      key={itemSKU.id + index + "itemSKURow"}
      style={getRowStyles({
        open,
        showImage,
        totalRows,
        backdropStyle: backdrop?.id?.includes(itemSKU.id),
      })}
      id={`purchaseOrder_${itemSKU.id}`}
    >
      <div className={cx("cellRow", "arrowContainer")}>
        {totalRows > 1 && (
          <div
            className="arrowHover"
            onClick={() => onOpenRow(itemSKU.id, !open)}
          >
            <KeyboardArrowDownIcon
              className="arrow"
              style={{
                transform: !open ? "rotate(-90deg)" : "none",
                color: mismatch.shipment ? "red" : "",
              }}
            />
          </div>
        )}
      </div>
      {modalAttention && (
        <AttentionModal
          title="Attention"
          description={"This item is not part of any PO in this project"}
          isOpen={modalAttention}
          onClick={() => setModalAttention(false)}
        />
      )}
      <div className={"cellRow"}>{itemNumber}</div>
      <div className={cx("cellRow")}>
        {showImage && (
          <img
            className="image"
            src={image}
            alt={`${itemNumber}Img`}
            onClick={() => {
              handlePhotosViewer({
                open: true,
                photos: [
                  ...itemSKU.image.map((el) => ({
                    src: el || defaultImage,
                    number: itemSKU.itemNumber,
                    description: itemSKU.description,
                  })),
                ],
              });
            }}
          />
        )}
      </div>
      <div
        className={cx("customized-tooltip", {
          hiddenPrice: !isAllowedToShowPrice,
        })}
        id={description}
      >
        <TooltipTD label={description} className="tooltTipSpan">
          {description}
        </TooltipTD>
      </div>

      <div
        id="SKU_quantity"
        className={cx("cellRow", "alignRight", {
          dangerStyle: mismatch.purchaseOrder || mismatch.salesOrder,
        })}
      >
        <SKUEditableLabelV2
          showToolTip={mismatch.purchaseOrder || mismatch.salesOrder}
          value={quantity}
          tooltipLabel={
            "This item quantity does not match the quantity in the Sales Order"
          }
          disabled={
            !isCompanyAllowed(
              COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_QUANTITY
            ) || isReadOnly
          }
          preventDelete
          labelFormat={(value) => formatWithComma(value)}
          onChangeLabel={(temporalLabel) => {
            onChangeData({
              item: itemSKU,
              row: {
                ...itemSKU,
                purchaseOrderQuantity: temporalLabel,
              },
              reference: itemTableReference.ITEM,
            });
          }}
        />
      </div>
      {isAllowedToShowPrice && (
        <div id="SKU_unitCost" className={cx("cellRow", "alignRight")}>
          <SKUEditableLabelV2
            value={unitCost}
            disabled={
              !isCompanyAllowed(
                COMPANY_PERMISSION_VALUE.PURCHASE_ORDER_PRICE
              ) || isReadOnly
            }
            preventDelete
            inputType="cash"
            labelFormat={(value) => formatCash(value)}
            onChangeLabel={(temporalLabel) => {
              onChangeData({
                item: itemSKU,
                row: {
                  ...itemSKU,
                  unitCost: temporalLabel,
                },
                reference: itemTableReference.ITEM,
              });
            }}
          />
        </div>
      )}
      {isAllowedToShowPrice && (
        <div
          id="SKU_extendedCost"
          className={cx("cellRow", "alignRight", "extendedCost")}
        >
          {formatCash(extendedCost)}
        </div>
      )}
      {salesOrders.length === 0 && <ItemsEmptyPOCells />}
      {salesOrders.map((salesOrderItem = {}, soIndex, soList) => {
        const shipments = salesOrderItem.shipments || [];
        const showSOIcon = soList.length > 1 ? !open : false;
        return (
          <>
            <SalesOrderItemCells
              customers={customers}
              itemSKU={itemSKU}
              open={open}
              shipments={shipments}
              salesOrderItem={salesOrderItem}
              totalRows={totalRows}
              key={salesOrders.id + itemSKU.id + index + soIndex}
              queryParams={queryParams}
              onChangeData={onChangeData}
              isAllowed={isCompanyAllowed(
                COMPANY_PERMISSION_VALUE.SALES_ORDER_QUANTITY
              )}
              isReadOnly={isReadOnly}
              firstSO={soIndex === 0}
              showIcon={showSOIcon}
              onOpenRow={onOpenRow}
              soList={soList}
            />
            {shipments.map((shipment, shipmentIndex, shipmentList) => (
              <ShipmentItemsCells
                key={shipment.id + itemSKU.id + index + shipmentIndex + soIndex}
                salesOrdersList={soList}
                shipmentList={shipmentList}
                shipmentIds={shipmentIds}
                skuIndex={soIndex + shipmentIndex}
                totalRows={totalRows}
                shipment={shipment}
                shipmentsDB={shipmentsDB}
                itemSKU={itemSKU}
                purchaseOrder={purchaseOrder}
                salesOrderItem={salesOrderItem}
                isAllowedToCreate={
                  isAllowed(GENERAL_PERMISSION_VALUE.CREATE_SHIPMENTS) &&
                  !purchaseOrder.isOutdated
                }
                isCompanyAllowed={isCompanyAllowed(
                  COMPANY_PERMISSION_VALUE.SHIPMENT_QUANTITY
                )}
                onOpenModal={onOpenModal}
                queryParams={queryParams}
                onChangeData={onChangeData}
                lastItem={
                  !open
                    ? shipmentIndex === 0
                    : shipmentIndex === shipmentList.length - 1 &&
                      soList.length - 1 === soIndex
                }
                showIcon={
                  shipmentList.length > 1 ||
                  (soList.length > 1 && shipmentIds.length > 0)
                    ? !open
                    : false
                }
                onOpenRow={onOpenRow}
                shipmentIndex={shipmentIndex}
                lastSO={soList.length - 1 === soIndex}
                showOnlySOIcon={
                  showSOIcon && shipmentList.length <= 1 && shipmentIndex === 0
                }
              />
            ))}
          </>
        );
      })}
    </div>
  );
}

export default CollapsePORow;
