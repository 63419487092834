import React, { useContext } from "react";
import { TableContentSetupStyled } from "./styles";
import {
  GANTTCHART_TABLE_SIZE,
  GANTT_CHART_ACTION,
  GanttChartContext,
  TaskTableSize,
} from "./GanttChartContext";
import { viewOptionLabels } from "../../helpers/timelineCalendar";
import { Button, ButtonGroup } from "@mui/material";
import undoIcon from "../../assets/flag-icons/undo-filled-icon.png";
import Milestone from "../../api/model/milestone";
import moment from "moment";
import CalendarDisplayOptions from "./CalendarDispalyOptions";
import { labelTaskPhases } from "../../helpers/constants";
import TaskTableHeader from "./TaskTableHeader";
import ViewOptionsV2 from "./ViewOptions";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import Milestones from "./Milestones";
import { GANTT_CHART_SETTINGS } from "../../helpers/ganttChart";
import { DoubleVerticalArrowIcon } from "../../helpers/iconSvgPath";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import { useUser } from "../../hooks/user";
import { useStorage } from "../../hooks";

function TableContentSetup({
  canRedo,
  canUndo,
  undoTasksState,
  redoTasksState,
}) {
  const user = useUser();
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const {
    collapseListTask,
    tableSize,
    viewOptions,
    taskPhases,
    milestones,
    calendarSetup,
    currentSO,
  } = ganttChartState;
  const [, setStorage] = useStorage(currentSO.id);
  function handleChangeCalendarSetup(prevCalendarSetup, calendarSetup) {
    const ganttWrapperEl = document.getElementById("table-content-graph");
    const scrollLeft = ganttWrapperEl.scrollLeft;
    const scrollTop = ganttWrapperEl.scrollTop;
    const prevState = GANTT_CHART_SETTINGS[prevCalendarSetup];
    const nextState = GANTT_CHART_SETTINGS[calendarSetup];
    const scrollLeftToGo = (scrollLeft / prevState) * nextState;
    setStorage("calendarSetup", calendarSetup);
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { calendarSetup: calendarSetup, reRenderArrow: false },
    });
    setTimeout(() => {
      ganttWrapperEl.scrollTo({
        top: scrollTop,
        left: scrollLeftToGo,
        behavior: "smooth",
      });
    }, 5);
  }
  const isAllPhasesOpen = Object.values(taskPhases).every((el) => el);

  function handleChangeMilestone(milestone) {
    let milestonesCpy = [...milestones];
    milestonesCpy = milestonesCpy.map((milestoneCpy) => {
      if (milestoneCpy.id === milestone.id) {
        return milestone;
      }
      return milestoneCpy;
    });
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { milestones: milestonesCpy },
    });
  }

  function handleDeleteMilestone(milestoneId) {
    let milestonesCpy = [...milestones];
    milestonesCpy = milestonesCpy.filter(
      (milestoneCpy) => milestoneCpy.id !== milestoneId
    );
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { milestones: milestonesCpy },
    });
  }

  function handleAddNewMilestone() {
    const newMilestone = new Milestone({
      color: "#D46BEE",
    });
    const milestonesCpy = [...milestones];
    milestonesCpy.push({ ...newMilestone });
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: { milestones: milestonesCpy },
    });
  }

  function handleCloseMilestone(milestones) {
    let milestonesToUpdate = milestones.filter(
      (milestone) => !milestone.isToday
    );
    milestonesToUpdate = milestonesToUpdate.map((milestone) => ({
      ...milestone,
      date: moment(milestone.date).valueOf(),
    }));
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        milestonesToSave: milestonesToUpdate,
      },
    });
  }

  function handleChangeViewOptions(option) {
    let viewOptionsArrayCpy = [...viewOptions];
    let tableSizeCpy = tableSize;
    viewOptionsArrayCpy = viewOptionsArrayCpy.map((el) => {
      if (option.label === viewOptionLabels.FULL_TABLE_DETAILS) {
        if (option.enabled) {
          tableSizeCpy = GANTTCHART_TABLE_SIZE.LARGE;
        } else {
          tableSizeCpy = GANTTCHART_TABLE_SIZE.MEDIUM;
        }
      }
      if (el.id === option.id) {
        return option;
      }
      return el;
    });
    const path = `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.GANTT_CHART_VIEW_OPTIONS}`;
    setDoc(
      doc(firestore, path),
      {
        id: USER_CUSTOM_SETTINGS.GANTT_CHART_VIEW_OPTIONS,
        viewOptions: viewOptionsArrayCpy,
      },
      { merge: true }
    );
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        viewOptions: viewOptionsArrayCpy,
        tableSize: tableSizeCpy,
      },
    });
  }

  function handleExpandCollapseAllPhases() {
    if (isAllPhasesOpen) {
      return dispatchGanttChart({
        type: GANTT_CHART_ACTION.COMMON,
        payload: {
          taskPhases: {
            [labelTaskPhases.PROPOSAL]: false,
            [labelTaskPhases.PRE_PRODUCTION]: false,
            [labelTaskPhases.PRODUCTION]: false,
            [labelTaskPhases.BOOKING_TRANSIT]: false,
            [labelTaskPhases.PAYMENT_BILLING]: false,
          },
          reRenderArrow: false,
        },
      });
    }
    return dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          [labelTaskPhases.PROPOSAL]: true,
          [labelTaskPhases.PRE_PRODUCTION]: true,
          [labelTaskPhases.PRODUCTION]: true,
          [labelTaskPhases.BOOKING_TRANSIT]: true,
          [labelTaskPhases.PAYMENT_BILLING]: true,
        },
        reRenderArrow: false,
      },
    });
  }
  const width = collapseListTask
    ? TaskTableSize[GANTTCHART_TABLE_SIZE.SMALL]
    : TaskTableSize[tableSize];

  const isSmall = tableSize === GANTTCHART_TABLE_SIZE.SMALL || collapseListTask;

  const undoRedoButtons = () => {
    return (
      <ButtonGroup>
        <Button
          className={cx("buttonViewOptions", "buttonGroup", "firstButton", {
            smallButton: collapseListTask,
          })}
          variant="contained"
          color="primary"
          onClick={undoTasksState}
          disabled={!canUndo}
        >
          <TooltipTD
            showToolTip={isSmall}
            label={"Undo"}
            className="tooltipContainer"
          >
            <img className="img-undo" src={undoIcon} alt="undo" />
            <span className="labelOption">Undo</span>
          </TooltipTD>
        </Button>
        <Button
          className={cx("buttonViewOptions", "buttonGroup", "lastButton", {
            smallButton: collapseListTask,
          })}
          variant="contained"
          color="primary"
          onClick={redoTasksState}
          disabled={!canRedo}
        >
          <TooltipTD
            label={"Redo"}
            showToolTip={isSmall}
            className="tooltipContainer"
          >
            <span className="labelOption">Redo</span>
            <img
              className="img-redo"
              src={undoIcon}
              alt="redo"
              style={{ transform: "rotateY(180deg)" }}
            />
          </TooltipTD>
        </Button>
      </ButtonGroup>
    );
  };

  const viewOptionsButton = () => (
    <ViewOptionsV2
      viewOptionsArray={viewOptions}
      collapseListTask={collapseListTask}
      onChangeViewOptions={handleChangeViewOptions}
    />
  );

  const milestonesButton = () => (
    <Milestones
      milestones={milestones}
      tableSize={tableSize}
      onChangeMilestone={handleChangeMilestone}
      onDeleteMilestone={handleDeleteMilestone}
      onAddNewMilestone={handleAddNewMilestone}
      onCloseMilestone={handleCloseMilestone}
      collapseListTask={collapseListTask}
    />
  );

  const timeframeButton = () => (
    <CalendarDisplayOptions
      calendarSetup={calendarSetup}
      onChangeCalendarSetup={handleChangeCalendarSetup}
      collapseListTask={collapseListTask}
      isTableSmall={isSmall}
    />
  );

  const expandCollapseButton = () => {
    const textValue = isAllPhasesOpen ? "Close All Phases" : "Open All Phases";
    return (
      <Button
        className={cx("buttonViewOptions", { smallButton: collapseListTask })}
        variant="contained"
        color="primary"
        onClick={handleExpandCollapseAllPhases}
      >
        <TooltipTD
          label={textValue}
          showToolTip={isSmall}
          className={"tooltipContainer"}
        >
          <DoubleVerticalArrowIcon
            className={cx("verticalArrowsIcon", {
              showPoIcon: !isAllPhasesOpen,
            })}
          />
          <span className="labelOption">{textValue}</span>
        </TooltipTD>
      </Button>
    );
  };

  const showHeader =
    tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask;

  return (
    <TableContentSetupStyled
      className="tableContentSetup"
      style={{
        width: width,
        minWidth: width,
      }}
    >
      <div
        className={cx("setup-container", {
          "setup-container-collapse": collapseListTask,
          "setup-container-big":
            tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask,
          "setup-container-mid":
            tableSize === GANTTCHART_TABLE_SIZE.MEDIUM && !collapseListTask,
        })}
      >
        {viewOptionsButton()}
        {expandCollapseButton()}
        {milestonesButton()}
        <div
          className={
            tableSize === GANTTCHART_TABLE_SIZE.MEDIUM && !collapseListTask
              ? "midPosition"
              : ""
          }
        >
          {undoRedoButtons()}
        </div>

        {timeframeButton()}
      </div>
      <TaskTableHeader showHeader={showHeader} />
    </TableContentSetupStyled>
  );
}

export default TableContentSetup;
