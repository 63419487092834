import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GanttChartTemplateContext } from "../../CompanyTabs/SalesOrderTemplateContainer";
import {
  GANTT_CHART_ACTION,
  GANTTCHART_TABLE_SIZE,
} from "../../Timeline/GanttChartContext";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import DropContainer from "./DropContainer";
import { labelTaskPhases, STAGE_LIST } from "../../../helpers/constants";
import { taskListByStage } from "../soTemplateHelper";
import { TASK_TEMPLATE_OPTIONS } from "../../../helpers/salesOrderHelpers";
import { useCompanyUsers } from "../../../hooks/user";
import { debounce } from "lodash";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  getReorderedTasks,
  handleDragOverState,
  validateDragOver,
} from "../../../helpers/taskTemplate";

function DndTaskTemplate({
  tasks = [],
  handleTaskStage,
  onAddTask,
  vendorTemplate,
}) {
  const [items, setItems] = useState(taskListByStage(tasks));
  const { ganttChartTemplateState, dispatchGanttChartTemplate } = useContext(
    GanttChartTemplateContext
  );
  const { tableSize, graphTableRef, taskPhases } = ganttChartTemplateState;
  const taskTableRef = useRef(null);
  const companyUsers = useCompanyUsers({
    showBotUser: false,
    showInactiveUsers: false,
  });

  const onDebounce = useCallback(
    debounce((tasks) => {
      addHoverUser(tasks);
    }, 1000),
    []
  );

  const addHoverUser = (tasks = []) => {
    const ids = tasks.map((task) => task.id);
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        highlightedTask: {
          ids,
        },
      },
    });
  };

  useEffect(() => {
    setItems(taskListByStage(tasks));
  }, [tasks]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (tableSize === GANTTCHART_TABLE_SIZE.MEDIUM) {
      const currentTop = taskTableRef?.current?.scrollTop;
      if (currentTop && graphTableRef && graphTableRef.current) {
        graphTableRef.current.scrollTop = currentTop;
      }
    }
  }, [tableSize]);

  function handleDragOver(event) {
    const { active = {}, over = {}, draggingRect = {} } = event;
    const canDragOver = validateDragOver({ active, over, items });
    if (canDragOver) {
      setItems((prev) => {
        const currentTasks = Object.values(prev).flat();
        const activeTask =
          currentTasks.find((task) => task.id === active.id) || {};
        const overTask =
          currentTasks.find((task) => task.id === over?.id) || {};
        const activeContainer = activeTask.stage;
        const overContainer = overTask.stage || over.id;
        const newItems = handleDragOverState({
          tasksByStages: prev,
          activeContainer,
          overContainer,
          active,
          over,
          draggingRect,
        });
        return newItems;
      });
    }
  }

  const saveTasks = (tasks) => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        tasks,
      },
      typeChange: TASK_TEMPLATE_OPTIONS.TASK_CHANGED_POSITION,
    });
  };

  function handleDragEnd(event) {
    const { active, over } = event;
    const tasksToUpdate = getReorderedTasks({
      active,
      over,
      isPOTemplate: vendorTemplate,
      items,
    });
    if (!tasksToUpdate) return;
    saveTasks(tasksToUpdate);
    setItems(taskListByStage(tasksToUpdate));
  }

  const onOpenAllStage = () => {
    dispatchGanttChartTemplate({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        taskPhases: {
          [labelTaskPhases.PROPOSAL]: true,
          [labelTaskPhases.PRE_PRODUCTION]: true,
          [labelTaskPhases.PRODUCTION]: true,
          [labelTaskPhases.BOOKING_TRANSIT]: true,
          [labelTaskPhases.PAYMENT_BILLING]: true,
        },
      },
    });
  };

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragStart={onOpenAllStage}
      onDragCancel={() => {
        setItems(taskListByStage(tasks));
      }}
    >
      {STAGE_LIST.map((stage) => (
        <DropContainer
          id={stage}
          stageTask={items[stage]}
          tasks={Object.values(items).flat()}
          handleTaskStage={handleTaskStage}
          isOpen={taskPhases[labelTaskPhases[stage]]}
          onAddTask={() => onAddTask(stage)}
          stage={labelTaskPhases[stage]}
          tableSize={tableSize}
          vendorTemplate={vendorTemplate}
          companyUsers={companyUsers}
          onDebounce={onDebounce}
          addHoverUser={addHoverUser}
        />
      ))}
    </DndContext>
  );
}

export default DndTaskTemplate;
