import React from "react";

import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import "./styles.scss";

const filter = createFilterOptions();

export default function AutoCompleteTag({
  tags = [],
  onTextFieldChange,
  onKeyDown,
  textFieldValue = "",
  isAllowed,
}) {
  return (
    <Autocomplete
      classes={{
        root: "autocomplete-tag-root",
        endAdornment: "endAdorment",
        clearIndicator: "clearIndicator",
        inputRoot: "inputRoot",
        input: "input",
      }}
      id="autocomplete-tag"
      freeSolo
      options={tags.map((option) => option.name)}
      onChange={(ev, value) => {
        onTextFieldChange(value);
      }}
      disabled={isAllowed("assign_tags") ? false : true}
      value={textFieldValue}
      filterOptions={(options, params) => {
        params.inputValue = textFieldValue || "";
        const filtered = filter(options, params);

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return option.name;
      }}
      clearOnEscape
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: "textField-root" }}
          placeholder="Add a label..."
          margin="normal"
          variant="outlined"
          onChange={(ev) => {
            onTextFieldChange(ev.target.value);
          }}
          onKeyUp={onKeyDown}
          value={textFieldValue}
          disabled={isAllowed("assign_tags") ? false : true}
          autoFocus
        />
      )}
    />
  );
}
