import { MenuItem, Select } from "@mui/material";
import React from "react";
import { DEPENDENCY_TYPE, TASK_TYPE } from "../../helpers/constants";
import { getDependencies, isSalesOrderRootTask } from "./soTemplateHelper";
import TooltipTD from "../Tooltip/TooltipTD";

const CustomSelect = ({
  tasks = [],
  task,
  dependencyTypeOptions = [],
  isDependencyType,
  labelIdSuffix,
  handleChange,
  showLabel,
  disabled = false,
  getVendorIndex = false,
  vendorTemplate,
}) => {
  const isRootTask = isSalesOrderRootTask({ task, vendorTemplate });
  const getValue = () => {
    if (isDependencyType) {
      return task.dependencyType || "";
    }
    return tasks.map((task) => task.id).includes(task.dependency)
      ? task.dependency
      : "";
  };
  const getOptions = () => {
    if (isDependencyType) {
      return dependencyTypeOptions.map((dependencyType) => (
        <MenuItem key={dependencyType} value={dependencyType}>
          {showLabel ? DEPENDENCY_TYPE[dependencyType] : dependencyType}
        </MenuItem>
      ));
    }

    return tasks
      .filter((taskData) => {
        if (taskData.id === task.id) {
          return false;
        }

        if (task.type === TASK_TYPE.PURCHASE_ORDER) {
          return taskData.type !== TASK_TYPE.SHIPMENT;
        }
        return true;
      })
      .map((taskData) => {
        const dependencies = getDependencies(taskData.id, tasks);
        const isDisabled = dependencies.includes(task.id);
        const prefix = taskData.type === TASK_TYPE.SALES_ORDER ? "S." : "P.";
        return (
          <MenuItem
            value={taskData.id}
            key={taskData.id + task.id}
            style={{
              cursor: isDisabled ? "not-allowed" : "",
              opacity: isDisabled ? 0.5 : 1,
              padding: 0,
            }}
          >
            <TooltipTD
              showToolTip={dependencies.includes(task.id)}
              label={"Not available because it creates a circular reference"}
              tooltipClick={(e) => {
                if (isDisabled) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              style={{
                width: "100%",
                padding: "6px 16px",
              }}
              className="itemMenuText"
            >
              {`${prefix}${
                getVendorIndex ? getVendorIndex(taskData) : taskData.number
              } ${taskData.description}`}
            </TooltipTD>
          </MenuItem>
        );
      });
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {!isRootTask && (
        <Select
          className="selectedGrid"
          MenuProps={{
            className: "selectedListGrid",
            disablePortal: true,
          }}
          labelId={`select-label-${task.id}-${labelIdSuffix}`}
          value={getValue()}
          onChange={(ev) => {
            handleChange(ev, isDependencyType);
          }}
          displayEmpty
          fullWidth
          size="small"
          SelectDisplayProps={{
            style: {
              paddingTop: 2,
              paddingLeft: !isDependencyType ? 0 : "",
              paddingBottom: 0,
            },
          }}
          disabled={disabled}
        >
          {!isDependencyType && (
            <MenuItem value="" disabled>
              <em style={{ marginLeft: 5 }}>Select one</em>
            </MenuItem>
          )}
          {getOptions()}
        </Select>
      )}
    </div>
  );
};

export default CustomSelect;
