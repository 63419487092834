import React, { useState } from "react";
import {
  useCompanyPermissionUser,
  useCompanyUsers,
  useUser,
} from "../../hooks/user";
import UserProfileHeader from "../../components/ProfileDrawer/UserProfileHeader";
import UserDrawerContent from "../../components/ProfileDrawer/UserDrawerContent";
import UserAvatarModal from "../../components/ProfileDrawer/UserAvatarModal";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import moment from "moment";
import { MARK_READ_LOADED_NOTIFICATIONS } from "../../actions/types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getNotificationsStorage } from "../NotificationsContainer/helperNotification";
import { READ_FROM_TYPE } from "../../helpers/constants";

function ProfileDrawer({  handleLoading }) {
  const [openAvatarModal, setOpenAvatarModal] = useState(false)
  const currentUser = useUser();
  const companyId = useCompanyId();
  const users = useCompanyUsers({});
  const sortPermissionGroup = useCompanyPermissionUser(currentUser);
  const dispatch = useDispatch();
  const loadedNotifications = useSelector(
    (state) => state.data.loadedNotifications
  );
  const { get } = useJSONLocalStorage("notifications");
  let { storageActiveTab } = getNotificationsStorage({
    storageNotifications: get(),
  });

  async function markShowNotifications() {
    handleLoading(true);
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/cleanAllMentions`,
      })
    );
    await callableEndpoint({
      companyId,
      userId: currentUser.id,
      date: moment().valueOf(),
    });
    const currentTabNotification = loadedNotifications[storageActiveTab] || {};
    const notifications = currentTabNotification.data;
    dispatch({
      type: MARK_READ_LOADED_NOTIFICATIONS,
      payload: {
        notifications: notifications,
        cleanAll: true,
        typeReadFrom: READ_FROM_TYPE.MARK_ALL_AS_READ_USER_SETTINGS,
      },
    });

    handleLoading(false);
  }
  return (
    <div className="userScrollDrawer">
      {openAvatarModal && (
        <UserAvatarModal
          currentUser={currentUser}
          handleOpenModal={(value) => setOpenAvatarModal(value)}
          openModal={openAvatarModal}
        />
      )}
      <UserProfileHeader
        currentUser={currentUser}
        handleClearMentions={markShowNotifications}
      />
      <UserDrawerContent
        handleLoading={(isOpen) => {
          handleLoading(isOpen);
        }}
        currentUser={currentUser}
        users={users}
        onChangeAvatar={(ev) => {
          setOpenAvatarModal(true);
          ev.stopPropagation();
        }}
        permissionGroup={sortPermissionGroup}
      />
    </div>
  );
}

export default ProfileDrawer;
