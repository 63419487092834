import { Button, Popover, styled, TextField } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const SelectButtonStyled = styled(Button)(() => ({
  "&.selectButtonContainer": {
    background: "#FFF",
    color: colors.primaryDark,
    borderRadius: 5,
    height: 40,
    width: 190,
    textTransform: "none",
    padding: "0 18px 0 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 10,
    fontSize: 14,
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& .emoji": {
      fontSize: 20,
    },
    "& .textFilter": {
      fontSize: 14,
      fontWeight: 500,
      color: colors.primaryDark,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      maxWidth: 240,
    },
    "& .iconContainer": {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    "& .arrowDropDownIcon": {
      fontSize: 30,
      color: colors.primaryDark,
      right: 4,
    },
  },
  "&.disabledButton": {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

export const SelectPopoverStyled = styled(Popover)(() => ({
  "&.selectButtonPopoverContainer": {
    "& .MuiPaper-root": {
      minWidth: 250,
    },
    "& .menu-item": {
      minWidth: 250,
      display: "flex",
      alignItems: "center",
      gap: 10,
      fontSize: 14,
      fontWeight: 500,
      color: colors.primaryDark,
      "& .editIcon": {
        display: "flex",
        alignItems: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: 240,
        marginLeft: "auto",
      },
      "& .outdatedContainer": {
        width: 14,
        display: "flex",
        alignItems: "center",
      },
      "& .textFilter": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: 240,
      },
    },
    "& .selected": {
      boxShadow: "4px 0px 0px 0px #218cff inset",
    },
  },
}));

export const SalesOrderTemplateTextFieldStyled = styled(TextField)(() => ({
  "&.soTemplateTextFieldContainer": {
    width: 250,
    "& .soTemplateFieldInputRoot": {
      background: "#FFF",
      paddingRight: 4,
      "&:hover": {
        cursor: "pointer",
      },
      "& fieldset": {
        border: "none",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
    },
    "& .soTemplateFieldInput": {
      padding: "0px 16px",
      height: 40,
      fontSize: 14,
      fontWeight: 500,
      color: colors.primaryDark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      pointerEvents: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .soTemplateFieldLabel": {
      color: colors.primaryDark,
      fontSize: 14,
      fontWeight: 500,
      background: "#FFF",
      padding: "0px 8px",
      borderRadius: 5,
    },
  },
}));
