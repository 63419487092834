import {
  collection,
  doc,
  increment,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  PO_DIAMOND_STATUS,
  PO_STATUS,
  SALES_ORDER_STATUS,
  STAGE_LIST,
  taskStatus,
} from "../../helpers/constants";
import { firestore } from "../../firebase";
import { dbTables, typeOfTask } from "../../api/types/dbTables";
import { chunk, isEqual, uniq } from "lodash";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getDetailFromMoveDependantTask,
  getNumberOfLateDays,
  triggerTaskTypes,
} from "../../helpers/tasks";
import { colors } from "../../assets/jss/variables";
import { CheckBoxIcon, DiamondIcon } from "../../helpers/iconSvgPath";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import ActivityTask from "../../api/model/ActivityTask.model";
import moment, { now } from "moment";
import { actionType, dependencyTypesOnMove } from "../../helpers/timelineModal";
import { getTaskUpdated } from "../../components/PurchaseOrderDashboard/HelperProjectTasks";
import { sortObjectsBy, taskNotificationType } from "../../helpers/helpers";
import { createActivityEntryFromMovedTask } from "../../helpers/orderDashboard";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import { activityType } from "../../helpers/activitiesStream";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";

export const parseSalesOrderData = ({ salesOrders, customers }) => {
  const customerMap = customers.reduce((acc, customer) => {
    acc[customer.id] = customer.name;
    return acc;
  }, {});
  return salesOrders.map((salesOrderData) => {
    const salesOrder = { ...salesOrderData.data(), ref: salesOrderData.ref };
    return {
      ...salesOrder,
      customerName:
        customerMap[salesOrder.customerId] || salesOrder.customerName,
    };
  });
};

export const getDataFromListener = ({
  path,
  setStateData,
  parseData = true,
  statusQuery,
}) => {
  let ref;
  try {
    if (statusQuery) {
      ref = query(
        collection(firestore, path),
        where("status", "==", SALES_ORDER_STATUS.IN_PROGRESS)
      );
    } else {
      ref = collection(firestore, path);
    }

    return onSnapshot(ref, (snapshot) => {
      if (parseData) {
        const snapData = snapshot.docs.map((dataSnapshot) => ({
          ...dataSnapshot.data(),
          ref: dataSnapshot.ref,
        }));
        setStateData(snapData);
      } else {
        setStateData(snapshot.docs);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPosFromListener = ({
  setPOData,
  salesOrderId,
  companyId,
  factories = [],
}) => {
  const factoriesMap = factories.reduce((acc, factory) => {
    acc[factory.id] = factory.name;
    return acc;
  }, {});
  return onSnapshot(
    query(
      collection(
        firestore,
        `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}`
      ),
      where("salesOrderIds", "array-contains", salesOrderId)
    ),
    (snapshot) => {
      const poData = snapshot.docs.map((po) => ({
        ...po.data(),
        customerVendorPath: `app/factories/${po.data().factoryId}`,
        customerVendorName:
          factoriesMap[po.data().factoryId] || po.data().vendorName,
        ref: po.ref,
      }));
      setPOData(poData);
    }
  );
};

export const getShipmentsListener = async ({
  purchaseOrders = [],
  companyId,
  setShipmentsData,
  customers = [],
}) => {
  const unsubscribeList = [];
  const shipmentsIds = purchaseOrders.map((po) => po.shipmentIds).flat();
  const uniqIds = uniq(shipmentsIds);
  const batches = chunk(uniqIds, 10);
  for (let i = 0; i < batches.length; i++) {
    const batch = batches[i];
    const unsubscribe = await onSnapshot(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
        ),
        where("id", "in", batch)
      ),
      (snapshot) => {
        const shipmentData = snapshot.docs.map((shipment) => ({
          ...shipment.data(),
          customerVendorPath: `app/customers/${shipment.data().customerId}`,
          customerVendorName:
            (
              customers.find(
                (customer) => customer.id === shipment.data().customerId
              ) || {}
            ).name || "",
          ref: shipment.ref,
          path: shipment.ref.path,
        }));
        setShipmentsData({ shipmentData });
      }
    );
    unsubscribeList.push(unsubscribe);
  }
  return { ids: uniqIds, unlistener: unsubscribeList };
};

export const getTaskDiamondComponent = ({ task }) => {
  switch (task.status) {
    case taskStatus.LATE:
      return (
        <TooltipTD
          style={{ display: "flex" }}
          label={getNumberOfLateDays({ task })}
        >
          <DiamondIcon color={colors.diamondRed} height={16} />
        </TooltipTD>
      );
    case taskStatus.NEAR_DUE:
      return (
        <TooltipTD style={{ display: "flex" }} label="Due Today or Tomorrow">
          <DiamondIcon color={colors.diamondOrange} height={16} />
        </TooltipTD>
      );
    case taskStatus.IN_PROGRESS:
      return (
        <TooltipTD style={{ display: "flex" }} label="In Progress">
          <DiamondIcon color={colors.diamondGreen} height={16} />
        </TooltipTD>
      );
    case taskStatus.NOT_STARTED:
      return (
        <TooltipTD style={{ display: "flex" }} label="Not Started">
          <DiamondIcon
            color={"white"}
            strokeColor={colors.diamondWhiteBorder}
            height={16}
          />
        </TooltipTD>
      );
    default:
      return (
        <TooltipTD style={{ display: "flex", opacity: 0.55 }} label="Completed">
          <CheckBoxIcon width={15} height={15} color="#6B7A89" />
        </TooltipTD>
      );
  }
};

export const DueLateDiamond = ({ item, width = 10, showTooltip = true }) => {
  const late = [PO_DIAMOND_STATUS.LATE, taskStatus.LATE];
  const near = [PO_DIAMOND_STATUS.NEAR_DUE, taskStatus.NEAR_DUE];
  const currentStatus = item.diamondStatus || item.status;
  const isTask = !item.diamondStatus;
  const dangerLabel = isTask
    ? getNumberOfLateDays({ task: item })
    : "There are one or more late tasks";
  const dueLabel = isTask
    ? "Due Today or Tomorrow"
    : "There are one or more tasks due today or tomorrow";
  return (
    <div style={{ width, display: "flex", justifyContent: "center" }}>
      {[...late, ...near].includes(currentStatus) && (
        <TooltipTD
          style={{ display: "flex" }}
          showToolTip={showTooltip}
          label={late.includes(currentStatus) ? dangerLabel : dueLabel}
        >
          <DiamondIcon
            color={
              late.includes(currentStatus)
                ? colors.diamondRed
                : colors.diamondOrange
            }
          />
        </TooltipTD>
      )}
    </div>
  );
};

export const getFilterTasksByStatus = ({
  filters = {},
  tasks = [],
  companyUsers = [],
}) => {
  let tasksCpy = [...tasks];
  const statusArr = [
    "completed",
    "inProgress",
    "late",
    "nearDue",
    "notStarted",
  ];
  const statusValues = [];
  Object.keys(filters).forEach((key) => {
    if (statusArr.includes(key)) {
      statusValues.push(filters[key]);
    }
  });
  if (
    statusValues.every((status) => status === true) ||
    statusValues.every((status) => status === false)
  ) {
    tasksCpy = [...tasks];
  } else {
    tasksCpy = tasksCpy.filter((task) => {
      if (filters.completed && task.status === taskStatus.COMPLETE) {
        return true;
      }
      if (filters.inProgress && task.status === taskStatus.IN_PROGRESS) {
        return true;
      }
      if (filters.late && task.status === taskStatus.LATE) {
        return true;
      }
      if (filters.nearDue && task.status === taskStatus.NEAR_DUE) {
        return true;
      }
      if (filters.notStarted && task.status === taskStatus.NOT_STARTED) {
        return true;
      }
      return false;
    });
  }
  const { assignedTo = [], searchText = "" } = filters;
  if (assignedTo.length > 0) {
    tasksCpy = tasksCpy.filter((task) => assignedTo.includes(task.assignedTo));
  }
  if (searchText) {
    const usersMap = companyUsers.reduce((acc, user) => {
      acc[user.id] = (user.displayName || "").toLowerCase();
      return acc;
    }, {});
    tasksCpy = tasksCpy.filter((task) => {
      const parseText = searchText.toLowerCase();
      const userAssignedTo = usersMap[task.assignedTo] || "";
      return (
        task.description.toLowerCase().includes(parseText) ||
        userAssignedTo.includes(parseText)
      );
    });
  }
  let taskByStage = [];
  STAGE_LIST.forEach((stage) => {
    const taskListStage = tasksCpy
      .filter((item) => item.stage === stage)
      .sort(sortObjectsBy("listIndex"));
    taskByStage = [...taskByStage, ...taskListStage];
  });

  return taskByStage;
};

export const getFilterTaskByProyect = (dataStorage = {}) => ({
  assignedTo: dataStorage.assignedTo || [],
  complete: dataStorage.complete || false,
  inProgress: dataStorage.inProgress || false,
  late: dataStorage.late || false,
  nearDue: dataStorage.nearDue || false,
  notStarted: dataStorage.notStarted || false,
  searchText: dataStorage.searchText || "",
});

export const TaskGroupHeaderCells = [
  {
    id: 0,
    label: "generic.text.blank",
    key: "blank",
    canBeSort: false,
    styles: {
      gridColumn: "span 2",
    },
  },
  {
    label: "salesorders.title",
    id: 1,
    styles: {
      gridColumn: "span 2",
    },
  },

  {
    label: "customers.title",
    id: 2,
  },
  {
    id: 3,
    label: "salesOrderGroup.taskcount",
  },
  {
    id: 4,
    label: "salesordertemplate.assignedtitle",
  },
  {
    id: 5,
    label: "widgets.startDate",
  },
  {
    id: 6,
    label: "widgets.endDate",
  },
  {
    id: 7,
    label: "widgets.action",
  },
  {
    id: 8,
    label: "generic.text.blank",
    canBeSort: false,
    styles: {
      padding: 0,
      gridColumn: "span 2",
    },
  },
];

export function updateTaskNotifications(notificationId) {
  const functions = getFunctions();
  const callable = httpsCallableFromURL(
    functions,
    getFunctionByName({
      name: `userNotifications`,
      env: globalEnvironment,
      params: `/updateTaskNotifications?notificationId=${notificationId}`,
    })
  );
  callable().then((result) => {
    console.log("RESULT:: ", result);
  });
}

export function generateActivityEntryForNonMoveTasksDueDates({
  task,
  user,
  companyId,
  currentOrder,
  orderDB = dbTables.SALES_ORDERS,
}) {
  const dayOffsetAbs = Math.abs(task.dayOffset);
  const activity = {
    ...new ActivityTask({
      scope: task.type,
      creationDate: now(),
      type: "MOVED_TASKS_DUE_DATES",
      detail: getDetailFromMoveDependantTask({
        companyUsers: [],
        task,
        dayOffsetAbs: dayOffsetAbs,
        type: actionType.DENIED_REMAINING_DAY_OFFSET_MOVED,
      }),
      user: user.id || "",
      companyId: companyId,
      taskId: task.id,
    }),
  };

  const path = `${dbTables.COMPANIES}/${companyId}/${orderDB}/${currentOrder.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  setDoc(doc(firestore, path), {
    ...activity,
    scope: currentOrder.type || typeOfTask.SHIPMENT,
  });
}

export function handleBadgeMoveDependencyTasks({
  task,
  companyId,
  dayOffset,
  remainingOffset,
  currentUser,
  companyUsers,
  parentCollection = dbTables.SALES_ORDERS,
  mainEntity,
}) {
  const parseRemainingOffset = Math.abs(parseInt(remainingOffset, 10));

  callDependencyTask({
    companyId,
    dayOffsetTask: dayOffset,
    mainEntityId: getEntitiyTaskId(task) || mainEntity.id,
    dependencyOffset: parseRemainingOffset,
    task,
    userId: currentUser.id,
  });

  updateDoc(task.ref, {
    moved: true,
    triggerType: triggerTaskTypes.MOVED_REMAINING_TASK,
  });

  const activity = {
    ...new ActivityTask({
      scope: task.type,
      creationDate: now(),
      type: "MOVED_TASKS_DUE_DATES",
      detail: getDetailFromMoveDependantTask({
        companyUsers: companyUsers,
        dayOffsetAbs: Math.abs(dayOffset),
        remainingOffset: parseRemainingOffset,
        task,
        type: actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED,
        user: currentUser,
      }),
      user: currentUser.id || "",
      companyId: companyId,
      taskId: task.id,
    }),
  };
  const path = `${dbTables.COMPANIES}/${companyId}/${parentCollection}/${mainEntity.id}/${dbTables.ACTIVITIES}/${activity.id}`;
  setDoc(doc(firestore, path), {
    ...activity,
    scope: mainEntity.type || typeOfTask.SHIPMENT,
  });

  updateTaskNotifications(task.notificationId);
}

export const getCurrentOrderTask = ({
  salesOrder,
  task,
  purchaseOrder,
  currentShipment,
}) => {
  let currentOrder;
  let orderDB;
  if (task.type === typeOfTask.SALES_ORDER) {
    currentOrder = salesOrder;
    orderDB = dbTables.SALES_ORDERS;
  } else if (task.type === typeOfTask.PURCHASE_ORDER) {
    currentOrder = purchaseOrder;
    orderDB = dbTables.PURCHASE_ORDERS;
  } else {
    currentOrder = currentShipment;
    orderDB = dbTables.SHIPMENTS;
  }
  return { currentOrder, orderDB };
};

export const completeCurrentTask = ({
  task,
  createTaskNotification = () => {},
  purchaseOrder = {},
  salesOrder,
  user,
  companyId,
  shipments,
}) => {
  const { dayOffset, notificationId, fieldsToUpdate, notificationTask } =
    getTaskUpdated({
      purchaseOrder,
      salesOrder,
      task,
      user,
      companyId,
      shipments,
    });
  if (dayOffset !== 0) {
    createTaskNotification({
      task: {
        ...notificationTask,
      },
      randomId: notificationId,
      type: taskNotificationType.COMPLETED,
    });
  }
  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  if (task.shipmentId) {
    updateDoc(
      doc(
        firestore,
        `${companyRef}/${dbTables.SHIPMENTS}/${task.shipmentId}/${dbTables.SHIPMENT_TASKS}/${task.id}`
      ),
      { ...fieldsToUpdate }
    );
  } else if (task.type === typeOfTask.SALES_ORDER) {
    updateDoc(
      doc(
        firestore,
        `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrder.id}/${dbTables.SALES_ORDER_TASKS}/${task.id}`
      ),
      { ...fieldsToUpdate }
    );
  } else {
    updateDoc(
      doc(
        firestore,
        `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrder.id}/${dbTables.PURCHASE_ORDER_TASKS}/${task.id}`
      ),
      { ...fieldsToUpdate }
    );
  }
};

export const moveStartFinishDate = ({ isStartDate, newDate, task }) => {
  const valueMoment = moment(newDate, "MM/DD/YY");
  const duration = task.duration <= 1 ? 0 : task.duration - 1;
  let startDate;
  let finishDate;
  let diffDays;
  if (isStartDate) {
    startDate = valueMoment.clone().startOf("day");
    finishDate = valueMoment.clone().add(duration, "days").endOf("day");
    const taskStartDate = getCorrectTimezone({
      date: task.startDate,
      isServerTime: true,
    }).valueOf();
    diffDays = valueMoment.diff(taskStartDate, "days");
  } else {
    finishDate = valueMoment.clone().endOf("day");
    startDate = valueMoment.clone().subtract(duration, "days").startOf("day");
    const taskFinishDate = getCorrectTimezone({
      date: task.finishDate,
      isServerTime: true,
    }).valueOf();
    diffDays = finishDate.diff(taskFinishDate, "days");
  }
  return {
    finishDate: getCorrectTimezone({
      date: finishDate,
    }).valueOf(),
    startDate: getCorrectTimezone({
      date: startDate,
    }).valueOf(),
    diffDays,
  };
};

export const sendSignShipmentsIds = ({
  purchaseOrders,
  currentShipmentIds = [],
}) => {
  const shipmentsIds = purchaseOrders.map((po) => po.shipmentIds || []).flat();
  const uniqIds = uniq(shipmentsIds);
  return currentShipmentIds === 0 || !isEqual(uniqIds, currentShipmentIds);
};

/*-------*/
export async function onMovingTasks({
  task,
  accepted,
  dayOffsetTask,
  dayOffsetRemainigTasks,
  companyId,
  currentUser,
  companyUsers,
  mainEntity,
  parentCollection = "",
}) {
  const parseDayOffSet = Math.abs(parseInt(dayOffsetRemainigTasks, 10));

  if (accepted) {
    callDependencyTask({
      companyId,
      dayOffsetTask,
      mainEntityId: getEntitiyTaskId(task) || mainEntity.id,
      dependencyOffset: parseDayOffSet,
      task,
      userId: currentUser.id,
    });
  }

  createActivityEntryFromMovedTask({
    task: task,
    diffDaysRemainingTasks: parseDayOffSet,
    diffDaysTask: dayOffsetTask,
    isDependantTaskMoved: accepted,
    isMovingForward: dayOffsetTask > 0,
    typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
    user: currentUser,
    companyUsers,
    mainEntity: {
      ...mainEntity,
      parentCollection,
      taskScope: task.type || typeOfTask.SHIPMENT,
    },
  });
}

const getEntitiyTaskId = (task) => {
  if (task.type === typeOfTask.SALES_ORDER) {
    return task.salesOrderId;
  } else if (task.type === typeOfTask.SALES_ORDER) {
    return task.purchaseOrderId;
  } else {
    return task.shipmentId;
  }
};

export const callMoveDependencyTasks = async ({
  companyId,
  salesOrder,
  task,
}) => {
  try {
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `moveDependencyTasks`,
        env: globalEnvironment,
        params: `/verifyDependencies/?companyId=${companyId}&&salesOrderId=${salesOrder.id}&&taskId=${task.id}&&type=${dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED}`,
      })
    );
    return await callable();
  } catch (error) {
    console.log(error);
  }
};

const callDependencyTask = ({
  companyId,
  task,
  mainEntityId,
  dayOffsetTask,
  dependencyOffset,
  userId,
}) => {
  const functions = getFunctions();
  const callable = httpsCallableFromURL(
    functions,
    getFunctionByName({
      name: "moveDependencyTasks",
      env: globalEnvironment,
    })
  );

  callable({
    companyId: companyId,
    mainEntityId: mainEntityId,
    taskId: task.id,
    dayOffset: dayOffsetTask,
    remainingDayOffset:
      dayOffsetTask >= 0 ? dependencyOffset : -dependencyOffset,
    user: userId,
    type: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
    taskType: task.type,
  });
};

export function verifyAdHocTask({ adHocTask, tabScope, currentShipment }) {
  const { description, stage, assignedTo, finishDate } = adHocTask;

  const isTaskFilled = description && stage && assignedTo && finishDate;

  if (!isTaskFilled) {
    return true;
  }

  if (tabScope !== typeOfTask.SHIPMENT) {
    return false;
  }

  return !(currentShipment && Object.keys(currentShipment).length > 0);
}

export function isVoidedEntity(currentOrder = {}) {
  return currentOrder.status === PO_STATUS.VOIDED;
}

export function isEmptyText(inputText) {
  return !inputText || /^\s*$/.test(inputText);
}

export const createTaskActivty = ({
  task,
  mainEntity,
  companyId,
  userAssigned = {},
  currentUser,
  type,
  orderDB,
}) => {
  const activity = new ActivityTask({
    id: task.id,
    companyId: companyId,
    creationDate: now(),
    detail: `<strong>${task.description}</strong> and assigned it to <strong>@${userAssigned.displayName}</strong>`,
    scope: mainEntity.type || type,
    type: activityType.CREATED_ADHOC_TASK,
    user: currentUser.id,
    taskId: task.id,
  });

  const companyRef = `${dbTables.COMPANIES}/${companyId}`;
  setDoc(
    doc(
      firestore,
      `${companyRef}/${orderDB}/${mainEntity.id}/${dbTables.ACTIVITIES}/${activity.id}`
    ),
    { ...activity }
  );
};

export const getTaskFilterByStage = ({ taskList, stage, listIndexes = {} }) => {
  return taskList
    .filter((task) => task.stage === stage)
    .map((task, index, taskList) => ({
      ...task,
      listIndex: task.enableToEdit
        ? taskList.length + 99
        : listIndexes[task.taskTemplateId] || task.listIndex,
    }))
    .sort(sortObjectsBy("listIndex"));
};

export const handleUpdateTotalTask = ({
  type,
  offset,
  mainEntity,
  salesOrder,
}) => {
  const parseOffset = parseInt(offset);
  const triggerType = salesOrderTriggerTypes.COMPLETED_TASKS;
  if (mainEntity.ref) {
    updateDoc(mainEntity.ref, {
      totalTasks: increment(parseOffset),
      triggerType: triggerType,
    });
    if (type === typeOfTask.PURCHASE_ORDER) {
      updateDoc(salesOrder.ref, {
        totalTasks: increment(parseOffset),
        triggerType: triggerType,
      });
    }
  }
};
