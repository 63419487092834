import { styled } from "@mui/material";
import { colors } from "../../assets/jss/variables";

export const EditableLabelStyled = styled("div")(() => ({
  "&.containerEditableLabel": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    minWidth: "30px",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      "& .edit-icon": {
        opacity: 1,
      },
    },
    "& .edit-icon": {
      opacity: 0,
      fontSize: 14,
      color: `${colors.primaryDark}`,
      position: "absolute",
      right: 0,
    },
    "& .text-static-name": {
      display: "flex",
      height: "100%",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      justifyContent: "center",
      fontWeight: 500,
      color: colors.primaryDark,
    },

    "& input": {
      background: "white",
      width: "100%",
      textAlign: "right",
    },
  },
  "&.timelineTaskEdit": {
    "& .text-static-name": {
      marginRight: 14,
      fontWeight: 700,
      justifyContent: "flex-end",
    },
    "& .edit-icon": {
      position: "absolute",
      right: 0,
    },
    "& input": {
      background: "transparent",
      width: "100%",
      textAlign: "right",
    },
  },
  "&.soTaskEdit": {
    "& .text-static-name": {
      marginRight: 5,
      fontWeight: 700,
      display: "unset",
      height: "auto",
      width: "fit-content",
    },
    "& .edit-icon": {
      position: "absolute",
      right: 0,
    },
    "& input": {
      background: "white",
      width: "100%",
      textAlign: "left",
      color: colors.primaryDark,
      fontWeight: 500,
    },
  },
  "&.numberValue": {
    "& .text-static-name": {
      justifyContent: "flex-end",
      marginRight: 17,
    },
    "& .edit-icon": {
      marginTop: -1,
    },
  },
}));
