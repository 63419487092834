import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ListItemText, MenuItem } from "@mui/material";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import { DraggableIcon } from "../../helpers/iconSvgPath";

export function SortableItem({ item, items, onChange }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
  };

  return (
  <div ref={setNodeRef} style={style} {...attributes}>
      <MenuItem
        value={item}
        classes={{ root: "menuItemOption" }}
        style={{ display: item.styles.display }}
        id={item.label}
        onClick={(ev) => {
          let itemsCpy = [...items];
          itemsCpy = itemsCpy.map((el) => {
            if (el.label === item.label) {
              return { ...el, enabled: !item.enabled };
            } else {
              return el;
            }
          });
          onChange(itemsCpy);
          ev.stopPropagation();
        }}
      >
        <div
          className={"dragContainer"}
          style={{
            cursor: item.disabledDraggable ? "unset" : "",
          }}
          {...listeners}
        >
          {!item.disabledDraggable && (
            <DraggableIcon svgClass="dragClass" height={17} width={17} />
          )}
        </div>
        <CustomCheckbox
          checked={item.enabled}
          iconSize={19}
          styles={{ marginBottom: -2, padding: 0 }}
        />
        <ListItemText
          className="listItemTextLabel"
          primary={item.label.replace(/\s\s+/g, " ")}
        />
      </MenuItem>
    </div>
  );
}
