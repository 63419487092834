import React, { useState, useEffect, useCallback } from "react";
import moment, { now } from "moment";
import IntlMessages from "../../util/IntlMessages";
import { ModalPermissionStyled } from "./styles";
import { storage } from "../../firebase";

import FileDocument from "../../api/model/FileDocument";

import {
  addNewDocumentType,
  createActivityMultiUpload,
} from "../../helpers/documents";
import { useCompany } from "../../hooks/company";
import { addGenericDataToFirestore } from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import { DOCUMENT_TRIGGER_TYPES } from "../../helpers/purchaseOrder";
import {
  getDocumentPathv2,
  getDocumentScope,
} from "../../helpers/documentsv2.helper";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import FileDocumentVersion from "../../api/model/FileDocumentVersion";
import {
  documentScopesObj,
  getUserAndGroupPermissionsObj,
} from "./DocumentHelper";
import { useCompanyUsers } from "../../hooks/user";

import { getGroupAndUserPermission } from "../ReportsTree/helpers";
import { useCompanyId, usePermissionGroups } from "../../hooks";
import DocumentsListUpload from "./DocumentsListUpload";
import { CircularStaticProgress } from "../CircularProgress/CircularStaticProgress";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function ModalUploadingFile({
  title,
  open,
  warningMessage,
  files = [],
  onClose = () => {},
  onCompleteUpload = () => {},
  userId,
  orderPath,
  parentCollection,
  parentDocumentId,
  parentDocument = {},
  allDocuments = [],
  currentDocument = {},
  mainEntity = {},
  folders = [],
}) {
  const [blockOk, setBlockOk] = useState(false);
  const [permissions, setPermissions] = useState({
    permissionUsers: [],
    permissionGroups: [],
  });
  const [progressUpload, setProgressUpload] = useState({});
  const [pending, setPending] = useState(false);
  const [sign, setSign] = useState(false);
  const [enabledSave, setEnabledSave] = useState(false);
  const [fileUploading, setFileUploading] = useState([]);
  const [newFileToUpload, setNewFileToUpload] = useState([]);
  const companyId = useCompanyId();
  const permissionGroupsDB = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });
  const company = useCompany();
  const dispatch = useDispatch();
  const companyUsers = useCompanyUsers({ showInactiveUsers: true });
  const addDocument = useCallback((document) =>
    addGenericDataToFirestore(
      [...orderPath.split("/"), document.id],
      document
    )(dispatch)
  );

  useEffect(() => {
    const { newPermissionGroups, newPermissionUsers } =
      getGroupAndUserPermission({
        permissionGroupsDB,
        companyUsers,
        isRoot: !parentDocument.id,
        parentPermission: parentDocument,
        permissionGroups: parentDocument.permissionGroups,
        permissionUsers: parentDocument.permissionUsers,
      });
    setPermissions({
      permissionGroups: parentDocument.id ? newPermissionGroups : [],
      permissionUsers: parentDocument.id
        ? newPermissionUsers
        : companyUsers.filter((user) => user.active),
    });
  }, []);

  useEffect(() => {
    if (
      !enabledSave &&
      files.filesData.length !== 0 &&
      newFileToUpload.length === files.filesData.length
    ) {
      setTimeout(() => {
        setEnabledSave(true);
      }, 600);
    }
  }, [newFileToUpload]);

  useEffect(() => {
    if (pending && sign) {
      onCancelUpload();
    }
  }, [sign]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setSign(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (open) {
      try {
        startUploadFiles();
      } catch (error) {
        console.log(error);
      }
      setPending(true);
    }
  }, [open]);

  const startUploadFiles = async () => {
    console.log("startUploadFiles");
    const { filesData, originalFiles } = files;
    await Promise.all(
      filesData.map(async (fileData, index) => {
        const storageRef = ref(storage, fileData.path);
        const task = uploadBytesResumable(storageRef, originalFiles[index]);
        task.on(
          "state_changed",
          (snapshot) => handleStateChange(snapshot, fileData, task),
          (error) => {
            console.error(`Error uploading file ${fileData.name}:`, error);
          },
          () => handleUploadComplete(fileData)
        );

        await task;
      })
    );
  };

  function handleUploadComplete(fileData) {
    setNewFileToUpload((oldFiles) => [...oldFiles, fileData]);
  }

  function handleFinisthUpload() {
    if (blockOk) {
      return;
    }
    setBlockOk(true);
    const fileTriggerType =
      newFileToUpload.length === 1 && folders.length === 0
        ? DOCUMENT_TRIGGER_TYPES.UPLOAD_FILE
        : DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER;

    newFileToUpload.forEach(async (currentFile) => {
      const {
        name,
        documentId,
        version,
        lastModifiedDate,
        type,
        size,
        originalName,
        parentId = "",
      } = currentFile;

      const path = name;
      // trackEvent("PO Dashboard - Documents - Upload via Drag-and-Drop", {
      //   filename: name,
      //   version,
      // });
      //
      try {
        const storageRef = `${orderPath}/${documentId}/version/${name}_V${version}`;
        const url = await getDownloadURL(ref(storage, storageRef));
        let newFile;
        if (currentDocument.ref) {
          const currentVersions = currentDocument.versions || [];
          let valuesToUpdate = {
            createdBy: userId,
            created: moment.now(),
            user: currentDocument.user || userId,
            versions: [
              ...currentVersions,
              {
                ...new FileDocumentVersion({
                  url: currentDocument.url,
                  version: currentDocument.version,
                  type: currentDocument.type,
                  created: currentDocument.created,
                  createdBy: currentDocument.createdBy,
                  size: currentDocument.size,
                  storageRef: currentDocument.storageRef,
                }),
              },
            ],
            triggerType: DOCUMENT_TRIGGER_TYPES.UPLOAD_NEW_FILE_VERSION,
          };
          if (currentDocument.placeholder) {
            valuesToUpdate = {
              createdBy: userId,
              created: moment.now(),
              user: userId,
              versions: currentVersions,
              triggerType: DOCUMENT_TRIGGER_TYPES.UPLOAD_FILE_PLACEHOLDER,
              placeholder: false,
            };
          }
          updateDoc(currentDocument.ref, {
            path,
            url,
            type,
            version,
            lastModifiedDate,
            size,
            editedBy: userId,
            parentDocumentId,
            storageRef,
            name: name,
            currentPath: getDocumentPathv2({
              currentDocument: {
                ...currentDocument,
                name,
              },
              documents: allDocuments,
            }),
            ...valuesToUpdate,
          });
        } else {
          console.log("URL ::: ", url, fileTriggerType);
          const id = documentId;
          newFile = new FileDocument({
            id,
            name,
            path,
            url,
            type,
            version,
            mainFile: true,
            lastModifiedDate,
            size,
            editedBy: userId,
            createdBy: userId,
            scope: documentScopesObj[parentCollection],
            parentDocumentId,
            originalName,
            parentId: parentId || parentDocument.id || "",
            storageRef,
          });

          addDocument({
            ...newFile,
            triggerType: fileTriggerType,
            currentPath: getDocumentPathv2({
              currentDocument: newFile,
              documents: [...folders, ...allDocuments],
            }),
            created: now(),
          });
        }

        addNewDocumentType({ company, document: { type } });
      } catch (error) {
        console.error("Error getting download URL:", error);
      }
    });
    const { permissionUsers, permissionGroups } =
      getUserAndGroupPermissionsObj(permissions);
    if (folders.length > 0) {
      folders.forEach((folder) => {
        addDocument({
          ...folder,
          permissionUsers,
          permissionGroups,
          created: now(),
        });
      });
    }

    if (fileTriggerType === DOCUMENT_TRIGGER_TYPES.DISMISS_TRIGGER) {
      createActivityMultiUpload({
        companyId: company.id,
        parentCollection,
        orderEntityId: parentDocumentId,
        parentDocumentId: parentDocument.id,
        userId,
        files: newFileToUpload.map((currentFile) => ({
          ...currentFile,
          parentId: currentFile.parentId || parentDocument.id || "",
        })),
        scope: getDocumentScope({
          mainEntity,
          parentCollection,
        }),
        doc,
        setDoc,
        currentPath:
          getDocumentPathv2({
            currentDocument: parentDocument,
            documents: allDocuments,
          }) || "top level",
        companyUsers,
        mainEntity,
        folders: folders.map((folder) => ({
          ...folder,
          currentPath: getDocumentPathv2({
            currentDocument: folder,
            documents: [...folders, ...allDocuments],
          }),
        })),
        permissionGroupsDB,
        permissions: { permissionUsers, permissionGroups },
      });
    }
    onCompleteUpload();
    setPending(false);
    setTimeout(() => {
      onClose();
    }, 400);
  }

  const onCancelUpload = () => {
    try {
      fileUploading.forEach((fileRef) => {
        if (fileRef.cancel) {
          fileRef.cancel();
        }
      });
      newFileToUpload.forEach((currentFile) => {
        deleteObject(ref(storage, currentFile.path)).then(() => {
          console.log("deleted", currentFile.path);
        });
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  function handleStateChange(snapshot, fileData, task) {
    const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setProgressUpload((oldValues) => ({
      ...oldValues,
      [fileData.documentId]: percent,
    }));
    if (percent === 0) {
      setFileUploading((oldFiles) => [...oldFiles, task]);
    }
  }

  const fileData = files.filesData;
  const completedFiles = fileData.filter(
    (file) => progressUpload[file.documentId] === 100
  );

  return (
    <ModalPermissionStyled
      isOpen={open}
      className="modal-container-permissions"
      id="modal-container-permission"
      modalClassName="modalClass"
      hasCloseIcon={false}
      bodyStyles={{
        width: 800,
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Escape") {
          onCancelUpload();
        }
        if (ev.key === "Enter" && enabledSave) {
          handleFinisthUpload();
        }
      }}
    >
      <div className="modalTitle">
        <p className="selectDocumentType">{title}</p>
        {fileData.length > 1 && (
          <CircularStaticProgress
            label={`${completedFiles.length} of ${fileData.length} files uploaded`}
            styleContainer={{
              position: "absolute",
              display: "flex",
              right: 120,
              top: 55,
              width: 40,
            }}
            thickness={15}
            value={(completedFiles.length / fileData.length) * 100}
          />
        )}
      </div>
      {warningMessage}
      <div className="filesThreeContainer">
        <DocumentsListUpload
          folders={folders.map((folder) => ({
            ...folder,
            currentPath: getDocumentPathv2({
              currentDocument: folder,
              documents: [...folders, ...allDocuments],
            }),
          }))}
          files={files.filesData.map((currentFile) => ({
            ...currentFile,
            parentId: currentFile.parentId || parentDocument.id || "",
            id: currentFile.documentId,
          }))}
          parentRootId={parentDocument.id}
          progressUpload={progressUpload}
        />
      </div>

      <div className="modalButtonsSection">
        <button
          className="btn-done"
          onClick={handleFinisthUpload}
          disabled={!enabledSave}
          autoFocus
        >
          <IntlMessages id="components.documents.modalpermission.done" />
        </button>
        <button className="btn-cancel" onClick={onCancelUpload}>
          <IntlMessages id="components.documents.modalpermission.cancel" />
        </button>
      </div>
    </ModalPermissionStyled>
  );
}

export default ModalUploadingFile;
