import moment from "moment";
import {
  activityType,
  getCleanMentionsValues,
} from "../../../helpers/activitiesStream";
import {
  ACTIVITY_DELETE_STATUS,
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  NOTES_SCOPE,
  PERMISSION_TEXT,
  PO_STATUS,
  TYPE_OF_FILE,
  notesFilters,
} from "../../../helpers/constants";
import { LockNoteIcon } from "../../../helpers/iconSvgPath";
import TooltipTD from "../../Tooltip/TooltipTD";
import {
  getDashboardSearchPath,
  sortObjectsBy,
} from "../../../helpers/helpers";
import { ACTIVITY_STREAM_ACTION } from "./ActivityStreamContext";
import { ORDER_TYPE, dbTables } from "../../../api/types/dbTables";
import { deleteField, doc, setDoc } from "firebase/firestore";
import { firestore, performanceFirebase } from "../../../firebase";
import { MARK_READ_ACTIVITY } from "../../../actions/types";
import ActivityNote from "../../../api/model/ActivityNote.model";
import { verifyPermission } from "../../../hooks/permissions";
import {
  documentScope,
  getDocumentDB,
  hasDocumentPermission,
} from "../../../helpers/documents";
import { trace } from "firebase/performance";
import userTypes from "../../../api/types/userTypes";

export const getTextFromType = (type) => {
  switch (type) {
    case activityType.FILE_UPLOAD:
      return "Uploaded file:";
    case activityType.FILE_NAME_CHANGE:
      return "Renamed file:";
    case activityType.FOLDER_NAME_CHANGE:
      return "Renamed folder:";
    case activityType.FOLDER_CREATE:
    case activityType.FOLDER_UPLOAD:
      return "Created folder:";
    case activityType.NOTE:
      return "Note:";
    case activityType.TASK_COMPLETED:
      return "Completed task:";
    case activityType.REASSIGNED_TASK:
      return "Reassigned Task:";
    case activityType.DELETED_FOLDER:
      return "Deleted Folder:";
    case activityType.DELETED_FILE:
      return "Deleted File:";
    case activityType.VERSION_UPLOAD:
      return "Uploaded new version of file:";
    case activityType.TASK_MOVED_LATER:
      return "Moved task date later:";
    case activityType.TASK_MOVED_EARLIER:
      return "Moved task date earlier:";
    case activityType.MOVED_TASKS_DUE_DATES:
      return "Moved task due date:";
    case activityType.MOVE_COMPLETED_TASKS:
      return "All tasks completed:";
    case activityType.MOVED_TASK_DUE_DATE:
      return "Moved task due date:";
    case activityType.PROPERTY_CHANGE_SHIPMENT:
      return "Updated ";
    case activityType.PROPERTY_CHANGE:
      return "Updated Info:";
    case activityType.FILE_MOVED:
      return "Moved file between folders:";
    case activityType.FOLDER_MOVED:
      return "Moved folder between folders:";
    case activityType.PLACEHOLDER_FILE_UPLOAD:
      return "Uploaded placeholder file:";
    case activityType.NEW_PO_ENTRY:
      return "PO added to project in progress:";
    case activityType.FOLDER_CHANGE_PERMISSION:
      return "Changed folder view permissions:";
    case activityType.FILE_CHANGE_PERMISSION:
      return "Changed file view permissions:";
    case activityType.CREATED_ADHOC_TASK:
      return "Created task:";
    case activityType.COPIED_FOLDER_LINK:
      return "Copied folder link:";
    case activityType.COPIED_FILE_LINK:
      return "Copied file link:";
    case activityType.DOWNLOADED_FOLDER:
      return "Downloaded folder:";
    case activityType.DOWNLOADED_FILE:
      return "Downloaded file:";
    case activityType.DOWNLOADED_FOLDER_FROM_LINK:
      return "Folder was downloaded from link:";
    case activityType.DOWNLOADED_FILE_FROM_LINK:
      return "File was downloaded from link:";
    case activityType.TAGS_UPDATE:
      return "Updated info:";
    case activityType.UPDATED_TASK:
      return "Updated task:";
    case activityType.NEW_ITEM_SHIPMENT:
      return "Item added to:";
    case activityType.UPDATED_ITEM_SHIPMENT:
      return "Item updated in:";
    case activityType.ITEM_CREATED:
      return "Added Item:";
    case activityType.ITEM_UPDATED:
      return "Updated item:";
    case activityType.ITEM_DELETED:
      return "Updated Item:";
    case activityType.MINIMUM_ORDER_QUANTITY:
      return "Salsify product data update:";
    case activityType.MOVED_PROJECT_TASKS:
      return "Revised task due dates after a Quote update:";
    case activityType.UPLOADED_MULTI_FILES:
      return "Uploaded multiple files:";
    case activityType.DELETE_MULTI_FILES_AND_FOLDERS:
      return "Deleted multiple files and folders:";
    case activityType.DELETE_MULTI_FILES:
      return "Deleted multiple files:";
    case activityType.DELETE_MULTI_FOLDERS:
      return "Deleted multiple folders:";
    case activityType.MOVE_FILES_AND_FOLDERS:
      return "Moved files and folders between folders:";
    case activityType.MOVE_FILES:
      return "Moved files between folders:";
    case activityType.MOVE_FOLDERS:
      return "Moved folders between folders:";
    case activityType.DOWNLOADED_MULTI_FILES_AND_FOLDERS:
      return "Downloaded multiple files and folders:";
    case activityType.DOWNLOADED_MULTI_FILES:
      return "Downloaded multiple files:";
    case activityType.DOWNLOADED_MULTI_FOLDERS:
      return "Downloaded multiple folders:";
    case activityType.WITHOUT_TITLE:
      return "";

    default:
      return "Activity";
  }
};

export const newBackdropDocument = ({
  poId,
  shipmentId,
  purchaseOrders = [],
  handleError = () => {},
  document,
  onAddFileId,
  id,
  query = {},
  verifyVendor,
}) => {
  switch (document.scope) {
    case documentScope.PURCHASE_ORDER:
    case documentScope.PURCHASE_QUOTE:
      const currentPO = purchaseOrders.find((po) => po.id === poId);
      if (!currentPO) {
        handleError({
          open: true,
          text: `You do not have access to purchase order ${document.name}`,
        });
        return;
      }
      const hasPermission = verifyVendor(currentPO.factoryId);
      if (hasPermission) {
        onAddFileId({
          fileId: `PO_${id}`,
          purchaseOrderId: poId,
          shipmentId: currentPO.shipmentIds[0] || "",
        });
      } else {
        handleError({
          open: true,
          text: PERMISSION_TEXT.VENDOR,
        });
      }

      break;
    case documentScope.SALES_ORDER:
    case documentScope.QUOTE:
      onAddFileId({ fileId: `SO_${id}` });
      break;

    default:
      const existInPO =
        purchaseOrders.find(
          (po) =>
            po.id === query.purchaseOrderId &&
            po.shipmentIds.includes(shipmentId)
        ) || purchaseOrders.find((po) => po.shipmentIds.includes(shipmentId));
      if (!existInPO) {
        handleError({
          open: true,
          text: "this file is attached to a shipment that is void and no longer connected to this project",
        });
        return;
      }
      const hasShipmentPermission = verifyVendor(existInPO.factoryId);
      if (hasShipmentPermission) {
        onAddFileId({
          fileId: `SH_${id}`,
          purchaseOrderId: existInPO.id,
          shipmentId: shipmentId,
        });
      } else {
        handleError({
          open: true,
          text: PERMISSION_TEXT.VENDOR,
        });
      }
      break;
  }
};

const addMentionBackdrop = async ({
  mentionData,
  currentSalesOrder,
  handleError = () => {},
  isAllowed = () => {},
  companyId,
  currentUser,
  permissionGroupDB,
  purchaseOrders,
  query,
  navigate,
  traceStop = () => {},
}) => {
  const {
    id,
    scope,
    type,
    poId = "",
    shipmentId = "",
    mentionId = "",
    originalId = "",
  } = mentionData;
  const currentType = type === TYPE_OF_FILE.FOLDER ? "Folder" : "File";

  let document = await getDocumentDB({
    salesOrder: currentSalesOrder,
    scope,
    shipmentId,
    documentId: originalId || mentionId,
    poId,
    companyId: companyId,
  });
  if (!document.exists()) {
    handleError({
      open: true,
      text: `This ${
        type === TYPE_OF_FILE.FOLDER ? "folder" : "file"
      } has been deleted`,
    });
    return;
  }
  document = { ...document.data(), scope: document.data().scope || scope };
  let parentDocument;
  if (document.parentId && document.type !== TYPE_OF_FILE.FOLDER) {
    parentDocument = await getDocumentDB({
      salesOrder: currentSalesOrder,
      scope,
      shipmentId,
      documentId: document.parentId,
      poId,
      companyId: companyId,
    });
    parentDocument = { ...parentDocument.data() };
  }
  const hasPermission = hasDocumentPermission({
    currentUser,
    document,
    permissionGroupDB,
    canSeeAllDocuments: isAllowed(
      GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_DASHBOARD_DOCUMENT
    ),
    parentDocument,
  });
  if (!hasPermission) {
    handleError({
      open: true,
      text: `You do not have access to ${currentType} ${document.name}`,
    });
    return;
  }
  newBackdropDocument({
    soId: currentSalesOrder,
    poId,
    shipmentId,
    purchaseOrders,
    handleError,
    document,
    onAddFileId: (ids) => {
      traceStop();
      navigate(
        getDashboardSearchPath({
          ...query,
          ...ids,
        })
      );
    },
    id,
    query,
    verifyVendor: (vendorId) =>
      verifyPermission({
        user: currentUser,
        permissionToCheck: [vendorId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      }),
  });
  return;
};

export const extractMentionAttributes = (element) => {
  if (element) {
    const elType = element.getAttribute("data-mention-type");
    const elId = element.getAttribute("data-mention-id");
    const elScope = element.getAttribute("data-mention-scope");
    const elPOId = element.getAttribute("data-note-po");
    const elShipmentId = element.getAttribute("data-note-shipment");
    const elOriginalId = element.getAttribute("data-original-id");
    const elVersion = element.getAttribute("data-note-version");
    const idVersion = elType !== TYPE_OF_FILE.FOLDER ? `_V${elVersion}` : "";

    return {
      elType,
      elId,
      elScope,
      elPOId,
      elShipmentId,
      elOriginalId,
      elVersion,
      idVersion,
    };
  }
  return {};
};

export function onClickMentions({
  event,
  hasFlagPermission,
  activity,
  companyId,
  currentUser,
  navigate,
  permissionGroupDB,
  purchaseOrders,
  query,
  isAllowed,
  currentSalesOrder,
  handleError = () => {},
  onReadActivity = () => {},
}) {
  if (!hasFlagPermission) {
    handleError({});
    return;
  }
  const element = event.target;
  const mentionAttributes = extractMentionAttributes(element);
  const {
    elType,
    elId,
    elScope,
    elPOId,
    elShipmentId,
    elOriginalId,
    elVersion,
    idVersion,
  } = mentionAttributes;
  const traceInstance = trace(
    performanceFirebase,
    `Backdrop_${BACKDROP_TYPE.FOLDER}_${elScope}`
  );
  if (element && elType && elId) {
    traceInstance.start();
    onReadActivity();
    const mentionData = {
      id: `${elId}${idVersion}`,
      scope: elScope,
      type: elType,
      poId: elPOId,
      shipmentId: elShipmentId,
      mentionId: elId,
      originalId: elOriginalId,
      version: elVersion,
      activity,
    };
    addMentionBackdrop({
      mentionData,
      currentSalesOrder,
      handleError: ({ open, text }) => {
        handleError({ open, text });
        try {
          traceInstance.stop();
        } catch (error) {
          console.log(error, "traceError");
        }
      },
      companyId,
      currentUser,
      navigate,
      permissionGroupDB,
      purchaseOrders,
      query,
      isAllowed,
      traceStop: () => {
        try {
          traceInstance.stop();
        } catch (error) {
          console.log(error, "traceError");
        }
      },
    });
    event.stopPropagation();
  } else {
    handleError({});
  }
}

export const activityScope = {
  [ORDER_TYPE.PURCHASE_ORDER]: NOTES_SCOPE.PURCHASE_ORDER,
  [ORDER_TYPE.PURCHASE_QUOTE]: NOTES_SCOPE.PURCHASE_ORDER,
  [ORDER_TYPE.SALES_ORDER]: NOTES_SCOPE.SALES_ORDER,
  [ORDER_TYPE.QUOTE]: NOTES_SCOPE.SALES_ORDER,
  [ORDER_TYPE.SHIPMENT]: NOTES_SCOPE.SHIPMENT,
};

export const SO_SCOPE = [ORDER_TYPE.SALES_ORDER, ORDER_TYPE.QUOTE];
export const PO_SCOPE = [ORDER_TYPE.PURCHASE_ORDER, ORDER_TYPE.PURCHASE_QUOTE];
export const SHIPMENT_SCOPE = [ORDER_TYPE.SHIPMENT];

export const getLabelScope = (scope) => {
  switch (scope) {
    case ORDER_TYPE.SALES_ORDER:
      return "SO";
    case ORDER_TYPE.PURCHASE_ORDER:
      return "PO";
    case ORDER_TYPE.QUOTE:
      return "QU";
    case ORDER_TYPE.PURCHASE_QUOTE:
      return "PQ";
    case ORDER_TYPE.SHIPMENT:
      return "SH";
    default:
      return "--";
  }
};

export const handleReplyActivity = ({
  activity,
  purchaseOrder,
  salesOrder,
  currentShipment,
  dispatchActivityStream,
  isReadOnly = false,
}) => {
  const orderScope = {
    [ORDER_TYPE.PURCHASE_ORDER]: purchaseOrder,
    [ORDER_TYPE.PURCHASE_QUOTE]: purchaseOrder,
    [ORDER_TYPE.SALES_ORDER]: salesOrder,
    [ORDER_TYPE.QUOTE]: salesOrder,
    [ORDER_TYPE.SHIPMENT]: currentShipment,
  };
  const currentOrder = orderScope[activity.scope] || {};
  if (isReadOnly || currentOrder.status === PO_STATUS.VOIDED) {
    // handleReadOnlyModal();
    return;
  }
  dispatchActivityStream({
    type: ACTIVITY_STREAM_ACTION.COMMON,
    payload: {
      currentNote: {
        ...new ActivityNote({
          scope: activityScope[activity.scope],
          threadId: activity.threadId || activity.id,
          iconIndex: activity.iconIndex,
        }),
      },
      openModalNotes: true,
      replyActivity: activity,
    },
  });
};

export const getLockIcon = ({ activity }) => {
  if (activity.lockNote) {
    return (
      <TooltipTD
        label={"Only users tagged in this note can see it"}
        style={{ display: "flex" }}
      >
        <LockNoteIcon size={16} />
      </TooltipTD>
    );
  } else {
    return null;
  }
};
export const filterByTab = ({ activity, activeTab, currentUser }) => {
  switch (activeTab) {
    case notesFilters.ALL:
      return true;
    case notesFilters.NOTES:
      return activity.type === activityType.NOTE;
    case notesFilters.ACTIVITY:
      return activity.type !== activityType.NOTE;
    case notesFilters.MENTIONS:
      return currentUser.id in (activity.userMentions || {});
    case notesFilters.THREADS:
      return activity.numberOfReplies > 0;
    default:
      return true;
  }
};

const checkGeneralPermission = ({
  currentUser,
  isAllowed,
  generalPermissions = [],
}) => {
  let hasPermission = false;
  generalPermissions.forEach((permissionValue) => {
    hasPermission = hasPermission || isAllowed(permissionValue);
  });
  return (
    currentUser.role === userTypes.TRADEDASH_EMPLOYEE ||
    currentUser.role === userTypes.SUPER_ADMIN ||
    hasPermission
  );
};

export const getFilterActivities = ({
  allActivities,
  querySearch = "",
  noteThread,
  activeTab,
  currentUser,
  filterByUsers = [],
  date = null,
  isAllowed = () => {},
}) => {
  if (allActivities.length === 0 || !date) {
    return [];
  }
  const startOfDayDate = moment(date).startOf("day").valueOf();
  let activities = Array.from(
    new Set(allActivities.map((activity) => activity.id))
  )
    .map((id) => allActivities.find((activity) => activity.id === id))
    .sort(sortObjectsBy("creationDate", true))
    .filter((activity) => {
      let allowByUser = true;
      if (activity.lockNote) {
        allowByUser =
          currentUser.id === activity.user ||
          currentUser.id in (activity.userMentions || {}) ||
          checkGeneralPermission({
            currentUser,
            isAllowed,
            generalPermissions: [GENERAL_PERMISSION_VALUE.CAN_SEE_ALL_NOTES],
          });
      }
      if (activity.deletedStatus === ACTIVITY_DELETE_STATUS.DELETED) {
        return false;
      }
      return activity.creationDate >= startOfDayDate && allowByUser;
    });
  activities = activities.map((activity) => {
    if (activity.deletedStatus === ACTIVITY_DELETE_STATUS.HIDDEN) {
      return {
        ...activity,
        detail: "<p><i>Redacted</i></p>",
      };
    }
    return activity;
  });
  if (querySearch) {
    activities = activities.filter((activity) => {
      const detailElement = document.createElement("div");
      detailElement.innerHTML = activity.detail;

      const textContent = detailElement.textContent || detailElement.innerText;
      return (
        textContent.toLowerCase().includes(querySearch.toLowerCase()) ||
        getTextFromType(activity.type)
          .toLowerCase()
          .includes(querySearch.toLowerCase())
      );
    });
  }
  if (noteThread) {
    activities = activities.filter(
      (activity) =>
        activity.threadId === noteThread.id || activity.id === noteThread.id
    );
  }
  activities = activities.filter((activity) => !activity.deleted);
  if (filterByUsers.length > 0)
    activities = activities.filter((activity) =>
      filterByUsers.includes(activity.user)
    );

  const currentActivities = activities.filter((activity) =>
    filterByTab({ activeTab, activity, currentUser })
  );

  return currentActivities.map((activity) => {
    if (activity.numberOfReplies) {
      const childrenActivities = activities.filter(
        (childActivity) => childActivity.threadId === activity.id
      );
      return {
        ...activity,
        numberOfReplies: Math.max(
          activity.numberOfReplies,
          childrenActivities.length
        ),
      };
    }
    return activity;
  });
};

export const getSeedByScope = ({
  typeScope,
  purchaseOrder,
  salesOrder,
  currentShipment,
}) => {
  let currentScope;
  let seedArray;
  let seedIndex;
  let currentOrder;
  let dataScope = {};
  if (typeScope === NOTES_SCOPE.PURCHASE_ORDER) {
    dataScope.scope = purchaseOrder.type;
    dataScope.purchaseOrderId = purchaseOrder.id;
    currentScope = ORDER_TYPE.PURCHASE_ORDER;
    seedArray = pseudoRandom(purchaseOrder.number);
    seedIndex = purchaseOrder.iconIndex || 0;
    currentOrder = purchaseOrder;
  } else if (typeScope === NOTES_SCOPE.SALES_ORDER) {
    dataScope.scope = salesOrder.type;
    dataScope.purchaseOrderId = purchaseOrder.id;
    currentScope = ORDER_TYPE.SALES_ORDER;
    seedArray = pseudoRandom(salesOrder.number);
    seedIndex = salesOrder.iconIndex || 0;
    currentOrder = salesOrder;
  } else if (typeScope === NOTES_SCOPE.SHIPMENT) {
    currentScope = ORDER_TYPE.SHIPMENT;
    dataScope.scope = ORDER_TYPE.SHIPMENT;
    dataScope.shipmentId = currentShipment.id;
    dataScope.purchaseOrderId = purchaseOrder.id;
    seedArray = pseudoRandom(currentShipment.number);
    seedIndex = currentShipment.iconIndex || 0;
    currentOrder = currentShipment;
  }
  seedIndex = seedIndex === 160 ? 0 : seedIndex;
  return { currentScope, seedArray, seedIndex, currentOrder, dataScope };
};

export const pseudoRandom = (seed) => {
  const parseSeed = `${seed}`;
  const a = 1664525;
  const c = 1013904223;
  const m = 4294967296; // 2^32
  const numbers = Array.from({ length: 160 }, (_, i) => i);
  let seedNumber = 0;
  const multiplier = 31; // Constante para multiplicar el hash

  for (let i = 0; i < parseSeed.length; i++) {
    seedNumber = seedNumber * multiplier + parseSeed.charCodeAt(i);
  }

  for (let i = numbers.length - 1; i > 0; i--) {
    seedNumber = (a * seedNumber + c) % m;
    const j = Math.floor((seedNumber / m) * i);
    [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
  }
  return numbers;
};

export const getActivityDataRead = ({ activity, userId, forceValue, type }) => {
  const hasActivityReadPoint = hasActivityReadUnread({
    activity,
    currentUser: { id: userId },
    type,
  });
  if (!hasActivityReadPoint) {
    return {};
  }
  const { readByUsers = {}, readFrom = {}, userMentions = {} } = activity;
  const isRead = userMentions[userId];
  const currentReadUser = readByUsers[userId] || moment().valueOf();
  const currentReadFrom = readFrom[userId] || type;
  return {
    userMentions: {
      ...userMentions,
      [userId]: forceValue ? forceValue.value : !isRead,
    },
    readByUsers: {
      ...readByUsers,
      [userId]: currentReadUser,
    },
    readFrom: {
      ...readFrom,
      [userId]: currentReadFrom,
    },
  };
};

export const hasActivityReadUnread = ({ activity, currentUser }) => {
  const { userMentions = {} } = activity;
  const userIds = Object.keys(userMentions);
  return (
    activity.type === activityType.NOTE && userIds.includes(currentUser.id)
  );
};

export const isActivityNoteMention = (activity = {}, userId = "") => {
  const userMentions = activity.userMentions || {};
  return userMentions[userId] === false && activity.type === activityType.NOTE;
};

export const onClearAllMention = async ({
  currentShipment,
  purchaseOrder,
  salesOrder,
  currentUser,
  userMentions,
  dispatch,
  allActivities,
  typeReadFrom,
}) => {
  allActivities.forEach((activity) => {
    if (isActivityNoteMention(activity, currentUser.id)) {
      const newActivityData = getActivityDataRead({
        activity,
        forceValue: { value: true },
        userId: currentUser.id,
        type: typeReadFrom,
      });
      dispatch({
        type: MARK_READ_ACTIVITY,
        payload: {
          ...activity,
          ...newActivityData,
        },
      });
    }
  });

  const soMentions = userMentions[dbTables.SALES_ORDERS] || {};
  const poMentions = userMentions[dbTables.PURCHASE_ORDERS] || {};
  const shipmentMentions = userMentions[dbTables.SHIPMENTS] || {};
  const soDeleteValues = getCleanMentionsValues({
    userMentions: soMentions,
  });
  const poDeleteValues = getCleanMentionsValues({
    userMentions: poMentions,
  });
  const merge = { merge: true };
  const shipmentDeleteValues = getCleanMentionsValues({
    userMentions: shipmentMentions,
  });
  const entities = {
    SALES_ORDERS: {
      docId: [dbTables.SALES_ORDERS],
      ref: soMentions.ref,
      values: soDeleteValues,
      id: salesOrder.id,
    },
    PURCHASE_ORDERS: {
      docId: [dbTables.PURCHASE_ORDERS],
      ref: purchaseOrder.ref,
      values: poDeleteValues,
      id: purchaseOrder.id,
    },
    SHIPMENTS: {
      docId: [dbTables.SHIPMENTS],
      ref: currentShipment.ref,
      values: shipmentDeleteValues,
      id: currentShipment.id,
    },
  };
  Object.values(entities).forEach(({ ref, values, id, docId }) => {
    if (ref) {
      const path = `${dbTables.USERS}/${currentUser.id}/${dbTables.MENTIONS}/${docId}`;
      setDoc(doc(firestore, path), { ...values, [id]: deleteField() }, merge);
    }
  });
};

export function alreadyReadNote({ activity = {}, user }) {
  const userMentions = activity.userMentions || {};
  if (userMentions) {
    for (const key in userMentions) {
      if (key === user.id) {
        return !!userMentions[user.id];
      }
    }
  }
  return false;
}
export function getNewestUnreadActivity({ activities = [] }) {
  let oldActivity = activities[0];
  activities.forEach((activity) => {
    if (
      activity &&
      moment(activity.creationDate).valueOf() <
        moment(oldActivity.creationDate).valueOf()
    ) {
      oldActivity = activity;
    }
  });
  return oldActivity || {};
}

export const getNotificationByActivity = ({
  notifications = {},
  activity = {},
}) => {
  if (!activity.notificationId) {
    return;
  }
  let currentNotification;
  Object.keys(notifications).forEach((typeNotification) => {
    if (!currentNotification) {
      const notificationsByFilter = notifications[typeNotification];
      const dataNotifications = notificationsByFilter.data || [];
      currentNotification = dataNotifications.find(
        (notification) => notification.id === activity.notificationId
      );
    }
  });
  return currentNotification;
};

export const reduxUpdateActivities = ({
  activitiesOrder,
  currentUser,
  cleanAll,
  notificationIdsList,
  typeReadFrom,
}) => {
  const updatedActivities = { ...activitiesOrder };
  Object.keys(updatedActivities).forEach((table) => {
    let activities = [...updatedActivities[table]];
    activities = activities.map((activity) => {
      const canMarkAsRead =
        cleanAll || notificationIdsList.includes(activity.notificationId);
      if (
        activity.type === activityType.NOTE &&
        activity.userMentions[currentUser.id] === false &&
        canMarkAsRead
      ) {
        const { userMentions = {}, readByUsers = {}, readFrom = {} } = activity;
        return {
          ...activity,
          userMentions: {
            ...userMentions,
            [currentUser.id]: true,
          },
          readByUsers: {
            ...readByUsers,
            [currentUser.id]: readByUsers[currentUser.id] || moment().valueOf(),
          },
          readFrom: {
            ...readFrom,
            [currentUser.id]: readFrom[currentUser.id] || typeReadFrom,
          },
        };
      } else {
        return { ...activity };
      }
    });
    updatedActivities[table] = activities;
  });
  return updatedActivities;
};

export const borderAnimation = {
  hidden: {
    clipPath: "polygon(50% 0, 50% 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 50%)",
  },
  visible: {
    clipPath: [
      "polygon(50% 0, 50% 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 50%)",
      "polygon(0 0, 100% 0, 100% 1%, 60% 1%, 50% 1%, 30% 1%, 0% 1%)",
      "polygon(0 0, 100% 0, 100% 100%, 100% 100%, 50% 90%, 0% 100%, 0 100%)",
      "polygon(0 0, 100% 0, 100% 100%, 50% 100%, 50% 90%, 50% 100%, 0 100%)",
    ],
    transition: {
      duration: 1,
      ease: "linear",
    },
  },
  reset: {
    clipPath: [
      "polygon(0 0, 100% 0, 100% 100%, 50% 100%, 50% 90%, 50% 100%, 0 100%)",
      "polygon(0 0, 100% 0, 100% 100%, 100% 100%, 50% 90%, 0% 100%, 0 100%)",
      "polygon(0 0, 100% 0, 100% 1%, 60% 1%, 50% 1%, 30% 1%, 0% 1%)",
      "polygon(50% 0, 50% 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 50%)",
    ],
    transition: {
      duration: 1,
      ease: "linear",
    },
  },
};
